import PropTypes from 'prop-types'
import { InstantSearch, SearchBox, Hits, useInstantSearch } from 'react-instantsearch'
import BlogCard from '@app/components/BlogCard'
import Typography from '../stories/Typography'
import searchClient from '../libs/algolia'

const HitBuilder = ({ hit }) => {
  return <BlogCard key={hit.objectId} blog={hit} />
}

const BlogsSearch = () => {
  const NoResultsBoundary = ({ children, fallback }) => {
    const { results } = useInstantSearch()

    // The `__isArtificial` flag makes sure not to display the No Results message
    // when no hits have been returned.
    // eslint-disable-next-line no-underscore-dangle
    if (!results.__isArtificial && results.nbHits === 0) {
      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      )
    }

    return children
  }

  NoResultsBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    fallback: PropTypes.node.isRequired,
  }

  function NoResults() {
    const { indexUiState } = useInstantSearch()

    return (
      <Typography variant='h3' weight='bold' align='center'>
        No blogs found for {indexUiState.query}
      </Typography>
    )
  }

  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName='blogs'
        initialUiState={{
          blogs: {
            query: '',
            page: 0,
          },
        }}
      >
        <SearchBox
          placeholder='Search for a Blog'
          style={{
            width: '100%',
            height: '50px',
            fontSize: '20px',
            marginBottom: '20px',
          }}
        />
        <NoResultsBoundary fallback={<NoResults />}>
          <Hits hitComponent={HitBuilder} />
        </NoResultsBoundary>
      </InstantSearch>
      {/* {!blogs && <Loader />}
      {blogs?.length === 0 && (
        <Typography variant='h3' weight='bold'>
          No blogs found
        </Typography>
      )}
      {blogs?.length >= 0 && (
        <>
          {blogs.map(blog => (
            <BlogCard key={blog.uid} blog={blog} />
          ))}
        </>
      )} */}
    </>
  )
}

HitBuilder.propTypes = {
  hit: PropTypes.object.isRequired,
}

export default BlogsSearch
