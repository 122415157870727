export const stripeVoucherMetadata = input => {
  const result = []
  Object.keys(input).forEach(key => {
    if (key.startsWith('subVoucherId')) {
      const idKey = key
      const codeKey = key.replace('subVoucherId', 'subVoucherCode')
      const voucherIds = input[idKey]?.split(' - ') || []
      const voucherCodes = input[codeKey]?.split(' - ') || []
      voucherIds.forEach((id, index) => {
        result.push({
          voucherId: id.trim(),
          voucherCode: voucherCodes[index]?.trim(),
        })
      })
    } else if (key.startsWith('voucherId')) {
      const idKey = key
      const codeKey = key.replace('voucherId', 'voucherCode')
      const voucherIds = input[idKey]?.split(' - ') || []
      const voucherCodes = input[codeKey]?.split(' - ') || []
      voucherIds.forEach((id, index) => {
        result.push({
          voucherId: id.trim(),
          voucherCode: voucherCodes[index]?.trim(),
        })
      })
    }
  })
  return result
}
