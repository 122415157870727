import { useState } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Typography from '../Typography'
import Container from '../Container'
import Section from '../Section'
import CollapsableListItem from '@app/components/CollapsableListItem'

const FAQ = () => {
  const [currentIndex, setCurrentIndex] = useState('')

  const onChangeCollapse = e => {
    setCurrentIndex(e)
  }

  return (
    <Section id='FAQ' bgcolor='#f5f5f5'>
      <Container maxWidth='lg'>
        <Box mb={4}>
          <Typography variant='h1' align='center' gutterBottom>
            Frequently Asked Questions
          </Typography>
        </Box>
        <List disablePadding>
          <CollapsableListItem
            key='question1'
            icon='question'
            currentIndex={currentIndex}
            onChangeCollapse={onChangeCollapse}
            title={<span>What is Mishe?</span>}
          >
            <Typography variant='body1'>
              Mishe is a technology company focused on increasing access to healthcare. Our platform
              makes it easier for people to connect directly with care providers, and our network
              makes it easier for people to access discounted care. We use transparency to (1)
              foster alignment amongst clinical stakeholders, (2) facilitate prospective care
              payment, (3) and enable value based care regardless of insurance status or practice
              makeup.
            </Typography>
          </CollapsableListItem>
          <CollapsableListItem
            key='question2'
            icon='question'
            currentIndex={currentIndex}
            onChangeCollapse={onChangeCollapse}
            title={<span>How does it work?</span>}
          >
            <Typography variant='body1'>
              Mishe contracts with network participants and pre-negotiates discount rates on behalf
              of users. Patient’s pre-pay for care on Mishe at the pre-negotiated discount rate -
              using one of our flexible payment options - in exchange for a care voucher (QR code),
              and Mishe holds funds until services are rendered. The voucher QR code is scanned when
              the service occurs, which notifies Mishe that services are rendered. Mishe adjudicates
              the doctor’s charge and discounts it to the pre-negotiated rate in real time.
            </Typography>
          </CollapsableListItem>
          <CollapsableListItem
            key='question3'
            icon='question'
            currentIndex={currentIndex}
            onChangeCollapse={onChangeCollapse}
            title={<span>How are these prices so low?</span>}
          >
            <Typography variant='body1'>
              Mishe’s participating clinicians offer exclusive discounts in exchange for immediate
              payment. We align everyone on treatment, cost and payment before services are rendered
              to eliminate surprises and extra costs. Efficiency = cost savings.
            </Typography>
          </CollapsableListItem>
          <CollapsableListItem
            key='question4'
            icon='question'
            currentIndex={currentIndex}
            onChangeCollapse={onChangeCollapse}
            title={<span>Can I use my insurance?</span>}
          >
            <Typography variant='body1'>
              Based on your health benefits, your service may be reimbursable by your insurance
              plan. Check with your Mishe Case Manager to determine eligibility after you receive
              your service. If eligible, your case manager will provide you with the appropriate
              documentation needed. Mishe does not accept insurance at this time, and is not
              intended for beneficiaries of government payor programs, including Medicare and
              Medicaid.
            </Typography>
          </CollapsableListItem>
          <CollapsableListItem
            key='question5'
            icon='question'
            currentIndex={currentIndex}
            onChangeCollapse={onChangeCollapse}
            title={<span>Should I go through insurance or go through Mishe?</span>}
          >
            <Typography variant='body1'>
              With Mishe, network discounts are guaranteed, so there is no risk of a denied claim or
              surprise bill. With Mishe, there are no referral or pre-authorization requirements to
              fulfill. With Mishe, your case manager will manage your appointment scheduling so you
              can skip the hold times and get appointment confirmation via chat or SMS.
            </Typography>
          </CollapsableListItem>
          <CollapsableListItem
            key='question6'
            icon='question'
            currentIndex={currentIndex}
            onChangeCollapse={onChangeCollapse}
            title={<span>What if the doctor I want to see isn’t on Mishe?</span>}
          >
            <Typography variant='body1'>Refer them!</Typography>
          </CollapsableListItem>
          <CollapsableListItem
            key='question7'
            icon='question'
            currentIndex={currentIndex}
            onChangeCollapse={onChangeCollapse}
            title={<span>How much does Mishe cost?</span>}
          >
            <Typography variant='body1'>
              Mishe is free for our initial users. We will introduce a membership fee for access to
              the network, but it will come with value added services that will help you save money.
              Future services will include insurance benefits consultations, access to ancillary
              care networks (imaging, medications, etc), patient advocacy, and more.
            </Typography>
          </CollapsableListItem>
        </List>
      </Container>
    </Section>
  )
}

export default FAQ
