import React, { useEffect, useRef, useState } from 'react'
import FRONTimage from '../assets/images/memberCards/FRONT.png'
import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import { Box } from '@mui/material'
import { formatDateForUI } from '@app/libs/formatters'

const MemberPlanCard = ({
  firstName,
  lastName,
  memberNumber,
  groupNumber,
  pcpCopay,
  specialistCopay,
  emergencyRoomCopay,
  memberServiceNumber,
  planType,
  prescriptionGroup,
  prescriptionCopay,
  effectiveDate,
}) => {
  const canvasRef = useRef(null)
  const [hover, setHover] = useState(false)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    const backgroundImage = new Image()
    backgroundImage.src = FRONTimage

    const fontSize = canvas.width * 0.03
    const lHeight = fontSize * 1.5
    const pad = canvas.width * 0.05
    const boldFont = `bold ${fontSize}px Poppins`
    const regularFont = `${fontSize}px Poppins`

    const drawText = (text, x, y, font = regularFont) => {
      ctx.font = font
      ctx.fillText(text, x, y)
    }

    backgroundImage.onload = () => {
      ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height)
      ctx.fillStyle = '#ffffff'

      // Left Section
      drawText('Member Name', pad, pad + lHeight, boldFont)
      drawText(`${firstName} ${lastName}`, pad, pad + lHeight * 2)
      drawText('Plan Type', pad, pad + lHeight * 3.5, boldFont)
      drawText(planType, pad, pad + lHeight * 4.5)
      drawText('Copay', pad, pad + lHeight * 6, boldFont)
      drawText(`PCP Copay: ${pcpCopay}`, pad, pad + lHeight * 7)
      drawText(`Specialist Copay: ${specialistCopay}`, pad, pad + lHeight * 8)
      drawText(`ER Copay: ${emergencyRoomCopay}`, pad, pad + lHeight * 9)
      drawText(`Prescription: ${prescriptionCopay}`, pad, pad + lHeight * 10)

      // Center Section
      const centerX = canvas.width / 2.5
      drawText('Member ID', centerX, pad + lHeight, boldFont)
      drawText(memberNumber.substring(1, 10), centerX, pad + lHeight * 2)
      drawText('Group ID', centerX, pad + lHeight * 3.5, boldFont)
      drawText(groupNumber, centerX, pad + lHeight * 4.5)
      drawText('Prescription Group', centerX, pad + lHeight * 6, boldFont)
      drawText(prescriptionGroup, centerX, pad + lHeight * 7)

      // Bottom-Right Section
      const bottomRightX = canvas.width - pad * 6
      const bottomY = canvas.height - pad

      drawText('Effective Date', bottomRightX, bottomY - lHeight * 3.5, boldFont)
      drawText(formatDateForUI(effectiveDate), bottomRightX, bottomY - lHeight * 2.5)

      drawText('Member Service', bottomRightX, bottomY - lHeight, boldFont)
      drawText(memberServiceNumber, bottomRightX, bottomY)
    }
  }, [
    firstName,
    lastName,
    memberNumber,
    groupNumber,
    pcpCopay,
    specialistCopay,
    emergencyRoomCopay,
    memberServiceNumber,
    planType,
    prescriptionGroup,
    prescriptionCopay,
  ])

  const handleDownload = () => {
    const canvas = canvasRef.current
    const link = document.createElement('a')
    link.href = canvas.toDataURL('image/png')
    link.download = `${firstName}_${lastName}_MemberCard.png`
    link.click()
  }

  return (
    <Box
      position='relative'
      display='inline-block'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <canvas
        ref={canvasRef}
        width='350'
        height='200'
        style={{
          border: '2px solid #333',
          borderRadius: '10px',
        }}
      ></canvas>
      {hover && (
        <Box
          position='absolute'
          top={0}
          left={0}
          width='100%'
          height='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          bgcolor='rgba(0, 0, 0, 0.3)'
        >
          <IconButton onClick={handleDownload} color='primary' size='large'>
            <DownloadIcon size='large' fontSize='inherit' />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default MemberPlanCard
