import { Box, Hidden } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Button from '../stories/Button'
import MisheLeaves from '../assets/images/MisheLeaves.png'
import PageSection from '@app/components/PageSection'
import Typography from '../stories/Typography'
import background from '../assets/images/backgroundTest2.png'
import WordShifterFade from '@app/components/WordShifterFade'
import ComponentShifterFade from '@app/components/ComponentShifterFade'
import PhysicianPage2 from '../assets/images/PhysicianPage2.png'
import MishePhysicianReceptionist from '../assets/images/MishePhysicianReceptionist.png'

const PhysiciansPage = () => {
  return (
    <Box>
      <Hidden mdDown>
        <PageSection
          startLoaded
          direction='left'
          image={PhysicianPage2}
          height={700}
          imgWidth='60%'
          infoWidth='545px'
          bgImage={background}
        >
          <Box
            padding='1rem'
            bgcolor='rgba(217, 193, 244, 0.2)'
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1)'
            // width='545px'
          >
            <Box padding='2rem' bgcolor='#652d92' marginLeft='2rem'>
              <Typography
                variant='h5'
                family='poppins'
                weight='medium'
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  margin: '0',
                }}
              >
                Same-day payment.
                <br /> Easier than cash. <br /> No billing necessary.
              </Typography>
            </Box>
            {/* <Box
              padding='1rem 0 0 2rem'
              display='flex'
              justifyContent='space-between'
            >
              <Typography
                variant='body1'
                family='poppins'
                weight='light'
                color='primary'
              >
                Are you a provider?
              </Typography>
              <Button
                variant='text'
                style={{
                  padding: '0',
                  height: 'auto'
                }}
              >
                <Typography
                  variant='body1'
                  family='poppins'
                  weight='light'
                  color='primary'
                >
                  Sign In
                </Typography>
              </Button>
            </Box> */}
          </Box>
        </PageSection>
        <PageSection height={100} bgColorSlide='#652d92' bgColor='#6d319e'>
          <Box display='flex' justifyContent='space-around' padding='0 2rem' width='100%'>
            <Typography
              variant='h2'
              family='poppins'
              weight='light'
              style={{
                color: '#fff',
                margin: '0',
              }}
            >
              How Mishe Works For You
            </Typography>
          </Box>
        </PageSection>
        <Box width='100vw' bgcolor='#f6eeff' height='150px' />
        <PageSection height={225} childrenOnSlide bgColor='#f6eeff'>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            margin='0 15rem'
            padding='3rem'
            bgcolor='#fff'
            boxShadow='0px 8px 8px 0px rgba(0,0,0,0.1)'
            borderRadius='20px'
          >
            <Typography
              variant='h3'
              family='poppins'
              weight='light'
              style={{ margin: '0px' }}
              textAlign='center'
            >
              No surprise bills. Concierge care coordination. Personal health passport.
            </Typography>
          </Box>
        </PageSection>
        <Box width='100vw' bgcolor='#f6eeff' height='50px' />
        <PageSection
          height={400}
          direction='left'
          infoWidth='60%'
          imgWidth='45%'
          image={MishePhysicianReceptionist}
          bgColor='#f6eeff'
        >
          <Box
            // display='flex'
            // justifyContent='center'
            // alignItems='center'
            margin='0 5rem 0 5rem'
            padding='3rem'
          >
            <Box mb={1}>
              <Typography variant='caption' weight='semibold' family='poppins'>
                <Box component='span' color='primary.main'>
                  MINDFUL CARE
                </Box>
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant='h3' gutterBottom family='poppins'>
                Big Bold Statement Goes,
                <br />
                <Box component='span' color='primary.main'>
                  Right Here
                </Box>
              </Typography>
            </Box>
            <Typography
              variant='body1'
              weight='light'
              style={{ maxWidth: '480px' }}
              family='poppins'
            >
              Mishe&apos;s Price Guarantee protects you against surprise bills and our Concierge
              Case Managers provide end-to-end support with everything from finding & scheduling
              care, obtaining records & results, and even fighting old bills. It&apos;s no
              coincidence that our leadership team consists of a physician, biller, care
              coordinator, and a patient. Have a question or concern? We&apos;ll take care of it.
            </Typography>
            <br /> <br />
            <Box mb={3}>
              <Typography
                variant='body2'
                gutterBottom
                weight='light'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <CheckCircleIcon style={{ color: '#662D91' }} />
                <span style={{ marginLeft: 20 }}>Check 1</span>
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography
                variant='body2'
                gutterBottom
                weight='light'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <CheckCircleIcon style={{ color: '#662D91' }} />
                <span style={{ marginLeft: 20 }}>Check 2</span>
              </Typography>
            </Box>
            <Typography
              variant='body2'
              gutterBottom
              weight='light'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <CheckCircleIcon style={{ color: '#662D91' }} />
              <span style={{ marginLeft: 20 }}>Check 3</span>
            </Typography>
            {/* <Typography
              variant='h4'
              family='poppins'
              weight='light'
              style={{ margin: '0px' }}
              align='center'
            >
              Mishe&apos;s Price Guarantee protects you against surprise bills
              and our Concierge Case Managers provide end-to-end support with
              everything from finding & scheduling care, obtaining records &
              results, and even fighting old bills. It&apos;s no coincidence
              that our leadership team consists of a physician, biller, care
              coordinator, and a patient. Have a question or concern? We&apos;ll
              take care of it.
            </Typography> */}
          </Box>
        </PageSection>
        <Box width='100vw' bgcolor='#f6eeff' height='150px' />
        <PageSection
          height={600}
          bgColor='#f6eeff'
          bgColorSlide='#fff'
          direction='up'
          slideBorderRadius='80px 80px 0 0'
          slideBoxShadow='0px -8px 8px 0px rgba(0,0,0,0.1)'
          childrenOnSlide
        >
          <Box display='flex' justifyContent='center' height='600px' padding='100px'>
            <ComponentShifterFade length={5000}>
              <Typography variant='h2' family='poppins' weight='light'>
                Hello this is where we can put some interestin paragraphs about things or maybe
                reviews or something who knows
              </Typography>
              <Typography variant='h2' family='poppins' weight='light'>
                Hello this 2
              </Typography>
              <Typography variant='h2' family='poppins' weight='light'>
                Hello this 3
              </Typography>
            </ComponentShifterFade>
          </Box>
        </PageSection>
      </Hidden>
      <Hidden mdUp>
        <PageSection
          startLoaded
          height={500}
          imgWidth='100%'
          infoWidth='100%'
          bgImage={MisheLeaves}
        >
          <Box
            padding='1rem'
            bgcolor='rgba(217, 193, 244, 0.9)'
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.1)'
            margin='1rem'
          >
            <Box padding='1rem' bgcolor='#652d92'>
              <Typography
                variant='h1'
                family='poppins'
                style={{
                  color: '#d9c1f4',
                  fontSize: '16px',
                  margin: '0',
                }}
              >
                Members
              </Typography>
              <Typography
                variant='h5'
                family='poppins'
                weight='light'
                style={{
                  color: '#fff',
                  fontSize: '24px',
                  margin: '0',
                }}
              >
                We don&apos;t just make your benefits better. We help you use them wherever you are.
              </Typography>
            </Box>
            <Box padding='1rem 2rem 0 2rem' display='flex' justifyContent='space-between'>
              <Typography variant='body1' family='poppins' weight='light' color='primary'>
                Are you a member?
              </Typography>
              <Button
                variant='text'
                style={{
                  margin: '0 1rem',
                  padding: '0',
                  height: 'auto',
                }}
              >
                <Typography variant='body1' family='poppins' weight='light' color='primary'>
                  Sign In
                </Typography>
              </Button>
            </Box>
          </Box>
        </PageSection>
        <PageSection height={100} bgColorSlide='#652d92' bgColor='#6d319e' width='100%'>
          <WordShifterFade inputText={['Find Care', 'Book Care', 'Get Care']} />
        </PageSection>
      </Hidden>
    </Box>
  )
}

export default PhysiciansPage
