import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '../stories/Typography'
import Button from '../stories/Button'

const ProviderSearchCard = ({
  data = { firstname: '', middlename: '', lastname: '', statesLicensed: [] },
  onClick = null,
  hideButton = false,
}) => {
  console.log(data.cptCode)
  const { name, firstname, middlename, lastname, statesLicensed = [] } = data

  return (
    <Box width='100%' padding='16px'>
      <Grid container display='flex' justifyContent='space-between' alignItems='center'>
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <Box paddingLeft='4px'>
            <Typography
              variant='body1'
              style={{
                color: '#652d92dd',
                // Make the text Normal capitalization
                textTransform: 'capitalize',
              }}
            >
              {name ? (
                name.toLowerCase()
              ) : (
                <>
                  {(firstname ? firstname + ' ' : '').toLowerCase()}
                  {(middlename ? middlename + ' ' : '').toLowerCase()}
                  {(lastname ? lastname : '').toLowerCase()}
                </>
              )}
            </Typography>
            {statesLicensed.length > 0 && (
              <Typography variant='body1' style={{ color: '#652d92dd' }}>
                States Licensed: {statesLicensed.join(', ')}
              </Typography>
            )}
          </Box>
          <Box
            color='#652d92'
            sx={{
              paddingBottom: {
                xs: '8px',
                sm: '8px',
                md: '0',
                lg: '0',
              },
            }}
          >
            <Typography variant='body1' weight='bold' color='inherit'>
              {/* ${(referralPrice && referralPrice.toFixed(2)) || (price && price.toFixed(2)) || (payerPrice && payerPrice.toFixed(2)) || '---'} */}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Box
            display='flex'
            justifyContent='space-between'
            height='100%'
            alignItems='center'
            sx={{
              flexDirection: {
                xs: 'row',
                sm: 'row',
                md: 'column',
                lg: 'column',
              },
            }}
            className='provider-search-card-button-container'
          >
            {!hideButton && (
              <Button
                variant='outlined'
                style={{
                  height: '40px',
                  margin: '4px',
                  width: '120px',
                  border: 'solid 1px #652d92',
                }}
                onClick={() => window?.Intercom('show')}
                className='provider-search-card-button'
              >
                <Typography
                  variant='h5'
                  weight='light'
                  style={{
                    margin: '0px',
                    whiteSpace: 'nowrap',
                    color: '#652d92',
                  }}
                >
                  Get Started
                </Typography>
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

ProviderSearchCard.propTypes = {
  data: PropTypes.shape({
    firstname: PropTypes.string,
    middlename: PropTypes.string,
    lastname: PropTypes.string,
    statesLicensed: PropTypes.array,
  }),
  onClick: PropTypes.string,
}

export default ProviderSearchCard
