import React from 'react'
import VouchersProvider from '../context/Vouchers'
import VoucherDetailsProvider from '../context/VoucherDetails'
import AddNoteModalProvider from '../context/AddNoteModal'
import AddOrderModalProvider from '../context/AddOrderModal'

const DashboardProviders = ({ children }) => {
  return (
    <VouchersProvider>
      <VoucherDetailsProvider>
        <AddNoteModalProvider>
          <AddOrderModalProvider>{children}</AddOrderModalProvider>
        </AddNoteModalProvider>
      </VoucherDetailsProvider>
    </VouchersProvider>
  )
}

export default DashboardProviders
