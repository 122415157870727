import { memo } from 'react'
import Box from '@mui/material/Box'
import Typography from '../../stories/Typography'
import { getVoucherLink } from '../../utils/getVoucherLink'
import AddToCalender from '@app/components/AddToCalender'
import { checkIfDate } from '../../libs/validators'
import { getVoucherAppDate, getVoucherType } from '@app/utils/userFormatterUtil'

const VoucherInfo = ({ voucher }) => (
  <Box>
    <Typography variant='h4' mt={2} mb={3}>
      Claim Info
    </Typography>
    <Box>
      <Typography variant='body1' mb={2}>
        <b>Claim ID:</b> {voucher.code}
      </Typography>
      <Typography variant='body1' mb={2}>
        <b>Claim ref:</b> {voucher.id}
      </Typography>
      <Typography variant='body1' mb={2}>
        <b>Service:</b> {voucher.service.name}
      </Typography>
      <Typography variant='body1' mb={2}>
        <b>Claim Type:</b> {getVoucherType(voucher)}
      </Typography>
      <Typography variant='body1' mb={2}>
        <b>Appointment Date:</b> {getVoucherAppDate(voucher)}
      </Typography>

      {checkIfDate(voucher?.appointmentDate) && (
        <Box marginTop='4px'>
          <AddToCalender
            calenderEvent={{
              title: voucher?.service?.name || '',
              description: `Voucher Link:  ${getVoucherLink(voucher)}`,
              start: voucher?.appointmentDate?.toDate()?.toISOString(),
              appointmentDate: voucher?.appointmentDate,
              duration: [1, 'hour'],
            }}
          />
        </Box>
      )}
    </Box>
  </Box>
)

export default memo(VoucherInfo)
