import { memo } from 'react'
import Box from '@mui/material/Box'
import Typography from '../../stories/Typography'
import Button from '../../stories/Button'
import GeneralModal from '@app/containers/GeneralModal'
import Link from '../../stories/Link'
import Icon from '../../stories/Icons'
import CopyVoucher from '@app/components/CopyVoucher'
import EobButton from '@app/components/EobButton'

const VoucherFunctions = ({ voucher, isProvider }) => (
  <Box>
    <Typography variant='h4' mt={2} mb={1}>
      Claim Actions
    </Typography>
    <Box mt={2}>
      <Link
        to={`/videochat?id=${voucher.id}`}
        type='internal'
        display='inline'
        variant='unstyled'
        size='small'
        target='_blank'
        style={{
          padding: '6px 10px',
          borderRadius: '4px',
          backgroundColor: '#652d92',
          color: '#fff',
          boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        }}
      >
        Join Mishe Video Call
      </Link>
      {isProvider && voucher?.customer?.idLink && (
        <Box mt={2}>
          <GeneralModal
            id='patientID'
            headerText='Patient Identification'
            maxWidth={'lg'}
            customButton={
              <Button variant='outlined' color='primary' size='small' style={{ boxShadow: 'none' }}>
                <Box display='flex' gap='8px' alignItems='center'>
                  <Icon name='pageViewOutlined' />
                  <Typography variant='body2' family='poppins'>
                    View Patient ID
                  </Typography>
                </Box>
              </Button>
            }
          >
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              style={{ maxWidth: '400px' }}
            >
              <img
                // make sure image fits in box
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  border: '3px solid #652d92',
                  borderRadius: '8px',
                }}
                src={voucher?.customer?.idLink}
                alt='PatientIdentification'
              />
            </Box>
          </GeneralModal>
        </Box>
      )}
      <Box marginTop='12px'>
        <CopyVoucher voucher={voucher} label='Copy Voucher Link' />
      </Box>
      <Box marginTop='12px'>
        <EobButton voucher={voucher} label='Explanation of Benefits' />
      </Box>
    </Box>
  </Box>
)

export default memo(VoucherFunctions)
