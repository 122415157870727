import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { collection } from '../../firebase/firestore'
import { convertFirestoreToDate } from '../../libs/formatters'
import { CircularProgress } from '@mui/material'
import { useCurrentProfile } from '@app/context/CurrentUser'

const getClaims = async planID => {
  const claimsRef = await collection('vouchers').where('plan.id', '==', planID).get()

  return claimsRef.docs.map(doc => {
    const { appointmentDate, organization, service } = doc.data()

    const { cptCode = '', name: treatment = '', price = '' } = service || {}

    const { name: clinicName = '', providerName = '' } = organization || {}

    const options = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }

    const priceIsNaN = isNaN(parseFloat(price))

    return {
      id: doc.id,
      cpt: cptCode || '---',
      treatment: treatment || '---',
      billed: priceIsNaN
        ? '---'
        : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            parseFloat(price),
          ),
      date: convertFirestoreToDate(appointmentDate)?.toLocaleString('en-US', options) || 'pending',
      clinicName: clinicName || '---',
      providerName: providerName || '---',
    }
  })
}

const ClaimsActivity = () => {
  const { managedPlanData } = useCurrentProfile()

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'cpt', headerName: 'CPT Codes', width: 150 },
    {
      field: 'treatment',
      headerName: 'Treatment',
      width: 150,
      editable: true,
    },
    {
      field: 'billed',
      headerName: 'Billed',
      width: 150,
      editable: true,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 110,
      editable: true,
    },
    {
      field: 'clinicName',
      headerName: 'Clinic Name',
      width: 160,
      editable: true,
    },
    {
      field: 'providerName',
      headerName: 'Provider Name',
      width: 160,
      editable: true,
    },
  ]

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    setError(false)
    getClaims(managedPlanData?.managedPlanID)
      .then(claims => {
        setRows(claims)
        setLoading(false)
      })
      .catch(error => {
        console.error('Error getting claims:', error)
        setError('We encountered an error while fetching claims.')
        setLoading(false)
      })
  }, [managedPlanData])

  return (
    <Box>
      {loading && (
        <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
          <CircularProgress load size={100} />
        </Box>
      )}
      {error && (
        <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
          {error}
        </Box>
      )}
      {!loading && !error && (
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            minHeight: '200px',
          }}
        />
      )}
    </Box>
  )
}

export default ClaimsActivity
