import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { formatDateForUI } from '@app/libs/formatters'
import { stripeVoucherMetadata } from '@app/utils/stripeVoucherMetadata'
import { CircularProgress } from '@mui/material'
import { getVouchersData } from '@app/firebase/functions'
import Link from '@app/stories/Link'

function Row(props) {
  const { row, payoutData } = props
  const [rowExpand, setRowExpand] = useState({ open: false, uid: null })
  const [voucherDetails, setVoucherDetails] = useState([])
  const [rowLoading, setRowLoading] = useState(false)

  useEffect(() => {
    if (rowExpand.open) {
      const getMetadata = payoutData.find(payout => payout.uid === rowExpand.uid)
      if (getMetadata) {
        const { metadata } = getMetadata
        const voucherMetadata = stripeVoucherMetadata(metadata)
        if (voucherMetadata.length) {
          const vouchersId = voucherMetadata.map(voucher => voucher.voucherId)
          setRowLoading(true)
          getVouchersData({ vouchersId })
            .then(data => {
              setVoucherDetails(data)
              setRowLoading(false)
            })
            .catch(err => {
              console.log('err', err)
              setRowLoading(false)
            })
        }
      }
    }
  }, [rowExpand])

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setRowExpand({ open: !rowExpand.open, uid: row.uid })}
          >
            {rowExpand.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          ${(row.amount / 100).toLocaleString()}
        </TableCell>
        <TableCell align='center'>{row?.payoutId || '---'}</TableCell>
        <TableCell align='center'>{row?.traceId?.value || '---'}</TableCell>
        {/* <TableCell align='center'>{row?.balanceTransaction || '---'}</TableCell> */}
        <TableCell align='center'>{row?.status || '---'}</TableCell>
        {/* <TableCell align='center'>{formatDateForUI(row.created) || '---'}</TableCell> */}
        <TableCell align='center'>{formatDateForUI(row.arrivalDate) || '---'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={rowExpand.open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                Voucher Details
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Treatment </TableCell>
                    <TableCell align='left'>Price Paid out </TableCell>
                    <TableCell align='left'>Redeemed At </TableCell>
                    <TableCell align='left'>Voucher Code </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {voucherDetails &&
                    voucherDetails.map(
                      ({ id, treatment, code, redeemedAt, bundleID, pricePaidToDoc }, index) => (
                        <TableRow key={index}>
                          <TableCell align='left'>{treatment}</TableCell>
                          <TableCell align='left'>{pricePaidToDoc}</TableCell>
                          <TableCell align='left'>{formatDateForUI(redeemedAt)}</TableCell>
                          <TableCell align='left'>
                            <Link to={`/dashboard/vouchers/${id}`} type='internal' target='_blank'>
                              {code} {bundleID && `(subvoucher)`}
                            </Link>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  {rowLoading && (
                    <TableRow>
                      <TableCell colSpan={4} align='center'>
                        <CircularProgress size={24} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default function ClinicPayoutsTable({ payoutData }) {
  console.log('payoutData', payoutData)

  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align='center'>Amount</TableCell>
            <TableCell align='center'>Payout ID</TableCell>
            <TableCell align='center'>Trace Id</TableCell>
            {/* <TableCell align='center'>Balance Transaction</TableCell> */}
            <TableCell align='center'>Status</TableCell>
            <TableCell align='center'>Arrival Date</TableCell>
            {/* <TableCell align='center'>Date Initiated</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {payoutData &&
            payoutData.map(row => <Row payoutData={payoutData} key={row.uid} row={row} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
