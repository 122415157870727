import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import Icon from '@app/stories/Icons'
import Typography from '@app/stories/Typography'
import GeneralModal from '@app/containers/GeneralModal'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@mui/material'

const EobButton = ({ voucher, label }) => {
  return (
    <GeneralModal
      id='explanationOfBenefits'
      headerText='Explanation of Benefits'
      maxWidth={'lg'}
      customButton={
        <Button
          variant='outlined'
          size='small'
          style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
        >
          <Icon name='assignmentOutlined' />
          <Typography variant='body2' style={{ marginLeft: '8px' }}>
            {label}
          </Typography>
        </Button>
      }
    >
      <Box display='flex' justifyContent='center' alignItems='center'>
        <Box p={2}>
          <Box display='flex' justifyContent='space-between' alignItems='flex-start' pb={2}>
            <Box>
              <Typography variant='h6' fontWeight='bold' style={{ textTransform: 'capitalize' }}>
                Patient's Name: {voucher?.customer?.name || ''}
              </Typography>
              <Typography variant='h6' fontWeight='bold'>
                Patient Acct. Number: {voucher?.customer?.id || ''}
              </Typography>
              <Typography variant='h6' fontWeight='bold'>
                Paid Amount:{' '}
                <Typography variant='h6' component='span' color='error' fontWeight='bold'>
                  $87.13
                </Typography>
              </Typography>
              <Typography variant='h6' fontWeight='bold'>
                To: {voucher?.organization?.name || ''}
              </Typography>
              <Typography variant='h6' fontWeight='bold'>
                It is not your responsibility to pay:{' '}
                <Typography variant='h6' component='span' color='error' fontWeight='bold'>
                  $12.87
                </Typography>
              </Typography>
            </Box>
            <Box>
              <Typography variant='h6' fontWeight='bold'>
                Claim Number: {voucher?.id || ''}
              </Typography>
              <Typography variant='h6' fontWeight='bold'>
                Claim Processed Date: {voucher?.createdAt?.toDate()?.toLocaleDateString() || ''}
              </Typography>
              {/* <Typography variant='h6' fontWeight='bold'>
            Sequence Number: ''
            </Typography> */}
              <Typography variant='h6' fontWeight='bold'>
                Provider of Services: {voucher?.organization?.name || ''}
              </Typography>
              <Typography variant='h6' fontWeight='bold'>
                Place of Service: Outpatient
              </Typography>
            </Box>
          </Box>

          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SERVICE DATE(S)</TableCell>
                  <TableCell>TYPE OF SERVICE</TableCell>
                  <TableCell>TOTAL BILLED</TableCell>
                  <TableCell>OTHER AMOUNT(S)</TableCell>
                  <TableCell>PATIENT SAVINGS</TableCell>
                  <TableCell>APPLIED TO DEDUCTIBLE</TableCell>
                  <TableCell>COINSURANCE COPAYMENT AMOUNT</TableCell>
                  <TableCell>CLAIMS PAYMENT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{voucher?.createdAt?.toDate()?.toLocaleDateString() || ''}</TableCell>
                  {console.log(voucher)}
                  <TableCell>{voucher?.service?.name || ''}</TableCell>
                  <TableCell>{voucher?.service?.price || ''}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>12.87</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>87.13</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>TOTAL THIS CLAIM</TableCell>
                  <TableCell>{voucher?.service?.price || ''}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>12.87</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>87.13</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </GeneralModal>
  )
}

EobButton.propTypes = {
  voucher: PropTypes.object.isRequired,
  label: PropTypes.string,
}

export default EobButton
