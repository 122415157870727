import {
  Typography,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { useEffect, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  generateStripeConnectLink,
  createStripeLoginLink,
  stripePaymentEmbedded,
} from '@app/firebase/functions'
import { useCurrentProfile } from '@app/context/CurrentUser'
import { collection } from '@app/firebase/firestore'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import SaveIcon from '@mui/icons-material/Save'
import {
  ConnectPayouts,
  ConnectComponentsProvider,
  ConnectAccountOnboarding,
  ConnectPayments,
  ConnectNotificationBanner,
} from '@stripe/react-connect-js'
import { loadConnectAndInitialize } from '@stripe/connect-js'
import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'
import StripeConnectAccount from './StripeConnectAccount'

const VITE_STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY

const EFTEnrollment = () => {
  const { organizationId, email } = useCurrentProfile()
  const { planColorSecondary, planColorPrimary } = useWhiteLabelingContext()

  const [pageDataLoading, setPageDataLoading] = useState(false)
  const [orgBillingDetails, setOrgBillingDetails] = useState(false)

  const [showCreateStripeAccount, setShowCreateStripeAccount] = useState(false)
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null)
  const [generateStripeConnectLinkLoading, setGenerateStripeConnectLinkLoading] = useState(false)
  const [stripeConnectLoginLoading, setStripeConnectLoginLoading] = useState(false)

  const [showAccessLevelMessage, setShowAccessLevelMessage] = useState('')

  console.log('organizationId', organizationId)

  useEffect(() => {
    setPageDataLoading(true)
    if (organizationId) {
      const getClinicData = async () => {
        collection('organizations')
          .doc(organizationId)
          .onSnapshot(
            doc => {
              if (!doc.exists) {
                console.error('No such clinic document!') // eslint-disable-line
                return null
              }
              const { billing, allowBilling } = doc.data()
              setPageDataLoading(false)
              setOrgBillingDetails({
                id: doc.id,
                ...billing,
              })
              const checkEnable = allowBilling?.find(e => e.email === email)?.enable === true
              if (!billing && checkEnable) {
                setShowCreateStripeAccount(true)
              }
              if (!checkEnable) {
                setShowAccessLevelMessage('You do not have access to this page')
              }
            },
            error => {
              console.error('  ** Error getting clinic data:', err) // eslint-
            },
          )
      }
      getClinicData()
    } else {
      setPageDataLoading(false)
    }
  }, [organizationId, email])

  console.log('orgBillingDetails----', orgBillingDetails)

  const getStripeConnectInstance = () => {
    const fetchClientSecret = async () => {
      return await stripePaymentEmbedded({
        stripeConnectAccountId: orgBillingDetails?.stripeConnectAccountId,
      })
        .then(async data => {
          const { client_secret: clientSecret } = data
          console.log('clientSecret', clientSecret)
          return clientSecret
        })
        .catch(err => {
          console.log('err', err)
        })
    }

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: VITE_STRIPE_KEY || '',
      fetchClientSecret: fetchClientSecret,
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins',
        },
      ],
      appearance: {
        overlays: 'dialog',
        variables: {
          colorPrimary: planColorPrimary || '#652d92',
          colorSecondary: planColorSecondary || '#f7f9fc',
          colorBackground: 'white',
          colorError: 'red',
          colorSuccess: 'green',
          colorText: 'black',
          fontFamily: 'Poppins',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '16px',
          color: '#000',
          '&.Mui-selected, &.Mui-selected:hover': {
            color: '#fff',
            backgroundColor: '#662d91',
          },
        },
      },
    })
  }

  useEffect(() => {
    if (orgBillingDetails?.stripeConnectAccountId) {
      setStripeConnectInstance(getStripeConnectInstance())
    }
  }, [orgBillingDetails])

  const generateLink = async () => {
    setGenerateStripeConnectLinkLoading(true)
    await generateStripeConnectLink({
      stripeConnectAccountId: orgBillingDetails?.stripeConnectAccountId,
      returnURL: window.location.origin,
    })
      .then(data => {
        const { stripeRedirectLink } = data
        console.log('stripeRedirectLink', stripeRedirectLink)
        window.location.href = stripeRedirectLink
        setGenerateStripeConnectLinkLoading(false)
      })
      .catch(err => {
        console.log('err', err)
        setGenerateStripeConnectLinkLoading(false)
      })
  }

  // for stripe connect portal login
  const stripeLoginLink = async () => {
    setStripeConnectLoginLoading(true)
    await createStripeLoginLink({
      stripeConnectAccountId: orgBillingDetails?.stripeConnectAccountId,
      returnURL: window.location.origin,
    })
      .then(data => {
        const { stripeRedirectLink } = data
        console.log('stripeRedirectLink', stripeRedirectLink)
        window.location.href = stripeRedirectLink
        setStripeConnectLoginLoading(false)
      })
      .catch(err => {
        setStripeConnectLoginLoading(false)

        console.log('err', err)
      })
  }

  return (
    <div>
      {pageDataLoading ? (
        <CircularProgress />
      ) : (
        <>
          {showAccessLevelMessage && <Typography>{showAccessLevelMessage}</Typography>}

          {showCreateStripeAccount && <StripeConnectAccount />}

          {!showCreateStripeAccount && stripeConnectInstance && (
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 5,
                }}
              >
                <Box
                  sx={{
                    bgcolor: 'background.paper',
                    width: { xs: '100%', md: '50%', lg: '20%' },
                    borderRadius: 1,
                    border: 1,
                    borderColor: 'grey.300',
                    borderStyle: 'solid',
                    padding: 2,
                  }}
                >
                  <Typography variant='h4'>Bank Setup Status</Typography>
                  <Box mt={5}>
                    <ConnectNotificationBanner />
                  </Box>
                  <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <Box display='flex' alignItems='center' mb={2}>
                      {orgBillingDetails?.detailsSubmitted ? <CheckIcon /> : <CloseIcon />}
                      <Typography variant='body1'>Details submitted</Typography>
                    </Box>
                    <Box display='flex' alignItems='center' mb={2}>
                      {orgBillingDetails?.payoutsEnabled ? <CheckIcon /> : <CloseIcon />}
                      <Typography variant='body1'>Payouts enabled</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    bgcolor: 'background.paper',
                    width: { xs: '100%', md: '50%', lg: '30%' },
                    borderRadius: 1,
                    border: 1,
                    borderColor: 'grey.300',
                    borderStyle: 'solid',
                    padding: 2,
                  }}
                >
                  <Typography variant='h4'>Stripe account details</Typography>
                  <ConnectAccountOnboarding />
                </Box>

                <Box
                  sx={{
                    bgcolor: 'background.paper',
                    width: { xs: '100%', md: '100%', lg: '50%' },
                    borderRadius: 1,
                    border: 1,
                    borderColor: 'grey.300',
                    borderStyle: 'solid',
                    padding: 2,
                  }}
                >
                  <Typography marginBottom={5} variant='h4'>
                    Payouts
                  </Typography>
                  <ConnectPayouts />
                </Box>
              </Box>
            </ConnectComponentsProvider>
          )}
        </>
      )}
    </div>
  )
}

export default EFTEnrollment
