import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Hidden } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Container from '../stories/Container'
import Section from '../stories/Section'
import { LiveChatSection, LiveChatContainer } from '../stories/LiveChat'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import { scrollToHash } from '../libs/scroll'
import { Number } from '@app/components/MisheFlow'

import ForCliniciansHero from '../assets/images/hero-for-clinicians.png'
import ForCliniciansSection1 from '../assets/images/for-clinicians-section-1.png'
import ForCliniciansSection2 from '../assets/images/for-clinicians-section-2.png'
import ForCliniciansSection3 from '../assets/images/for-clinicians-section-3.png'

import HubspotContactForm from '@app/components/HubSpotForm'

const WhyMishePage = () => {
  const { hash, search } = useLocation()
  const ref = new URLSearchParams(search).get('ref')
  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'For Clinicians',
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timeout
    if (hash) {
      timeout = setTimeout(() => {
        // wait 0.3s before scrolling
        scrollToHash(hash)
      }, 200)
    }
    return () => {
      // clear timeout on re-render
      clearTimeout(timeout)
    }
  })

  return (
    <>
      <Helmet>
        <title>For Clinicians | More Patients. Less Overhead.</title>
        <meta property='og:title' content='For Clinicians | More Patients. Less Overhead.' />
        <meta
          name='description'
          property='og:description'
          content='Bypass insurance restrictions and avoid billing costs with guaranteed payment directly from consumers. Receive 100% of earnings at your cash price.'
        />
        <meta property='og:url' content='https://mishe.co/forclinicians' />
        <link rel='canonical' href='https://mishe.co/forclinicians' />
      </Helmet>
      <Section>
        <Container>
          <Grid container spacing={3} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Typography variant='h1' gutterBottom>
                mishe for{' '}
                <Box component='span' color='primary.main'>
                  Clinicians
                </Box>
              </Typography>
              <Typography variant='body1' weight='light' style={{ maxWidth: '466px' }}>
                Add more consumers and reduce overhead by increasing visibility to your services.
                <br /> <br />
                Bypass insurance and eliminate billing costs with guaranteed payment at the cash
                prices you set.
                <br /> <br />
                Receive 100% of your earnings and compete in the direct-to-consumer arena by
                offering direct care online.
              </Typography>
              <Box mt={4} mb={1}>
                <Button
                  type='button'
                  size='medium'
                  onClick={() => {
                    scrollToHash('#request-demo')
                  }}
                >
                  Request a demo
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} />
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img src={ForCliniciansHero} alt='ForCliniciansHero' width='100%' />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section bgcolor='background.smoke'>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box mb={1}>
                <Typography variant='caption' weight='semibold'>
                  <Box component='span' color='primary.main'>
                    GROW YOUR PRACTICE
                  </Box>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant='h3' gutterBottom>
                  Market your services
                  <br />
                  with a{' '}
                  <Box component='span' color='primary.main'>
                    digital front door.
                  </Box>
                </Typography>
              </Box>
              <Typography variant='body1' weight='light' style={{ maxWidth: '480px' }}>
                Price transparency is a priority for the 1-in-3 consumers paying out-of-pocket for
                care. Unlock demand from the 60% of people avoiding care due to price uncertainty.
              </Typography>
              <br /> <br />
              <Box mb={3}>
                <Typography
                  variant='body2'
                  gutterBottom
                  weight='light'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <CheckCircleIcon style={{ color: '#662D91' }} />
                  <span style={{ marginLeft: 20 }}>
                    Meet consumers online where they are searching for care
                  </span>
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography
                  variant='body2'
                  gutterBottom
                  weight='light'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <CheckCircleIcon style={{ color: '#662D91' }} />
                  <span style={{ marginLeft: 20 }}>
                    Build trust and acquire new patients while reducing AR
                  </span>
                </Typography>
              </Box>
              <Typography
                variant='body2'
                gutterBottom
                weight='light'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <CheckCircleIcon style={{ color: '#662D91' }} />
                <span style={{ marginLeft: 20 }}>
                  Built-in referral source from our community of clinicians
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} />
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <img src={ForCliniciansSection1} alt='ForCliniciansSection1' width='100%' />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box mb={1}>
                <Typography variant='caption' weight='semibold'>
                  <Box component='span' color='primary.main'>
                    SUPERIOR PAYMENT EXPERIENCE
                  </Box>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant='h3' gutterBottom>
                  The industry is changing.
                  <br />
                  Stay competitive with{' '}
                  <Box component='span' color='primary.main'>
                    innovative offerings.
                  </Box>
                </Typography>
              </Box>
              <Typography variant='body1' weight='light' style={{ maxWidth: '480px' }}>
                Direct access to your services guarantees payment and eliminates insurance abrasion.
                Improve relationships by helping consumers shop and access care with confidence.
              </Typography>
              <br /> <br />
              <Box mb={3}>
                <Typography
                  variant='body2'
                  gutterBottom
                  weight='light'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <CheckCircleIcon style={{ color: '#662D91' }} />
                  <span style={{ marginLeft: 20 }}>Contactless upfront payment, no billing</span>
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography
                  variant='body2'
                  gutterBottom
                  weight='light'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <CheckCircleIcon style={{ color: '#662D91' }} />
                  <span style={{ marginLeft: 20 }}>
                    Accept HSA/FSA, cryptocurrency and buy-now-pay-later payments
                  </span>
                </Typography>
              </Box>
              <Typography
                variant='body2'
                gutterBottom
                weight='light'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <CheckCircleIcon style={{ color: '#662D91' }} />
                <span style={{ marginLeft: 20 }}>Reduce headaches, surprises and costs</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} />
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img src={ForCliniciansSection2} alt='ForCliniciansSection2' width='100%' />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section bgcolor='background.smoke'>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box mb={1}>
                <Typography variant='caption' weight='semibold'>
                  <Box component='span' color='primary.main'>
                    TAKE BACK CONTROL
                  </Box>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant='h3' gutterBottom>
                  <Box component='span' color='primary.main'>
                    Bypass insurance{' '}
                  </Box>
                  &<br />
                  simplify your practice.
                </Typography>
              </Box>
              <Typography variant='body1' weight='light' style={{ maxWidth: '480px' }}>
                Ensure a frictionless payment experience with no middle-man interference. Get paid
                for innovative offerings while exploring new delivery channels and care pathways.
              </Typography>
              <br /> <br />
              <Box mb={3}>
                <Typography
                  variant='body2'
                  gutterBottom
                  weight='light'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <CheckCircleIcon style={{ color: '#662D91' }} />
                  <span style={{ marginLeft: 20 }}>Maximize productivity with dynamic pricing</span>
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography
                  variant='body2'
                  gutterBottom
                  weight='light'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <CheckCircleIcon style={{ color: '#662D91' }} />
                  <span style={{ marginLeft: 20 }}>Distribute demand efficiently</span>
                </Typography>
              </Box>
              <Typography
                variant='body2'
                gutterBottom
                weight='light'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <CheckCircleIcon style={{ color: '#662D91' }} />
                <span style={{ marginLeft: 20 }}>
                  Protect against no-shows and last-minute cancellations
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} />
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <img src={ForCliniciansSection3} alt='ForCliniciansSection3' width='100%' />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section id='howitworks'>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box mb={1} textAlign='center'>
                <Typography variant='caption' weight='semibold'>
                  <Box component='span' color='primary.main'>
                    HOW IT WORKS
                  </Box>
                </Typography>
              </Box>
              <Box mb={5}>
                <Typography variant='h2' gutterBottom align='center'>
                  This is healthcare,{' '}
                  <Box component='span' color='primary.main'>
                    simplified.
                  </Box>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid id='exp_serv_sched' container spacing={7}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box align='center' mb={2}>
                <Number>1</Number>
              </Box>
              <Typography variant='h4' gutterBottom align='center'>
                Patient purchases care on marketplace.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box align='center' mb={2}>
                <Number>2</Number>
              </Box>
              <Typography variant='h4' gutterBottom align='center'>
                Appointment scheduled with clinicians.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box align='center' mb={2}>
                <Number>3</Number>
              </Box>
              <Typography variant='h4' gutterBottom align='center'>
                mishe voucher redeemed & care provided.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box align='center' mb={2}>
                <Number>4</Number>
              </Box>
              <Typography variant='h4' gutterBottom align='center'>
                100% payment delivered in-full.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <LiveChatSection>
        <Container>
          <LiveChatContainer>
            <Container>
              <Grid container spacing={3} align='center' alignItems='center'>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Hidden mdDown>
                    <Typography
                      // id='requestDemo'
                      variant='caption'
                      weight='semibold'
                      align='center'
                    >
                      <Box component='span' color='primary.main'>
                        LETS GET STARTED
                      </Box>
                    </Typography>
                    <Typography variant='h3' gutterBottom weight='medium' align='center'>
                      Are you ready to revolutionize your
                      <br />
                      practice with mishe?
                    </Typography>
                    <Typography variant='body1' weight='light' align='center'>
                      Our team is standing by to deliver a full platform demo
                      <br />
                      and talk about how mishe can best fit your needs.
                    </Typography>
                  </Hidden>
                  <Hidden mdUp>
                    <Typography
                      // id='requestDemo'
                      variant='body1'
                      style={{ fontWeight: '600' }}
                      align='center'
                    >
                      <Box component='span' color='primary.main'>
                        LETS GET STARTED
                      </Box>
                    </Typography>
                    <Typography variant='h3' gutterBottom align='center'>
                      Are you ready to revolutionize your practice with mishe?
                    </Typography>
                    <Typography variant='body1' align='center'>
                      Our team is standing by to deliver a full platform demo and talk about how
                      mishe can best fit your needs.
                    </Typography>
                    <Box mt={4} mb={1} textAlign='center'>
                      <Button className='open-live-chat' size='medium'>
                        Live chat with concierge
                      </Button>
                    </Box>
                  </Hidden>
                </Grid>
                <Grid item xs={12} sm={12} md={1} lg={1} />
                <Grid item xs={12} sm={12} md={12} lg={12} align='center'>
                  <HubspotContactForm />
                </Grid>
              </Grid>
            </Container>
          </LiveChatContainer>
        </Container>
      </LiveChatSection>
    </>
  )
}

export default WhyMishePage
