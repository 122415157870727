import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { Divider } from '@mui/material'
import { signIn } from '../firebase/auth'
import Link from '../stories/Link'
import { GENDER_OPTIONS } from '../libs/validators'
import PhoneField from '../stories/PhoneInput'
import SelectField from '../stories/Select'
import TextField from '../stories/TextField'
import Notification from './NotificationText'
import Typography from '../stories/Typography'
import CheckoutStatus from '../stories/CheckoutStatus'
import Button from '../stories/Button'
import { useQuickCheckout } from '../context/QuickCheckoutNetwork'
import { useCurrentUser, useCurrentProfile } from '../context/CurrentUser'
import parseQueryString from '../libs/parseQueryStrings'
import { scrollToHash } from '../libs/scroll'
import BookingPolicy from '../stories/BookingPolicy'
import StateSelectInput from './StateSelectInput'

import { attachCustomer } from '../firebase/functions'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import styled from '@mui/system/styled'
import DobPicker from './DobPicker'
import { DatePicker } from '@mui/x-date-pickers'
import DateRangeIcon from '@mui/icons-material/DateRange'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import useIdentificationCards from '../hooks/useIdentificationCards'

import { formatDateForDatePicker, convertFirestoreToDate } from '../libs/formatters'

import useSignInUpModals from '../hooks/useSignInUpModals'
import SignInModal from './SignInModal'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const CheckoutForm = ({
  orderCompletionCallback,
  paymentIntentID,
  // hasScript,
  // pharmacyRequested,
  // deliveryMethod,
  // medicationRequested,
  // otherMedicationInfo,
}) => {
  const { checkProfileForID, checkForIdentificationInfo, updateProfileWithIdentificationInfo } =
    useIdentificationCards()

  const { search } = useLocation()
  const stripe = useStripe()
  const elements = useElements()
  const user = useCurrentUser()
  const profile = useCurrentProfile()
  const { createAccount, disabled, setDisabled } = useQuickCheckout()

  // Flow States
  const [checkoutStep, setCheckoutStep] = useState(0)

  // Payment related states
  const [paymentProcessing, setPaymentProcessing] = useState(false)
  const [error, setError] = useState('')
  const [pendingClientSecret, setPendingClientSecret] = useState('')
  const [successfulPaymentIntent, setSuccessfulPaymentIntent] = useState('')
  const [paymentStatus, setPaymentStatus] = useState(null)

  // Load error states
  const [errorTimer, setErrorTimer] = useState(null)
  const [loadError, setLoadError] = useState(null)

  // Patient Info States
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [birthday, setBirthday] = useState(null)
  const [gender, setGender] = useState('')

  const [street, setStreet] = useState('')
  const [street2, setStreet2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')

  const [idNumber, setIdNumber] = useState('')
  const [idExpiry, setIdExpiry] = useState(null)
  const [idImage, setIdImage] = useState(null)

  const [terms, setTermsAccepted] = useState(false)
  const [hipaa, setHipaaAccepted] = useState(false)
  const [smsOptIn, setSmsOptIn] = useState(false)

  // New Patient Info
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  // Other info
  const [bookingPolicy, setBookingPolicy] = useState(false)

  // UI States
  const [preview, setPreview] = useState(null)
  const [idErrorMessage, setIdErrorMessage] = useState(null)
  const [validIdInfo, setValidIdInfo] = useState(false)
  const { signInOpen, handleOpen } = useSignInUpModals()

  // const [selectedDeliveryOption, setSelectedDeliveryOption] = useState('TwoDay')

  // const DELIVERY_OPTIONS = [
  //   { key: 'Overnight', value: 'Overnight', text: 'Overnight', label: 'Overnight' },
  //   { key: 'TwoDay', value: 'TwoDay', text: 'Two Day', label: 'Two Day' },
  // ]

  const errors = {
    firstname: '',
    lastname: '',
    email: '',
    dob: '',
    gender: '',
    phone: '',
    terms: '',
    hipaa: '',
    smsOptIn: '',
    bookingPolicy: '',
  }

  // This is used to display a pre-existing ID image
  useEffect(() => {
    if (!idImage) {
      setPreview(null)
      return
    }
    setPreview(URL.createObjectURL(idImage))
  }, [idImage])

  // This is how we prefil the checkout form with the user's profile information if they're logged in
  useEffect(() => {
    if (profile?.firstname) {
      setFirstname(profile.firstname)
    }
    if (profile?.lastname) {
      setLastname(profile.lastname)
    }
    if (profile?.email) {
      setEmail(profile.email)
    }

    if (profile?.phone) {
      setPhone(profile.phone)
    }

    if (profile?.birthday || profile?.dob) {
      setBirthday(convertFirestoreToDate(profile.dob) || formatDateForDatePicker(profile.birthday))
    }

    if (profile?.gender) {
      setGender(profile?.gender)
    }

    if (profile?.address) {
      if (profile?.address?.street) {
        setStreet(profile.address.street)
      }
      if (profile?.address?.street2) {
        setStreet2(profile.address.street2)
      }
      if (profile?.address?.city) {
        setCity(profile.address.city)
      }
      if (profile?.address?.state) {
        setState(profile.address.state)
      }
      if (profile?.address?.zip) {
        setZip(profile.address.zip)
      }
    }

    setValidIdInfo(checkProfileForID({ profile, setIdErrorMessage }))
  }, [profile])

  useEffect(() => {
    if (!user.uid) {
      setCheckoutStep(0)
    }
  }, [user])

  useEffect(() => {
    if (validIdInfo && checkoutStep === 1) {
      setCheckoutStep(2)
    }
  }, [validIdInfo])

  // This is used to display an error message if its taking too long to load the payment status
  useEffect(() => {
    if (loadError) {
      clearTimeout(errorTimer)
    }
    setErrorTimer(
      setTimeout(() => {
        setLoadError(true)
      }, 10000),
    )
  }, [])

  // This is how we get the payment intent client secret from the URL
  useEffect(() => {
    const { payment_intent_client_secret } = parseQueryString(search) // eslint-disable-line
    setPendingClientSecret(payment_intent_client_secret)
  }, [search])

  useEffect(() => {
    async function checkPaymentStatus() {
      console.log('getting payment status') // eslint-disable-line
      const result = await stripe.retrievePaymentIntent(pendingClientSecret)
      const { paymentIntent } = result
      console.log(paymentIntent) // eslint-disable-line
      if (result.error) {
        setPaymentStatus('error')
        window.Intercom('trackEvent', 'Checkout Error', {
          typeOfCheckout: 'Medication',
          error: result.error || 'None',
        })
        return undefined
      }

      if (!paymentIntent) {
        setPaymentStatus('error')
        return undefined
      }

      if (paymentIntent.error) {
        console.log(paymentIntent.error) // eslint-disable-line
        return undefined
      }

      if (paymentIntent.status === 'succeeded') {
        console.log('Payment Succeeded!') // eslint-disable-line
      }
      if (paymentIntent.status === 'requires_payment_method') {
        console.log('Payment Failed!') // eslint-disable-line
      }
      if (paymentIntent.status === 'requires_action') {
        console.log('Payment Requires Action!') // eslint-disable-line
      }
      if (paymentIntent.status === 'processing') {
        console.log('Payment Processing!') // eslint-disable-line
      }

      window.Intercom('trackEvent', `Checkout ${paymentIntent.status}`, {
        typeOfCheckout: 'Medication',
        paymentIntent: paymentIntent.id,
        paymentStatus: paymentIntent.status,
        error: paymentIntent.error || 'None',
      })
      setSuccessfulPaymentIntent(paymentIntent.id)
      setPaymentStatus(`${paymentIntent.error ? 'error' : paymentIntent.status}`)
    }
    if (!stripe || !pendingClientSecret) {
      return undefined
    }

    checkPaymentStatus()
  }, [pendingClientSecret, stripe])

  useEffect(() => {
    if (paymentStatus === 'error' || paymentStatus === 'requires_payment_method') {
      setPendingClientSecret(null)
    }
  }, [paymentStatus])

  const errorHandler = ({ profileValues, passwordValue, confirmPassword, existingProfileData }) => {
    setError('')
    if (!user?.uid) {
      if (passwordValue !== confirmPassword) {
        setError('Passwords do not match.')
        setDisabled(false)
        return false
      }

      if (!profileValues?.email) {
        setError('Please enter your email.')
        setDisabled(false)
        return false
      }
    }

    if (!profileValues?.firstname) {
      setError('Please enter your first name.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.lastname) {
      setError('Please enter your last name.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.phone) {
      setError('Please enter your phone number.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.dob) {
      setError('Please enter your date of birth.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.terms && !existingProfileData?.terms) {
      setError('Please agree to the terms.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.hipaa && !existingProfileData?.hipaa) {
      setError('Please agree to the HIPAA Authorization.')
      setDisabled(false)
      return false
    }

    if (!profileValues?.smsOptIn && !existingProfileData?.smsOptIn) {
      setError('Please agree to the text opt in.')
      setDisabled(false)
      return false
    }

    if (checkoutStep === 1 && !validIdInfo && (!idImage || !idExpiry || !idNumber)) {
      setError('Please upload your ID.')
      setDisabled(false)
      return false
    }

    return true
  }

  // Step 0 - Patient Info
  const handlePatientInfo = useCallback(async () => {
    setError('')
    const profileValues = {
      firstname,
      lastname,
      email,
      phone,
      birthday,
      dob: birthday,
      gender,
      address: {
        street,
        street2,
        city,
        state,
        zip,
      },
      terms,
      hipaa,
      smsOptIn,
    }

    console.log('  == Profile Values: ', profileValues) // eslint-disable-line

    // Check for errors
    console.log('  ** running error handler') // eslint-disable-line

    try {
      if (
        !errorHandler({
          profileValues,
          passwordValue: password,
          confirmPassword,
          existingProfileData: profile,
        })
      ) {
        console.log('** Error handler threw error') // eslint-disable-line
        return
      }
    } catch (e) {
      console.log('** Error handler caught error') // eslint-disable-line
      console.log(e) // eslint-disable-line
      setDisabled(false)
      return
    }

    // JAMES YOU LEFT OFF HERE THEORIZING WHERE YOU SHOULD ADD THE ID PROTOCOL

    // Check if user is logged in
    if (!user.uid) {
      console.log('  -- no uid running create account flow') // eslint-disable-line

      const { status, uid, code } = await createAccount(
        profileValues?.email,
        password,
        profileValues,
      )
      if (status === 'error') {
        // check firebase signIn response for errors
        if (code === 'auth/email-already-in-use') {
          console.log('email already in use') // eslint-disable-line
          try {
            await signIn(profileValues.email, password)
          } catch (signInError) {
            console.log('error signing in caught') // eslint-disable-line
            console.log(signInError) // eslint-disable-line
            if (signInError.code === 'auth/wrong-password') {
              setError('Incorrect Password')
              setDisabled(false)
              return
            }
            setDisabled(false)
            return
          }
        }

        if (!code === 'auth/weak-password') {
          setError('Password is too weak.')
          setDisabled(false)
          return
        }

        if (code === 'auth/invalid-email') {
          setError('Invalid Email')
          setDisabled(false)
          return
        }

        if (code === 'auth/operation-not-allowed') {
          setError('Please contact support. There was an error creating your account.')
          setDisabled(false)
          return
        }
      } else {
        profileValues.uid = uid
      }
    } else {
      profileValues.email = user.email
    }

    console.log(' -- Profile Values')
    console.log(profileValues)

    return true
  }, [
    user,
    firstname,
    lastname,
    email,
    phone,
    birthday,
    gender,
    street,
    street2,
    city,
    state,
    zip,
    terms,
    hipaa,
    smsOptIn,
    bookingPolicy,
    password,
    confirmPassword,
    profile,
  ])

  // Step 1 - ID Info
  const handleIdInfo = useCallback(async () => {
    setError('')

    console.log('  -- uploading id info')

    if (
      !checkForIdentificationInfo({
        idNumber,
        idExpiry,
        idImageFile: idImage,
        errorCallback: setIdErrorMessage,
      })
    ) {
      console.log('  ** id info not valid')
      setDisabled(false)
      return
    }

    const idUploadLink = await updateProfileWithIdentificationInfo({
      uid: user.uid,
      idNumber,
      idExpiry,
      idFile: idImage,
    })

    console.log('  -- id upload result')
    console.log(idUploadLink ? 'success' : 'error')

    if (!idUploadLink) {
      console.log('  ** error uploading id info')
      setIdErrorMessage(
        'Oh uh, looks like we ran into an issue uploading your ID info.\nPlease contact support if this issue persists.',
      )
      setDisabled(false)
      return
    }

    return true
  }, [idNumber, idExpiry, idImage, user])

  // Step 2 - Payment
  const handlePayment = useCallback(async () => {
    setDisabled(true)
    setPaymentProcessing(true)
    setError('')

    console.log(bookingPolicy)

    if (!bookingPolicy) {
      setError('Please agree to the booking policy.')
      setDisabled(false)
      setPaymentProcessing(false)
      return false
    }

    // Check if stripe and elements are loaded
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.error(' ** stripe or elements null')
      setDisabled(false)
      setPaymentProcessing(false)
      return
    }

    const attachCustomerResult = await attachCustomer({
      paymentIntentID,
    }).catch(error => {
      console.error(' ** error attaching customer')
      console.error(error)
      return { error: true }
    })

    if (attachCustomerResult.error) {
      console.error(' ** error attaching customer')
      setError('Error attaching customer')
      setDisabled(false)
      setPaymentProcessing(false)
      return
    }

    // Trigger payment on stripe

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.href}`,
      },
      redirect: 'if_required',
    })

    console.log('Stripe result')
    console.log(result)

    if (result?.error) {
      window.Intercom('trackEvent', 'Checkout Error', {
        typeOfCheckout: 'Medication',
        errorType: result.error.type,
        errorCode: result.error.code,
        errorDeclineReason: result.error.decline_code || 'None',
      })
      setDisabled(false)
      setPaymentProcessing(false)
      setPaymentStatus('error')
      return
    }

    window.Intercom('trackEvent', `Checkout ${result.paymentIntent.status}`, {
      typeOfCheckout: 'Medication',
      paymentIntent: result.paymentIntent.id,
      paymentStatus: result.paymentIntent.status,
    })

    if (
      result.paymentIntent.status === 'requires_payment_method' ||
      result.paymentIntent.status === 'requires_action'
    ) {
      setDisabled(false)
      setPaymentProcessing(false)
    } else {
      setSuccessfulPaymentIntent(result.paymentIntent.id)
    }
    setPaymentStatus(result.paymentIntent.status)
    orderCompletionCallback(result.paymentIntent.status)
    scrollToHash('#checkoutContainer')
    setPaymentProcessing(false)
  }, [stripe, elements, bookingPolicy, user, orderCompletionCallback, paymentIntentID])

  return (
    <Box id='checkoutContainer'>
      {!pendingClientSecret && paymentStatus !== 'succeeded' && paymentStatus !== 'processing' ? (
        <Grid container spacing={1}>
          {checkoutStep === 0 && (
            <>
              <SignInModal
                open={signInOpen}
                handleClose={() => handleOpen('signIn')}
                callback={() => handleOpen('signIn')}
              />
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant='h4' align='center'>
                  Patient Information
                </Typography>
                {!user.uid && (
                  <Typography variant='caption' align='center'>
                    Already have an account?{' '}
                    <Button size='small' variant='outlined' onClick={() => handleOpen('signIn')}>
                      Login
                    </Button>
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  required
                  value={firstname}
                  onChange={e => setFirstname(e.target.value)}
                  disabled={disabled}
                  name='firstname'
                  label='first name'
                  autoComplete='given-name'
                  helperText={errors.firstname}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  required
                  value={lastname}
                  onChange={e => setLastname(e.target.value)}
                  disabled={disabled}
                  name='lastname'
                  label='last name'
                  autoComplete='family-name'
                  helperText={errors.lastname}
                />
              </Grid>
              {!user.uid && (
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      disabled={disabled}
                      name='email'
                      type='email'
                      label='e-mail'
                      autoComplete='email'
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <TextField
                      required
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      disabled={disabled}
                      name='password'
                      type='password'
                      autoComplete='new-password'
                      label='password'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <TextField
                      required
                      value={confirmPassword}
                      onChange={e => setConfirmPassword(e.target.value)}
                      disabled={disabled}
                      name='confirmPassword'
                      type='password'
                      autoComplete='new-password'
                      label='Confirm Password'
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={6}>
                <PhoneField
                  required
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  name='phone'
                  disabled={disabled}
                  label='telephone number'
                  autoComplete='tel'
                  helperText={errors.phone}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  flexDirection='column'
                  width='100%'
                  paddingTop='16px'
                >
                  <DobPicker dob={birthday} setDob={setBirthday} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <SelectField
                  required
                  value={gender}
                  onChange={e => {
                    setGender(e.target.value)
                  }}
                  disabled={disabled}
                  label='gender'
                  name='gender'
                  options={GENDER_OPTIONS}
                  helperText={errors.gender}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  required
                  value={street}
                  onChange={e => setStreet(e.target.value)}
                  display='standard'
                  id='t-street'
                  label='Street Address'
                  variant='outlined'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  value={street2}
                  onChange={e => setStreet2(e.target.value)}
                  display='standard'
                  id='t-street2'
                  label='Apt, Suite, etc.'
                  variant='outlined'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={5}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  flexDirection='column'
                  width='100%'
                  paddingTop='16px'
                >
                  <TextField
                    required
                    value={city}
                    onChange={e => setCity(e.target.value)}
                    display='standard'
                    id='t-city'
                    label='City'
                    variant='outlined'
                    fullWidth
                    style={{
                      margin: '0',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  flexDirection='column'
                  width='100%'
                  paddingTop='16px'
                >
                  <StateSelectInput
                    required
                    state={state}
                    onChange={setState}
                    id='t-state'
                    label='State'
                    variant='outlined'
                    defaultValue=''
                    fullWidth
                    style={{
                      backgroundColor: '#fff',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  flexDirection='column'
                  width='100%'
                  paddingTop='16px'
                >
                  <TextField
                    required
                    value={zip}
                    onChange={e => setZip(e.target.value)}
                    display='standard'
                    id='t-zip'
                    label='Zip Code'
                    variant='outlined'
                    fullWidth
                    style={{
                      margin: '0',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {(!profile?.terms || !profile?.hipaa || !profile?.smsOptIn) && ( // eslint-disable-line
                  <Grid item xs={12} sm={12}>
                    <Typography variant='body2'>
                      To use this account please review and agree to the following:
                    </Typography>
                  </Grid>
                )}
                {!profile?.terms && (
                  <Grid item xs={12} sm={12}>
                    <Box display='flex' alignItems='center'>
                      <Checkbox
                        disabled={disabled}
                        name='terms'
                        checked={terms}
                        onChange={() => setTermsAccepted(prev => !prev)}
                        color='primary'
                        inputProps={{ 'aria-label': 'terms' }}
                      />
                      <Typography variant='body2' color={errors.terms ? 'error' : 'initial'}>
                        <Link
                          display='standard'
                          size='small'
                          target='_blank'
                          to='/privacy-policy'
                          style={{ fontWeight: '300' }}
                        >
                          Privacy Policy
                        </Link>{' '}
                        ,{' '}
                        <Link
                          display='standard'
                          size='small'
                          target='_blank'
                          to='/terms'
                          style={{ fontWeight: '300' }}
                        >
                          Terms of Use
                        </Link>{' '}
                        and{' '}
                        <Link
                          display='standard'
                          size='small'
                          target='_blank'
                          to='/eula-consumer'
                          style={{ fontWeight: '300' }}
                        >
                          EULA
                        </Link>
                        .
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {!profile?.hipaa && (
                  <Grid item xs={12} sm={12}>
                    <Box display='flex' alignItems='center'>
                      <Checkbox
                        disabled={disabled}
                        checked={hipaa}
                        onChange={() => setHipaaAccepted(prev => !prev)}
                        name='hipaa'
                        color='primary'
                        inputProps={{ 'aria-label': 'hipaa' }}
                      />
                      <Typography variant='body2' color={errors.hipaa ? 'error' : 'initial'}>
                        <Link
                          display='standard'
                          size='small'
                          target='_blank'
                          to='/hipaa'
                          style={{ fontWeight: '300' }}
                        >
                          HIPAA Authorization
                        </Link>
                      </Typography>
                      .
                    </Box>
                  </Grid>
                )}
                {!profile?.smsOptIn && (
                  <Grid item xs={12} sm={12}>
                    <Box display='flex' alignItems='center'>
                      <Checkbox
                        disabled={disabled}
                        checked={smsOptIn}
                        onChange={() => setSmsOptIn(!smsOptIn)}
                        name='smsOptIn'
                        color='primary'
                        inputProps={{ 'aria-label': 'Text Opt In' }}
                      />
                      <Typography variant='body2' color={errors.smsOptIn ? 'error' : 'initial'}>
                        Agree to receive SMS about your account and orders. Message and data rates
                        may apply.
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Grid>
                <Button
                  fullWidth
                  onClick={async () => {
                    setDisabled(true)
                    const status = await handlePatientInfo()

                    if (!status) {
                      setDisabled(false)
                      console.warn('Error handling patient info')
                      return
                    }

                    setDisabled(false)

                    if (!validIdInfo) {
                      setCheckoutStep(1)
                    } else {
                      setCheckoutStep(2)
                    }
                  }}
                >
                  Next
                </Button>
              </Grid>
            </>
          )}
          {checkoutStep === 1 && !validIdInfo && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={5}>
                <Typography variant='h4' align='center'>
                  Government ID
                </Typography>
              </Grid>
              {idErrorMessage && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant='body1'
                    style={{
                      color: 'red',
                    }}
                  >
                    {idErrorMessage}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box>
                  <Typography variant='caption'>
                    Enter your ID/Drivers License Number as it appears.
                  </Typography>
                </Box>
                <TextField
                  id='dirx-id-number'
                  value={idNumber}
                  onChange={e => {
                    setIdNumber(e.target.value)
                  }}
                  label='ID/Drivers License Number'
                  variant='outlined'
                  fullWidth
                  disabled={disabled && true}
                  sx={{
                    marginTop: '0px',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box>
                  <Typography variant='caption'>
                    ID must expire more than 7 days from today.
                  </Typography>
                </Box>
                <DatePicker
                  id='id-expiry'
                  label='ID/Drivers License Expiration Date'
                  value={idExpiry}
                  onChange={setIdExpiry}
                  inputVariant='outlined'
                  bgcolor='background.paper'
                  format='MM-dd-yyyy'
                  keyboardIcon={<DateRangeIcon />}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  slotProps={{ textField: { required: true, fullWidth: true } }}
                  disabled={disabled && true}
                  minDate={new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '10px' }}>
                <Box mt={1} width='100%' display='flex' alignItems='center' justifyContent='center'>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    flexDirection='column'
                    width='100%'
                  >
                    <Button
                      component='label'
                      variant='outlined'
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      disabled={disabled && true}
                      size='large'
                      sx={{
                        minWidth: '155px',
                      }}
                    >
                      <Typography variant='body1' whiteSpace='nowrap'>
                        Upload ID
                      </Typography>
                      <VisuallyHiddenInput
                        id='dirx-id-file'
                        type='file'
                        accept='image/*'
                        disabled={disabled && true}
                        onChange={e => {
                          setIdImage(e.target.files[0])
                          const objectUrl = URL.createObjectURL(e.target.files[0])
                          setPreview(objectUrl)
                        }}
                      />
                    </Button>
                    <Button
                      component='label'
                      variant='outlined'
                      tabIndex={-1}
                      startIcon={<DeleteForeverIcon />}
                      disabled={disabled && true}
                      size='large'
                      sx={{
                        marginTop: '10px',
                        minWidth: '155px',
                      }}
                      onClick={() => {
                        setIdImage(null)
                      }}
                    >
                      <Typography variant='body1' whiteSpace='nowrap'>
                        Clear
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    width='100%'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    padding={2}
                  >
                    {preview ? (
                      <img
                        id='id_preview'
                        src={preview}
                        alt='ID Preview'
                        style={{
                          width: '100%',
                          maxWidth: '400px',
                          height: 'auto',
                        }}
                      />
                    ) : (
                      <Typography variant='body1'>
                        Please upload a clear picture of the front of your ID.
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  onClick={async () => {
                    setDisabled(true)
                    const status = await handleIdInfo()

                    if (!status) {
                      setDisabled(false)
                      console.warn('Error handling ID info')
                      return
                    }

                    setDisabled(false)
                    setCheckoutStep(2)
                  }}
                >
                  Next
                </Button>
              </Grid>
            </>
          )}
          {/* conditionally rendered commented out but its there */}
          {/* {deliveryMethod === 'mail' && (
            <>
              <Grid item mt={2} xs={12}>
                <Typography variant='h4' align='center'>
                  Shipping Options
                </Typography>
              </Grid>
              <Grid item mb={2} xs={12}>
                <SelectField
                  id='dirx-delivery-speed'
                  label='Delivery Speed'
                  variant='outlined'
                  fullWidth
                  value={selectedDeliveryOption}
                  onChange={e => {
                    setSelectedDeliveryOption(e.target.value)
                  }}
                  options={DELIVERY_OPTIONS}
                  disabled={disabled && true}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ margin: '20px 0' }} />
              </Grid>
            </>
          )} */}
          {checkoutStep === 2 && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant='h4' align='center'>
                  Payment
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <PaymentElement id='payment-element' />
                <Grid
                  container
                  style={{ marginTop: '30px' }}
                  spacing={1}
                  justifyContent={
                    !profile?.terms || !profile?.hipaa || !profile?.smsOptIn
                      ? 'space-between'
                      : 'flex-end'
                  }
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <BookingPolicy />
                    <Box display='flex' alignItems='center'>
                      <Checkbox
                        disabled={disabled}
                        checked={bookingPolicy}
                        onChange={e => setBookingPolicy(e.target.checked)}
                        name='bookingPolicy'
                        color='primary'
                        inputProps={{ 'aria-label': 'terms' }}
                      />
                      <Typography
                        variant='body2'
                        color={errors.bookingPolicy ? 'error' : 'initial'}
                      >
                        I have read and agree to the booking policy.
                      </Typography>
                    </Box>
                    <Box width='100%' mt={3} mb={3}>
                      <Divider />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box display='flex' alignItems='center'>
                      <Button
                        onClick={handlePayment}
                        disabled={disabled}
                        fullWidth
                        className='book-network-service-btn'
                      >
                        Confirm Payment
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container>
              {paymentProcessing && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box display='flex' alignItems='center'>
                    <Typography variant='body2' color='primary'>
                      Your payment is processing. Please do not leave this page or refresh. You will
                      be shown a confirmation once payment is complete. This may take a few moments.
                    </Typography>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {error && (
                  <Notification
                    message={error}
                    style={{
                      backgroundColor: 'rgba(254, 35, 32, 0.19)',
                      padding: '10px',
                      borderRadius: '5px',
                      color: '#000',
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {paymentStatus === 'requires_payment_method' ||
                  (paymentStatus === 'requires_action' && (
                    <Notification
                      message='Payment Failed. Please try again.'
                      style={{
                        backgroundColor: 'rgba(254, 35, 32, 0.19)',
                        padding: '10px',
                        borderRadius: '5px',
                        color: '#000',
                      }}
                    />
                  ))}
                {paymentStatus === 'error' && (
                  <Notification
                    message='There was an error with your payment. Please try again.'
                    style={{
                      backgroundColor: 'rgba(254, 35, 32, 0.19)',
                      padding: '10px',
                      borderRadius: '5px',
                      color: '#000',
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <CheckoutStatus status={paymentStatus} orderId={successfulPaymentIntent} />
      )}
    </Box>
  )
}

export default CheckoutForm

CheckoutForm.propTypes = {
  orderCompletionCallback: PropTypes.func.isRequired,
  paymentIntentID: PropTypes.string.isRequired,
  medicationRequested: PropTypes.object.isRequired,
  otherMedicationInfo: PropTypes.shape({
    brandName: PropTypes.string,
    genericName: PropTypes.string,
  }).isRequired,
}
