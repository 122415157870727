import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { collection } from '../firebase/firestore'
import { startsWithString } from '../firebase/query'
import { useVouchers } from '../context/Vouchers'
import Modal from '@app/components/ClaimVoucherModal'

const queryVouchers = async (orgId, query) => {
  let ref = collection('vouchers').where('organization.id', '==', orgId)
  if (query) {
    if (typeof parseInt(query, 10) === 'number') {
      ref = startsWithString(ref, 'code', query)
    } else if (query.startsWith('pi_')) {
      ref = startsWithString(ref, 'orderId', query)
    }
  }
  return ref.limit(25).get()
}

const ClaimVoucherModal = ({ uid }) => {
  const { search } = useLocation()
  const { changeVouchers, vouchers } = useVouchers()
  const [flag, setFlag] = useState(false)
  const slug = new URLSearchParams(search).get('claim')

  useEffect(() => {
    async function updateVoucher() {
      if (uid && slug) {
        setFlag(false)
        await queryVouchers(uid, slug)
          .then(data => {
            changeVouchers(data)
            setFlag(true)
          })
          .catch(() => {
            // TODO handle error
          })
      } else {
        changeVouchers(null)
      }
    }
    updateVoucher()
  }, [uid, changeVouchers, slug])

  return (
    <>
      <Modal voucherId={slug} vouchers={vouchers || null} flag={flag || false} />
    </>
  )
}

ClaimVoucherModal.propTypes = {
  uid: PropTypes.string.isRequired,
}

export default ClaimVoucherModal
