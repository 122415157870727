import firebase from './app.js'
import 'firebase/compat/auth'
import { createDocument } from './firestore.js'
import { sendVerificationEmail } from './functions.js'
import { checkFields } from './utils.js'

// Connect Auth emulator if enabled
const authEmulator = import.meta.env.VITE_FIREBASE_EMULATOR_AUTH
if (authEmulator) {
  firebase.auth().useEmulator('http://'.concat(authEmulator))
}

const auth = () => firebase.auth()

const setPersistence = () => auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

export const setPersistencePhone = () => auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

export const currentUser = () => auth().currentUser

export const call = (name, options = {}) => firebase.functions().httpsCallable(name)(options)

export const sendCurrentUserVerification = () => {
  const user = currentUser()
  return sendVerificationEmail(user.email)
}

export const signUp = (email, password, data = {}, callback = data => data) =>
  auth()
    .createUserWithEmailAndPassword(email, password)
    .then(async ({ user }) => {
      console.log('  ** User created', user)

      const profileData = {
        cart: [],
        ...data,
      }

      await createDocument('profiles', user.uid, profileData)
      console.log('  ** Profile created', profileData)

      localStorage.removeItem('refvalue')
      try {
        call('intercom-getIntercomHash')
          .then(intercomHash => {
            if (intercomHash?.data) {
              const hash = intercomHash.data
              window.Intercom('update', checkFields(user.uid, email, hash, profileData))
              window.Intercom('trackEvent', 'Signed up', {
                email,
                userID: user.uid,
              })
            } else {
              console.log(' ** There was no intercom hash data')
            }
          })
          .catch(e => {
            console.log('  ** Error - getting intercom hash')
            console.log(e)
          })
      } catch (error) {
        console.log('  ** intercom had an error', error)
      }
      return user
    })
    .then(callback)

export const signIn = (email, password) =>
  setPersistence().then(() => auth().signInWithEmailAndPassword(email, password))

export const subscribe = callback => auth().onAuthStateChanged(callback)

export const signOut = () => auth().signOut()

export const changePassword = (currentPassword, newPassword) => {
  const user = currentUser()
  const { credential } = firebase.auth.EmailAuthProvider
  const cred = credential(user.email, currentPassword)
  return user.reauthenticateWithCredential(cred).then(() => user.updatePassword(newPassword))
}

export const confirmPassword = (code, email) => auth().confirmPasswordReset(code, email)

export const resetPassword = email => auth().sendPasswordResetEmail(email)

export const sendPasswordResetEmailWithOrg = async email => {
  const response = await call('members-sendPasswordResetEmailWithOrg', email)
  return response
}

export default auth
