import { useState, useEffect, useCallback, useRef } from 'react'
import { Box, Divider, Collapse } from '@mui/material'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Slide from '@mui/material/Slide'
import Tooltip from '@mui/material/Tooltip'

import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import SimpleMap from './GoogleMap'

import NetworkServiceLoader from '../stories/NetworkServiceLoader'
import Capsules from '../assets/images/Capsules.png'
import MedicationPriceCalculator from './MedicationPriceCalculator'
import InstantSearchContainerCustom from '@app/containers/InstantSearchContainerCustom'
import PharmacySearchCard from './PharmacySearchCard'

import StepperBubbleComponent from './StepperBubbleComponent'

import { createPaymentIntentMedication } from '../firebase/functions'

import QuickCheckoutContainer from '@app/containers/QuickCheckoutContainerMedication'
import QuickCheckoutMedication from './QuickCheckoutMedication'
import LoadingBar from '../stories/RandomLoadingBar'
import Icon from '../stories/Icons'

import { useCurrentUser } from '../context/CurrentUser'

const PlanMedicationLander = ({ medicationData = [] }) => {
  const { uid } = useCurrentUser()

  const [startQuestion, setStartQuestion] = useState(true)
  const [openDeliveryQuestion, setOpenDeliveryQuestion] = useState(false)
  const [openPriceCalculator, setOpenPriceCalculator] = useState(false)

  const [openPharmacySearch, setOpenPharmacySearch] = useState(false)
  const [openPharmacyMap, setOpenPharmacyMap] = useState(false)
  const [openConsultSearch, setOpenConsultSearch] = useState(false)
  const [openCheckout, setOpenCheckout] = useState(false)
  const [slideDirection, setSlideDirection] = useState('right')

  const [selectedMedication, setSelectedMedication] = useState(null)
  const [totalPrice, setTotalPrice] = useState('')
  const [form, setForm] = useState('')
  const [dosage, setDosage] = useState('')
  const [quantity, setQuantity] = useState('')

  const [stepperLoad, setStepperLoad] = useState(false)
  const [pharmacySelected, setPharmacySelected] = useState(null) // eslint-disable-line no-unused-vars

  const [locationsFromSearch, setLocationsFromSearch] = useState([])

  const [alreadyHavePrescription, setAlreadyHavePrescription] = useState(false)
  const [useDelivery, setUseDelivery] = useState(false)
  const [noDelivery, setNoDelivery] = useState(false)
  const [noPickup, setNoPickup] = useState(false)

  const [currentFlow, setCurrentFlow] = useState('pickupWithoutPrescription')
  const [activeStep, setActiveStep] = useState(-2)

  const [medicationInfo, setMedicationInfo] = useState(null)

  const [paymentIntent, setPaymentIntent] = useState(null)

  const [medicationRequested, setMedicationRequested] = useState(false)
  const [orderComplete, setOrderComplete] = useState(false)

  const [deliveryMethod, setDeliveryMethod] = useState('')

  const [toolTipOpen, setToolTipOpen] = useState(false)

  const handleToolTipOpen = () => {
    setToolTipOpen(true)
  }

  const handleToolTipClose = () => {
    setToolTipOpen(false)
  }

  const boxRef = useRef(null)

  const handleSelectPharmacy = pharmacy => {
    setPharmacySelected(pharmacy)
    if (boxRef.current) {
      boxRef.current.scrollTop = 0
    }
  }

  const fetchPaymentIntent = useCallback(async () => {
    console.log('fetchPaymentIntent medication requested:', medicationRequested)
    const payload = {
      hasPerscription: false,
      medicationInfo: {
        medicationID: medicationRequested.id,
        medicationName: medicationInfo.brandName || medicationInfo.genericName || '',
        medicationForm: medicationRequested.form,
        medicationStrength: medicationRequested.dosage,
        medicationQuantity: medicationRequested.quantity,
      },
      deliveryMethod: deliveryMethod,
      patientID: uid,
    }

    console.log('fetchPaymentIntent payload:', payload)

    if (alreadyHavePrescription) {
      console.log('fetchPaymentIntent hasPerscription:', alreadyHavePrescription)
      payload.hasPerscription = true
    }

    if (deliveryMethod === 'pickup') {
      console.log('fetchPaymentIntent pharmacySelected:', pharmacySelected)
      payload.pharmacyID = pharmacySelected.id
    }

    const paymentIntentData = await createPaymentIntentMedication(payload)
    console.log('fetchPaymentIntent paymentIntentData:', paymentIntentData)
    setPaymentIntent(paymentIntentData)
  }, [
    uid,
    medicationRequested,
    medicationInfo,
    alreadyHavePrescription,
    deliveryMethod,
    dosage,
    form,
    pharmacySelected,
    createPaymentIntentMedication,
  ])

  useEffect(() => {
    if (medicationData) {
      // accumulator would work better - james
      // he means a reduce function - james
      const options = []
      let price = 1
      let brandName = ''
      let genericName = ''
      let prescriptionRequired = false

      const dirxData = medicationData.filter(
        medication => medication?.pharmacyName?.toLowerCase() === 'dirx',
      )

      if (dirxData.length === 0) {
        setNoDelivery(true)
      }

      const makorxData = medicationData.filter(
        medication => medication?.pharmacyName?.toLowerCase() === 'makorx',
      )

      if (makorxData.length === 0) {
        setNoPickup(true)
      }

      const medicationFormatter = medication => {
        price = medication.price || price
        brandName = medication.brandName || brandName
        genericName = medication.genericName || genericName
        prescriptionRequired = medication.prescriptionRequired || prescriptionRequired

        const dosageForm = medication?.dosageForm?.toLowerCase()?.trim() || 'Unknown'

        const formIndex = options.findIndex(medForm => medForm.label === dosageForm)
        if (formIndex === -1) {
          options.push({
            label: dosageForm,
            dosages: [
              {
                text: medication.strength,
                id: medication.id,
                price: medication.price,
                requiredQuantity: medication?.requiredQuantity,
                suggestedQuantities: medication?.suggestedQuantities,
                specialPriceRules: medication?.specialPriceRules,
              },
            ],
          })
        } else {
          options[formIndex].dosages.push({
            text: medication.strength,
            id: medication.id,
            price: medication.price,
            requiredQuantity: medication?.requiredQuantity,
            suggestedQuantities: medication?.suggestedQuantities,
            specialPriceRules: medication?.specialPriceRules,
          })
        }
      }

      if (useDelivery) {
        setMedicationRequested({
          form: dirxData[0]?.dosageForm?.toLowerCase()?.trim() || 'Unknown',
          dosage: dirxData[0]?.strength,
          quantity: '',
        })
        dirxData.forEach(medicationFormatter)
      } else {
        setMedicationRequested({
          form: makorxData[0]?.dosageForm?.toLowerCase()?.trim(),
          dosage: makorxData[0]?.strength,
          quantity: '',
        })
        makorxData.forEach(medicationFormatter)
      }

      setMedicationInfo({
        price,
        brandName,
        genericName,
        prescriptionRequired,
        options,
      })
    }
  }, [medicationData, useDelivery, form])

  const medicationInput = {
    useDelivery,
    alreadyHavePrescription,
    selectedMedication,
    totalPrice,
    form,
    dosage,
    quantity,
    activeStep,
  }

  const medicationInputHandler = {
    setAlreadyHavePrescription,
    setSelectedMedication,
    setTotalPrice,
    setForm,
    setDosage,
    setQuantity,
    setCurrentFlow,
  }

  useEffect(() => {
    const handleSlideSwap = () => {
      setSlideDirection(prev => (prev === 'left' ? 'right' : 'left'))
    }

    const handleSlideClose = () => {
      setStartQuestion(false)
      setOpenDeliveryQuestion(false)
      setOpenPriceCalculator(false)
      setOpenPharmacySearch(false)
      setOpenPharmacyMap(false)
      setOpenConsultSearch(false)
      setOpenCheckout(false)
    }

    const handleOpen = func => {
      handleSlideClose()
      setTimeout(() => {
        handleSlideSwap()
        setTimeout(() => {
          func(true)
        }, 100)
      }, 500)
    }

    if (activeStep === -2) {
      handleOpen(setStartQuestion)
    }

    if (activeStep === -1) {
      handleOpen(setOpenDeliveryQuestion)
    }

    if (activeStep === 0) {
      handleOpen(setOpenPriceCalculator)
      setTimeout(() => {
        setStepperLoad(true)
      }, 600)
    }

    if (currentFlow === 'pickupWithoutPrescription') {
      if (activeStep === 1) {
        handleOpen(setOpenPharmacySearch)
        setOpenPharmacyMap(true)
      }

      if (activeStep === 2) {
        handleOpen(setOpenConsultSearch)
      }

      if (activeStep === 3) {
        handleOpen(setOpenCheckout)
        fetchPaymentIntent()
      }
      return
    }

    if (currentFlow === 'deliveryWithPrescription') {
      if (activeStep === 1) {
        handleOpen(setOpenCheckout)
        fetchPaymentIntent()
      }
      return
    }

    if (currentFlow === 'deliveryWithoutPrescription') {
      if (activeStep === 1) {
        handleOpen(setOpenConsultSearch)
      }

      if (activeStep === 2) {
        handleOpen(setOpenCheckout)
        fetchPaymentIntent()
      }
      return
    }

    if (currentFlow === 'pickupWithPrescription') {
      if (activeStep === 1) {
        handleOpen(setOpenPharmacySearch)
        setOpenPharmacyMap(true)
      }

      if (activeStep === 2) {
        handleOpen(setOpenCheckout)
        fetchPaymentIntent()
      }
    }
  }, [activeStep, currentFlow])

  return (
    <>
      <Section id='medications' variant='noPadding' mt={1}>
        {!medicationInfo && <NetworkServiceLoader />}
        {medicationInfo && (
          <Container>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                marginTop: '-50px',
              }}
            >
              <Box
                sx={{
                  marginTop: {
                    xs: '60px',
                    sm: '0px',
                  },
                }}
              >
                <Box width='100%' display='flex' justifyContent='flex-start' alignItems='center'>
                  <Box
                    marginRight='16px'
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'block',
                      },
                    }}
                  >
                    <img
                      src={Capsules}
                      alt='Medication'
                      style={{ objectFit: 'contain', maxWidth: '80px' }}
                    />
                  </Box>
                  <Box display='flex' flexDirection='column' justifyContent='center' height='100%'>
                    {medicationInfo?.prescriptionRequired && (
                      <Box color='#7004c6'>
                        <Typography
                          variant='body1'
                          color='inherit'
                          family='poppins'
                          style={{ margin: '0' }}
                        >
                          Prescription Required
                        </Typography>
                      </Box>
                    )}
                    <Box
                      display='flex'
                      sx={{
                        flexDirection: {
                          xs: 'column',
                          sm: 'row',
                        },
                        alignItems: {
                          xs: 'flex-start',
                          sm: 'flex-end',
                        },
                      }}
                    >
                      {medicationInfo?.brandName ? (
                        <>
                          <Typography
                            variant='h2'
                            family='poppins'
                            style={{
                              margin: '0',
                              textTransform: 'capitalize',
                            }}
                          >
                            {medicationInfo?.brandName}
                          </Typography>
                          {medicationInfo?.genericName && (
                            <Box
                              sx={{
                                marginLeft: {
                                  xs: '0px',
                                  sm: '16px',
                                },
                              }}
                            >
                              <Typography
                                variant='h4'
                                family='poppins'
                                weight='light'
                                style={{
                                  marginBottom: '3px',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {medicationInfo?.genericName}
                              </Typography>
                            </Box>
                          )}
                        </>
                      ) : (
                        medicationInfo?.genericName && (
                          <Typography
                            variant='h4'
                            family='poppins'
                            weight='light'
                            style={{
                              marginBottom: '3px',
                              textTransform: 'capitalize',
                            }}
                          >
                            {medicationInfo?.genericName}
                          </Typography>
                        )
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                width='100%'
                marginTop='8px'
                bgcolor='#f2ecfa'
                padding='20px'
                borderRadius='12px'
                boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
                overflow='hidden'
                display='flex'
                minHeight='250px'
              >
                <Box
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  width='100%'
                  sx={{
                    flexDirection: {
                      xs: 'column',
                    },
                  }}
                >
                  <Slide
                    in={stepperLoad}
                    direction={slideDirection}
                    timeout={500}
                    unmountOnExit
                    mountOnEnter
                  >
                    <Box width='80%' display='flex' justifyContent='center'>
                      {!useDelivery ? (
                        <>
                          {alreadyHavePrescription ? (
                            <StepperBubbleComponent
                              customBackgroundColor='#f2ecfa'
                              bubbles={[
                                {
                                  key: 'medication',
                                  stepName: 'Choose Medication',
                                },
                                {
                                  key: 'pharmacy',
                                  stepName: 'Choose a Pharmacy',
                                },
                                {
                                  key: 'checkout',
                                  stepName: 'Checkout',
                                },
                              ]}
                              activeStep={activeStep}
                              setActiveStep={setActiveStep}
                              customColor='#7004c6'
                            />
                          ) : (
                            <StepperBubbleComponent
                              customBackgroundColor='#f2ecfa'
                              bubbles={[
                                {
                                  key: 'medication',
                                  stepName: 'Choose Medication',
                                },
                                {
                                  key: 'pharmacy',
                                  stepName: 'Choose a Pharmacy',
                                },
                                {
                                  key: 'consult',
                                  stepName: 'Confirm Consult',
                                },
                                {
                                  key: 'checkout',
                                  stepName: 'Checkout',
                                },
                              ]}
                              activeStep={activeStep}
                              setActiveStep={setActiveStep}
                              customColor='#7004c6'
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {alreadyHavePrescription ? (
                            <StepperBubbleComponent
                              customBackgroundColor='#f2ecfa'
                              bubbles={[
                                {
                                  key: 'medication',
                                  stepName: 'Choose Medication',
                                },
                                {
                                  key: 'checkout',
                                  stepName: 'Checkout',
                                },
                              ]}
                              activeStep={activeStep}
                              setActiveStep={setActiveStep}
                              customColor='#7004c6'
                            />
                          ) : (
                            <StepperBubbleComponent
                              customBackgroundColor='#f2ecfa'
                              bubbles={[
                                {
                                  key: 'medication',
                                  stepName: 'Choose Medication',
                                },
                                {
                                  key: 'consult',
                                  stepName: 'Confirm Consult',
                                },
                                {
                                  key: 'checkout',
                                  stepName: 'Checkout',
                                },
                              ]}
                              activeStep={activeStep}
                              setActiveStep={setActiveStep}
                              customColor='#7004c6'
                            />
                          )}
                        </>
                      )}
                    </Box>
                  </Slide>
                  <Slide
                    in={startQuestion}
                    direction={slideDirection}
                    timeout={500}
                    unmountOnExit
                    mountOnEnter
                    style={{ width: '100%' }}
                  >
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      width='100%'
                      height='100%'
                      flexDirection='column'
                    >
                      <Typography
                        variant='h3'
                        family='poppins'
                        weight='semibold'
                        color='inherit'
                        align='center'
                        style={{
                          margin: '0px',
                          color: '#7004c6',
                        }}
                      >
                        Already have a prescription?
                      </Typography>
                      <Box paddingTop='30px' width='50%'>
                        <Box
                          width='100%'
                          display='flex'
                          justifyContent='space-around'
                          alignItems='center'
                          sx={{
                            flexDirection: {
                              xs: 'column',
                              sm: 'row',
                            },
                          }}
                        >
                          <Button
                            variant='text'
                            size='medium'
                            onClick={() => {
                              setAlreadyHavePrescription(true)
                              setCurrentFlow('pickupWithPrescription')
                              setActiveStep(activeStep + 1)
                            }}
                            style={{
                              color: '#7004c6',
                            }}
                          >
                            <Box display='flex' alignItems='center'>
                              <Box padding='0 8px' fontSize='30px' marginBottom='10px'>
                                <Icon name='check' fontSize='inherit' />
                              </Box>
                              <Typography
                                variant='h4'
                                family='poppins'
                                weight='medium'
                                color='inherit'
                                style={{ margin: '0px', whiteSpace: 'nowrap' }}
                              >
                                Yes I do
                              </Typography>
                            </Box>
                          </Button>
                          <Button
                            variant='text'
                            size='medium'
                            onClick={() => {
                              setAlreadyHavePrescription(false)
                              setActiveStep(activeStep + 1)
                            }}
                            style={{
                              color: '#7004c6',
                            }}
                          >
                            <Box display='flex' alignItems='center'>
                              <Box padding='0 8px' fontSize='30px' marginBottom='7px'>
                                <Icon name='close' fontSize='inherit' />
                              </Box>
                              <Typography
                                variant='h4'
                                family='poppins'
                                weight='medium'
                                color='inherit'
                                style={{ margin: '0px', whiteSpace: 'nowrap' }}
                              >
                                No I don&apos;t
                              </Typography>
                            </Box>
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Slide>
                  <Slide
                    in={openDeliveryQuestion}
                    direction={slideDirection}
                    timeout={500}
                    unmountOnExit
                    mountOnEnter
                    style={{ width: '100%' }}
                  >
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      width='100%'
                      height='100%'
                      flexDirection='column'
                    >
                      <Typography
                        variant='h3'
                        family='poppins'
                        weight='semibold'
                        color='inherit'
                        align='center'
                        style={{
                          margin: '0px',
                          color: '#7004c6',
                        }}
                      >
                        How would you like to receive your medication?
                      </Typography>
                      <Box width='50%' paddingTop='30px'>
                        <Box
                          width='100%'
                          display='flex'
                          justifyContent='space-around'
                          alignItems='center'
                          sx={{
                            flexDirection: {
                              xs: 'column',
                              sm: 'row',
                            },
                          }}
                        >
                          <Button
                            variant='text'
                            size='medium'
                            disabled={noDelivery}
                            onClick={() => {
                              setUseDelivery(true)
                              if (alreadyHavePrescription) {
                                setCurrentFlow('deliveryWithPrescription')
                              } else {
                                setCurrentFlow('deliveryWithoutPrescription')
                              }
                              setActiveStep(activeStep + 1)
                              setDeliveryMethod('mail')
                            }}
                            style={{
                              color: noDelivery ? '#7004c655' : '#7004c6',
                              margin: '0px',
                            }}
                          >
                            <Box display='flex' alignItems='center'>
                              <Box padding='0 8px' fontSize='30px' marginBottom='10px'>
                                <Icon name='delivery' fontSize='inherit' />
                              </Box>
                              <Typography
                                variant='h4'
                                family='poppins'
                                weight='medium'
                                color='inherit'
                                style={{ margin: '0px', whiteSpace: 'nowrap' }}
                              >
                                Delivery
                              </Typography>
                            </Box>
                          </Button>
                          <Button
                            variant='text'
                            size='medium'
                            disabled={noPickup}
                            onClick={() => {
                              setUseDelivery(false)
                              if (alreadyHavePrescription) {
                                setCurrentFlow('pickupWithPrescription')
                              } else {
                                setCurrentFlow('pickupWithoutPrescription')
                              }
                              setActiveStep(activeStep + 1)
                              setDeliveryMethod('pickup')
                            }}
                            style={{
                              color: noPickup ? '#7004c655' : '#7004c6',
                              margin: '0px',
                            }}
                          >
                            <Box display='flex' alignItems='center'>
                              <Box padding='0 8px' fontSize='30px' marginBottom='10px'>
                                <Icon name='pickUp' fontSize='inherit' />
                              </Box>
                              <Typography
                                variant='h4'
                                family='poppins'
                                weight='medium'
                                color='inherit'
                                style={{ margin: '0px', whiteSpace: 'nowrap' }}
                              >
                                Pick-up
                              </Typography>
                            </Box>
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Slide>
                  <Slide
                    in={openPharmacySearch}
                    direction={slideDirection}
                    timeout={500}
                    unmountOnExit
                    mountOnEnter
                    style={{ width: '100%' }}
                  >
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      width='100%'
                      height='100%'
                      sx={{
                        flexDirection: {
                          xs: 'column',
                          sm: 'row',
                        },
                      }}
                    >
                      {selectedMedication ? (
                        <>
                          <Box
                            width='30%'
                            height='100%'
                            color='#7004c6'
                            display='flex'
                            alignItems='flex-start'
                            padding='8px'
                            sx={{
                              flexDirection: {
                                xs: 'row',
                                sm: 'column',
                              },
                              width: {
                                xs: '100%',
                                sm: '30%',
                              },
                              justifyContent: {
                                xs: 'center',
                                sm: 'space-between',
                              },
                              marginBottom: {
                                xs: '8px',
                                sm: '0px',
                              },
                            }}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              sx={{
                                alignItems: {
                                  xs: 'center',
                                  sm: 'flex-start',
                                },
                              }}
                            >
                              <Typography
                                variant='h3'
                                family='poppins'
                                color='inherit'
                                style={{ margin: '0px' }}
                              >
                                ${totalPrice}
                              </Typography>
                              <Box color='#888'>
                                <Typography variant='body1' weight='light' family='poppins'>
                                  {quantity} - {dosage} {form}s
                                </Typography>
                              </Box>
                            </Box>
                            {/* <Box
                              sx={{
                                display: {
                                  xs: 'none',
                                  sm: 'block'
                                }
                              }}
                            >
                              <Box>
                                <Button
                                  variant='text'
                                  size='small'
                                  style={{
                                    color: '#7004c695'
                                  }}
                                  onClick={() => {
                                    setActiveStep(0)
                                    setSelectedMedication(false)
                                  }}
                                >
                                  <Typography
                                    variant='body1'
                                    family='poppins'
                                    weight='light'
                                  >
                                    I need some help
                                  </Typography>
                                </Button>
                              </Box>
                            </Box> */}
                          </Box>
                          <Divider orientation='vertical' flexItem />
                          <Box
                            sx={{
                              fontSize: {
                                xs: '20px',
                                sm: '30px',
                              },
                              padding: {
                                xs: '0px',
                                sm: '0px 16px',
                              },
                            }}
                          >
                            <Typography
                              variant='h3'
                              family='poppins'
                              weight='medium'
                              align='center'
                              style={{
                                fontSize: 'inherit',
                                margin: '0px',
                                color: '#7004c6cc',
                              }}
                            >
                              Look below for pharmacies in your area, or select a different
                              medication to compare prices.
                            </Typography>
                          </Box>
                          <Divider orientation='vertical' flexItem />
                          <Box
                            display='flex'
                            padding='0px 8px'
                            alignItems='center'
                            justifyContent='center'
                            sx={{
                              width: {
                                xs: '100%',
                                sm: '30%',
                              },
                              marginTop: {
                                xs: '16px',
                                sm: '0px',
                              },
                            }}
                          >
                            <Button
                              variant='text'
                              size='small'
                              style={{
                                color: '#7004c695',
                              }}
                              onClick={() => {
                                setActiveStep(0)
                                setTimeout(() => {
                                  setSelectedMedication(false)
                                }, 500)
                              }}
                            >
                              <Typography
                                variant='body1'
                                family='poppins'
                                weight='light'
                                align='center'
                              >
                                Change Medication
                              </Typography>
                            </Button>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box
                            padding='0px 16px'
                            sx={{
                              fontSize: {
                                xs: '20px',
                                sm: '30px',
                              },
                              textAlign: {
                                xs: 'center',
                                sm: 'left',
                              },
                            }}
                          >
                            <Typography
                              variant='h3'
                              family='poppins'
                              weight='medium'
                              align='inherit'
                              style={{
                                fontSize: 'inherit',
                                margin: '0px',
                                color: '#7004c6cc',
                              }}
                            >
                              Look below for pharmacies in your area, or use the price calculator to
                              find the best price.
                            </Typography>
                          </Box>
                          <Divider orientation='vertical' flexItem />
                          <Box
                            display='flex'
                            padding='0px 8px'
                            alignItems='center'
                            justifyContent='center'
                            sx={{
                              width: {
                                xs: '100%',
                                sm: '24%',
                              },
                              marginTop: {
                                xs: '16px',
                                sm: '0px',
                              },
                            }}
                          >
                            <Button
                              variant='text'
                              size='small'
                              style={{
                                color: '#7004c695',
                              }}
                              onClick={() => {
                                setActiveStep(0)
                                setSelectedMedication(false)
                              }}
                            >
                              <Typography
                                variant='body1'
                                family='poppins'
                                weight='light'
                                align='center'
                              >
                                Price Calculator
                              </Typography>
                            </Button>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Slide>
                  <Slide
                    in={openPriceCalculator}
                    direction={slideDirection}
                    timeout={500}
                    unmountOnExit
                    mountOnEnter
                    style={{ width: '100%' }}
                  >
                    <Box>
                      <MedicationPriceCalculator
                        onClick={setActiveStep}
                        medicationOptions={medicationInfo}
                        medicationInput={medicationInput}
                        medicationInputHandler={medicationInputHandler}
                        setMedicationRequested={setMedicationRequested}
                      />
                    </Box>
                  </Slide>
                  <Slide
                    in={openConsultSearch}
                    direction={slideDirection}
                    timeout={500}
                    unmountOnExit
                    mountOnEnter
                    style={{ width: '100%' }}
                  >
                    <Box
                      display='flex'
                      justifyContent='flex-start'
                      sx={{
                        flexDirection: {
                          xs: 'column',
                          sm: 'row',
                        },
                      }}
                    >
                      {selectedMedication && (
                        <>
                          <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='flex-start'
                            justifyContent='center'
                            height='100%'
                          >
                            <Typography
                              variant='h5'
                              family='poppins'
                              weight='medium'
                              style={{ margin: '0px', whiteSpace: 'nowrap' }}
                            >
                              Selected Medication
                            </Typography>
                            <Box
                              height='100%'
                              color='#7004c6'
                              display='flex'
                              alignItems='flex-start'
                              flexDirection='column'
                              sx={{
                                justifyContent: {
                                  xs: 'center',
                                  sm: 'space-between',
                                },
                                marginBottom: {
                                  xs: '8px',
                                  sm: '0px',
                                },
                              }}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                paddingTop='16px'
                                alignItems='flex-start'
                              >
                                <Typography
                                  variant='h3'
                                  family='poppins'
                                  color='inherit'
                                  style={{ margin: '0px' }}
                                >
                                  ${totalPrice}
                                </Typography>
                                <Box color='#888'>
                                  <Typography variant='body1' weight='light' family='poppins'>
                                    {quantity} - {dosage} {form}s
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  marginTop: {
                                    xs: '16px',
                                    sm: '0px',
                                  },
                                }}
                              >
                                <Button
                                  variant='text'
                                  size='small'
                                  style={{
                                    color: '#7004c695',
                                  }}
                                  onClick={() => {
                                    setActiveStep(0)
                                    setSelectedMedication(false)
                                  }}
                                >
                                  <Typography variant='body1' family='poppins' weight='light'>
                                    Change Medication
                                  </Typography>
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                          <Divider orientation='vertical' flexItem style={{ margin: '0px 16px' }} />
                        </>
                      )}
                      {pharmacySelected && (
                        <>
                          <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='flex-start'
                            justifyContent='center'
                          >
                            <Typography
                              variant='h5'
                              family='poppins'
                              weight='medium'
                              style={{
                                margin: '0px',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Selected Pharmacy
                            </Typography>
                            <PharmacySearchCard data={pharmacySelected} variant='selected' />
                            <Box color='#7004c6'>
                              <Button
                                variant='text'
                                size='small'
                                onClick={() => {
                                  setActiveStep(1)
                                }}
                                style={{
                                  color: '#7004c695',
                                }}
                              >
                                <Typography
                                  variant='body1'
                                  family='poppins'
                                  weight='light'
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  Change Pharmacy
                                </Typography>
                              </Button>
                            </Box>
                          </Box>
                          <Divider orientation='vertical' flexItem style={{ margin: '0px 16px' }} />
                        </>
                      )}
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        color='#7004c6cc'
                        height='100%'
                        padding='16px'
                        sx={{
                          display: {
                            xs: 'none',
                            sm: 'flex',
                          },
                        }}
                      >
                        <Typography
                          variant='h3'
                          family='poppins'
                          weight='medium'
                          color='inherit'
                          style={{ margin: '0px' }}
                        >
                          Please confirm your would like a consultation
                        </Typography>
                      </Box>
                      <Divider orientation='vertical' flexItem style={{ margin: '0px 16px' }} />
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        color='#7004c6'
                        height='100%'
                        padding='16px'
                      >
                        <Button
                          variant='contained'
                          size='medium'
                          style={{
                            color: '#fff',
                            backgroundColor: '#7004c6',
                            borderRadius: '34px',
                          }}
                          onClick={() => {
                            setActiveStep(activeStep + 1)
                          }}
                        >
                          <Box display='flex' alignItems='center' padding='4px'>
                            <Box paddingRight='8px'>
                              <Icon name='assignmentOutlined' />
                            </Box>
                            <Box display='flex' flexDirection='column'>
                              <Typography variant='body1' family='poppins' weight='medium'>
                                I need a consultation
                              </Typography>
                            </Box>
                          </Box>
                        </Button>
                      </Box>
                    </Box>
                  </Slide>
                  <Slide
                    in={openCheckout}
                    direction={slideDirection}
                    timeout={500}
                    unmountOnExit
                    mountOnEnter
                    style={{ width: '100%', height: '100%' }}
                  >
                    <Box
                      display='flex'
                      justifyContent='center'
                      width='100%'
                      height='100%'
                      sx={{
                        flexDirection: {
                          xs: 'column',
                          sm: 'row',
                        },
                      }}
                    >
                      {!orderComplete ? (
                        <>
                          {alreadyHavePrescription ? (
                            <Box display='flex' height='100%' alignItems='center'>
                              <Box
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                                color='#7004c6'
                              >
                                <Typography
                                  variant='h3'
                                  family='poppins'
                                  color='inherit'
                                  style={{ margin: '0px' }}
                                >
                                  ${totalPrice}
                                </Typography>
                                <Box color='#888'>
                                  <Typography variant='body1' weight='light' family='poppins'>
                                    {quantity} - {dosage} {form}s
                                  </Typography>
                                </Box>
                              </Box>
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{ margin: '0px 24px' }}
                              />
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                color='#7004c6'
                                height='100%'
                                padding='16px'
                              >
                                <Typography
                                  variant='h3'
                                  family='poppins'
                                  weight='medium'
                                  color='inherit'
                                >
                                  Complete your booking below.
                                </Typography>
                              </Box>
                            </Box>
                          ) : (
                            <Box display='flex' height='100%' alignItems='center' flexWrap='wrap'>
                              <Box
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                                color='#7004c6'
                                sx={{
                                  width: {
                                    xs: '49%',
                                    sm: 'auto',
                                  },
                                }}
                              >
                                <Box color='#888' display='flex'>
                                  <Typography variant='body1' weight='light' family='poppins'>
                                    Pay Now
                                  </Typography>
                                  <Tooltip
                                    title='This is the cost of the consultation with the doctor.'
                                    placement='top'
                                    arrow
                                    enterTouchDelay={0}
                                  >
                                    <Box marginLeft='8px'>
                                      <Icon name='info' />
                                    </Box>
                                  </Tooltip>
                                </Box>
                                <Typography
                                  variant='h3'
                                  family='poppins'
                                  color='inherit'
                                  style={{ margin: '16px 0px' }}
                                >
                                  $50
                                </Typography>
                                <Box color='#888'>
                                  <Typography
                                    variant='body1'
                                    weight='light'
                                    family='poppins'
                                    align='center'
                                  >
                                    Telehealth Consultation
                                  </Typography>
                                </Box>
                              </Box>
                              <Divider
                                orientation='vertical'
                                flexItem
                                sx={{
                                  margin: {
                                    xs: '0px',
                                    sm: '0px 24px',
                                  },
                                }}
                              />
                              <Box
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                                color='#7004c6'
                                sx={{
                                  width: {
                                    xs: '49%',
                                    sm: 'auto',
                                  },
                                }}
                              >
                                <Box color='#888' display='flex'>
                                  <Typography
                                    variant='body1'
                                    weight='light'
                                    family='poppins'
                                    align='center'
                                  >
                                    Estimated Pay Later
                                  </Typography>
                                  <Tooltip
                                    title='You will receive an order through Mishe. Prices may vary slightly based on medication form, dosage, and quantity. You are not guaranteed this medication through this consultation.'
                                    placement='top'
                                    arrow
                                    enterTouchDelay={0}
                                  >
                                    <Box marginLeft='8px'>
                                      <Icon name='info' />
                                    </Box>
                                  </Tooltip>
                                </Box>
                                <Typography
                                  variant='h3'
                                  family='poppins'
                                  color='inherit'
                                  style={{ margin: '16px 0px' }}
                                >
                                  ${totalPrice}
                                </Typography>
                                <Box color='#888'>
                                  <Typography
                                    variant='body1'
                                    weight='light'
                                    family='poppins'
                                    align='center'
                                  >
                                    Requested Medication Cost
                                  </Typography>
                                </Box>
                              </Box>
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{ margin: '0px 24px' }}
                                sx={{
                                  display: {
                                    xs: 'none',
                                    sm: 'block',
                                  },
                                }}
                              />
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                color='#7004c6'
                                padding='16px'
                                sx={{
                                  height: {
                                    xs: 'auto',
                                    sm: '100%',
                                  },
                                }}
                              >
                                <Typography
                                  variant='h3'
                                  family='poppins'
                                  weight='medium'
                                  color='inherit'
                                  style={{
                                    margin: '0px',
                                  }}
                                  sx={{
                                    textAlign: {
                                      xs: 'center',
                                      sm: 'inherit',
                                    },
                                  }}
                                >
                                  Complete your booking below.
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </>
                      ) : (
                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                          color='#7004c6cc'
                          height='100%'
                          padding='16px'
                          sx={{
                            display: {
                              xs: 'none',
                              sm: 'flex',
                            },
                          }}
                        >
                          <Typography variant='h3' family='poppins' weight='medium' color='inherit'>
                            Thank you for your Order!
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Slide>
                </Box>
              </Box>
            </Box>
            {/* Desktop Service Cards Container */}
            <Box width='100%'>
              <Collapse in={openPharmacyMap} timeout={500} unmountOnExit mountOnEnter>
                <Box width='100%'>
                  <Grid container width='100%' id='serviceCards' spacing={2}>
                    <Grid item xs={12}>
                      <Box position='relative'>
                        <InstantSearchContainerCustom
                          HitComponent={PharmacySearchCard}
                          hitClick={handleSelectPharmacy}
                          showInitialHits
                          indexName='pharmacies'
                          showZipcode
                          geoSearch
                          configuration={{ hitsPerPage: 25 }}
                          searchBoxText='Search for a pharmacy'
                          noResultsMessage='No results for'
                          exportFunction={setLocationsFromSearch}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {/* Desktop Service Cards */}
                      <Box
                        ref={boxRef}
                        overflow='scroll'
                        borderRadius='8px'
                        sx={{
                          height: {
                            xs: '40vh',
                            sm: '70vh',
                          },
                        }}
                      >
                        {locationsFromSearch.length > 0 ? (
                          <>
                            {pharmacySelected && (
                              <PharmacySearchCard
                                locationID={location.objectID}
                                key={location.objectID}
                                data={pharmacySelected}
                                selected={pharmacySelected}
                                onClick={handleSelectPharmacy}
                                handleSwap={setActiveStep}
                                alreadyHavePrescription={alreadyHavePrescription}
                                activeStep={activeStep}
                              />
                            )}
                            {locationsFromSearch
                              .filter(location => location !== pharmacySelected)
                              .map(location => (
                                <PharmacySearchCard
                                  locationID={location.objectID}
                                  key={location.objectID}
                                  data={location}
                                  selected={pharmacySelected}
                                  onClick={handleSelectPharmacy}
                                  handleSwap={setActiveStep}
                                  alreadyHavePrescription={alreadyHavePrescription}
                                  activeStep={activeStep}
                                />
                              ))}
                          </>
                        ) : (
                          <Typography variant='h4' align='center' color='textSecondary'>
                            Search for a pharmacy to see results.
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    {/* Desktop Map */}
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      {locationsFromSearch && (
                        <Box width='100%' borderRadius='8px' overflow='hidden'>
                          <SimpleMap
                            clinics={locationsFromSearch}
                            displayHeight='70vh'
                            pharmacyPage
                            locationSelected={pharmacySelected}
                            handleSelectLocation={handleSelectPharmacy}
                            handleSwap={setActiveStep}
                            activeStep={activeStep}
                          />
                        </Box>
                      )}
                    </Grid>
                    {/* END Desktop Map */}
                  </Grid>
                </Box>
              </Collapse>
              {/* payment form */}
              <Box marginTop='16px'>
                <Collapse
                  in={!paymentIntent && openCheckout}
                  timeout={500}
                  mountOnEnter
                  unmountOnExit
                >
                  <Box width='100%'>
                    <Grid container width='100%' id='serviceCards' spacing={2}>
                      <Grid item xs={12}>
                        <Box position='relative'>
                          <LoadingBar
                            speed={1}
                            customWording='Just a moment while we load the checkout...'
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
                <Collapse
                  in={paymentIntent && openCheckout}
                  timeout={500}
                  mountOnEnter
                  unmountOnExit
                >
                  <Box width='100%'>
                    <Grid container width='100%' id='serviceCards'>
                      <Grid item xs={12}>
                        <Box position='relative'>
                          <QuickCheckoutContainer
                            clientSecret={paymentIntent?.secret}
                            paymentIntent={paymentIntent?.paymentIntentId}
                          >
                            <QuickCheckoutMedication
                              orderCompletionCallback={setOrderComplete}
                              deliveryMethod={deliveryMethod}
                              hasScript={alreadyHavePrescription}
                              paymentIntentID={paymentIntent?.paymentIntentId}
                              medicationRequested={medicationRequested}
                              pharmacyRequested={pharmacySelected}
                              otherMedicationInfo={medicationInfo}
                            />
                          </QuickCheckoutContainer>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </Box>
              {/* END payment form */}
            </Box>
            {/* END Desktop Service Cards Container */}
          </Container>
        )}
      </Section>
    </>
  )
}

PlanMedicationLander.propTypes = {
  medicationData: PropTypes.array,
}

export default PlanMedicationLander
