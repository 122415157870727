import { useState, useEffect } from 'react'
import { useCurrentProfile, useCurrentUser } from '../context/CurrentUser'
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge'
import SelectField from '../stories/Select'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Divider,
} from '@mui/material'
import { EmployerPlanUtil } from '../utils/employerPlanUtil'
import MemberPlanCard from './MemberPlanCard'
import PatientPlanCopayInfo from '@app/components/PatientPlanCopayInfo'

const HEADERS = ['Name', 'Type', 'Spend Amount', 'Family Amount Spent', 'Total Services']

const renderGauge = (value, max, label) => (
  <Box textAlign='center'>
    <Typography variant='h6'>{label}</Typography>
    <Gauge
      width={300}
      height={200}
      value={value}
      valueMax={max}
      startAngle={-100}
      endAngle={100}
      sx={theme => ({
        [`& .${gaugeClasses.valueText}`]: {
          fontSize: 24,
          fontFamily: 'Poppins',
          fontWeight: 300,
        },
        [`& .${gaugeClasses.valueArc}`]: { fill: '#652d92' },
        [`& .${gaugeClasses.referenceArc}`]: { fill: theme.palette.text.disabled },
      })}
      text={`$${value} / $${max}`}
    />
  </Box>
)

const PlansPatientDashboardContent = () => {
  const { plansAssociated = {}, ...profile } = useCurrentProfile()
  const { uid } = useCurrentUser()

  const [plans, setPlans] = useState()
  const [plan, setPlan] = useState()
  const [selectedPlanID, setSelectedPlanID] = useState()

  useEffect(() => {
    const formattedPlans = Object.entries(plansAssociated).map(([id, data]) => ({
      value: id,
      label: data.name,
      ...data,
    }))

    setPlans(formattedPlans)
  }, [plansAssociated])

  useEffect(() => {
    if (selectedPlanID) {
      const fetchPlan = async () => {
        const plan = await EmployerPlanUtil.create(uid, selectedPlanID)
        setPlan(plan)
      }

      fetchPlan()
    }
  }, [selectedPlanID, profile.uid])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'scroll',
      }}
    >
      {plans && (
        <SelectField
          variant='standard'
          fullWidth
          size='large'
          label='Select Plan'
          options={plans}
          onChange={e => setSelectedPlanID(e.target.value)}
          titleSelect
        />
      )}
      {plan && (
        <>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              overflow: 'scroll',
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              sx={{
                width: { xs: '100%', md: '33%' },
                padding: '16px',
                marginBottom: { xs: '32px', md: '0' },
              }}
            >
              <Card sx={{ maxWidth: 600, margin: '20px auto', boxShadow: 3 }}>
                <CardContent>
                  <PatientPlanCopayInfo copayInfo={plan.planDesign} />
                </CardContent>
              </Card>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              sx={{
                width: { xs: '100%', md: '33%' },
                padding: '16px',
                marginBottom: { xs: '32px', md: '0' },
              }}
            >
              {renderGauge(plan.deductible.remaining, plan.deductible.total, 'Deductible')}
              {renderGauge(plan.oop.remaining, plan.oop.total, 'Total OOP')}
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              sx={{
                width: { xs: '100%', md: '33%' },
                padding: '16px',
                marginBottom: { xs: '32px', md: '0' },
              }}
            >
              <MemberPlanCard
                firstName={profile.firstname}
                lastName={profile.lastname}
                memberNumber={profile.uid}
                groupNumber={selectedPlanID}
                pcpCopay='$25'
                specialistCopay={plan.planDesign.officeOrSpecialistVisit.copay}
                emergencyRoomCopay={plan.planDesign.emergencyRoom.copay}
                memberServiceNumber='(601)-647-4326'
                planType={plan.planType}
                effectiveDate={plan.effectiveDate}
                prescriptionGroup={plan.prescriptionGroup}
                prescriptionCopay='$15 / $30 / $60'
              />
            </Box>
          </Box>

          <Typography variant='h6' align='center'>
            Family Breakdown
          </Typography>
          <TableContainer component={Paper} sx={{ width: '100%', mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {HEADERS.map(header => (
                    <TableCell key={header}>
                      <Typography variant='h6'>{header}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {plan.familyVouchers.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>
                      {row.vouchers.reduce((total, voucher) => total + voucher.amountPaid, 0)}
                    </TableCell>
                    <TableCell>${plan.totalFamilySpend}</TableCell>
                    <TableCell>{row.vouchers.length}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  )
}

export default PlansPatientDashboardContent
