// src/utils/voucherUtils.js

import { collection } from '../firebase/firestore'

export const getSubVouchers = async (voucher, isProvider, organizationId) => {
  try {
    let ownerId = voucher.user.id
    let key = 'user.id'
    if (isProvider) {
      ownerId = organizationId
      key = 'organization.id'
    }
    const subVouchers = await collection('vouchers')
      .doc(voucher.id)
      .collection('subVouchers')
      .where(key, '==', ownerId)
      .where('status', '==', 'redeemed')
      .get()

    if (!subVouchers.empty) {
      return subVouchers.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,

        parentUid: voucher.id,
      }))
    }

    return [] // Return an empty array if no vouchers found
  } catch (error) {
    console.error('Error getting sub vouchers', error)
    return [] // Return an empty array in case of error
  }
}

export const getVoucher = async (id, userId) => {
  try {
    const voucher = await collection('vouchers').doc(id).get()

    if (!voucher.empty) {
      return voucher
    }

    return [] // Return an empty array if no vouchers found
  } catch (error) {
    console.error('Error getting sub vouchers', error)
    return [] // Return an empty array in case of error
  }
}

export const getSubVoucher = async (id, subId) => {
  try {
    const subVoucher = await collection('vouchers')
      .doc(id)
      .collection('subVouchers')
      .doc(subId)
      .get()

    if (!subVoucher.empty) {
      return subVoucher
    }

    return [] // Return an empty array if no vouchers found
  } catch (error) {
    console.error('Error getting sub vouchers', error)
    return [] // Return an empty array in case of error
  }
}
