import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Link, Button, Divider } from '@mui/material'
import Icon from '../../stories/Icons'
import { Modal } from '@mui/material'
import Fade from '@mui/material/Fade'
import Backdrop from '@mui/material/Backdrop'

import { handleDownload, dateOptions } from './messageUtils'

const MessageOut = ({ author, content, sendTime, date }) => {
  const { fileType, fileName, url, text } = content
  const [expandImage, setExpandImage] = useState(false)
  const [zoomImage, setZoomImage] = useState(false)

  return (
    <Box
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        padding: '0px 4px',
      }}
    >
      <Modal
        open={expandImage}
        onClose={() => setExpandImage(false)}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        disableAutoFocus
      >
        <Fade in={expandImage}>
          <Box
            position='absolute'
            top='50%'
            left='50%'
            bgcolor='#ccc'
            border='2px solid #652d92'
            borderRadius='5px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            style={{
              transform: zoomImage ? 'translate(-50%, -50%) scale(1.5)' : 'translate(-50%, -50%)',
            }}
          >
            <img
              src={url}
              alt={fileName}
              style={{
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
                borderRadius: '5px',
              }}
            />
            <Button
              onClick={() => setExpandImage(false)}
              style={{
                position: 'absolute',
                top: '-2px',
                right: '-2px',
                minWidth: '40px',
                padding: '0px',
                height: '40px',
                border: '2px solid #652d92',
                borderRadius: '0 5px 0 5px',
                backgroundColor: '#dcc4f4',
              }}
            >
              <Icon name='close' color='primary' />
            </Button>
            <Button
              onClick={() => setZoomImage(!zoomImage)}
              style={{
                position: 'absolute',
                bottom: '-2px',
                right: '-2px',
                minWidth: '40px',
                padding: '0px',
                height: '40px',
                border: '2px solid #652d92',
                borderLeft: '2px solid #652d92',
                borderRadius: '5px 0 5px 0',
                backgroundColor: '#dcc4f4',
              }}
            >
              <Icon name={zoomImage ? 'zoomOut' : 'zoomIn'} color='primary' />
            </Button>
          </Box>
        </Fade>
      </Modal>
      <Box
        style={{
          maxWidth: '70%',
          padding: '10px',
          backgroundColor: '#652d92',
          marginTop: '16px',
          marginBottom: '4px',
          color: '#fff',
          borderRadius: '5px',
          // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
        }}
      >
        <Typography
          variant='body2'
          style={{
            position: 'relative',
          }}
        >
          <Typography
            variant='caption'
            style={{
              position: 'absolute',
              top: '-36px',
              right: '-16px',
              color: '#000',
              width: 'max-content',
              whiteSpace: 'nowrap',
              textTransform: 'capitalize',
            }}
          >
            {author} - {date.toLocaleString('en-US', dateOptions)} {sendTime}
          </Typography>
          {fileType && (
            <Box
              style={{
                position: 'absolute',
                left: '-56px',
                top: 'calc(50% - 20px)',
              }}
            >
              <Button
                style={{
                  borderRadius: '50%',
                  backgroundColor: '#fff',
                  height: '40px',
                  width: '40px',
                  minWidth: '40px',
                  padding: '0px',
                  boxShadow: '0px 0px 1px rgba(0,0,0,0.6)',
                }}
                onClick={() => {
                  handleDownload(url, fileName)
                }}
              >
                <Icon name='getApp' color='primary' />
              </Button>
            </Box>
          )}
          {fileType === 'image' && (
            <img
              src={url}
              alt={fileName}
              style={{
                maxWidth: '100%',
              }}
              onClick={() => {
                setExpandImage(true)
              }}
            />
          )}
          {fileType === 'file' && (
            <>
              <Link
                href={url}
                underline='always'
                sx={{
                  color: '#fff',
                  display: 'block',
                  marginBottom: '4px',
                }}
              >
                {fileName || 'Download File'}
              </Link>
              <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} />
            </>
          )}
          {text}
        </Typography>
      </Box>
      <Box
        style={{
          position: 'absolute',
          bottom: '-1px',
          right: '-8px',
        }}
      >
        <svg height='8' width='16'>
          <polygon
            points='0,8 0,0 16,8'
            style={{
              fill: '#652d92',
            }}
          />
        </svg>
      </Box>
    </Box>
  )
}

MessageOut.propTypes = {
  author: PropTypes.string.isRequired,
  content: PropTypes.shape({
    fileType: PropTypes.string,
    fileName: PropTypes.string,
    url: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  sendTime: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
}

export const MessageOutOrders = ({ author, content, sendTime, date }) => {
  const { fileType, fileName, url, text } = content
  const [expandImage, setExpandImage] = useState(false)
  const [zoomImage, setZoomImage] = useState(false)

  return (
    <Box
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        padding: '0px 4px',
      }}
    >
      <Modal
        open={expandImage}
        onClose={() => setExpandImage(false)}
        style={{
          zIndex: 100000,
        }}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        disableAutoFocus
      >
        <Fade in={expandImage}>
          <Box
            position='absolute'
            top='50%'
            left='50%'
            bgcolor='#ccc'
            border='2px solid #652d92'
            borderRadius='5px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            style={{
              transform: zoomImage ? 'translate(-50%, -50%) scale(1.5)' : 'translate(-50%, -50%)',
            }}
          >
            <img
              src={url}
              alt={fileName}
              style={{
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
                borderRadius: '5px',
              }}
            />
            <Button
              onClick={() => setExpandImage(false)}
              style={{
                position: 'absolute',
                top: '-2px',
                right: '-2px',
                minWidth: '40px',
                padding: '0px',
                height: '40px',
                border: '2px solid #652d92',
                borderRadius: '0 5px 0 5px',
                backgroundColor: '#dcc4f4',
              }}
            >
              <Icon name='close' color='primary' />
            </Button>
            <Button
              onClick={() => setZoomImage(!zoomImage)}
              style={{
                position: 'absolute',
                bottom: '-2px',
                right: '-2px',
                minWidth: '40px',
                padding: '0px',
                height: '40px',
                border: '2px solid #652d92',
                borderLeft: '2px solid #652d92',
                borderRadius: '5px 0 5px 0',
                backgroundColor: '#dcc4f4',
              }}
            >
              <Icon name={zoomImage ? 'zoomOut' : 'zoomIn'} color='primary' />
            </Button>
          </Box>
        </Fade>
      </Modal>
      <Box
        style={{
          position: 'relative',
          maxWidth: '70%',
          padding: '10px',
          backgroundColor: '#652d92',
          marginTop: '16px',
          marginBottom: '4px',
          color: '#fff',
          borderRadius: '5px',
        }}
      >
        {fileType && (
          <Box
            style={{
              position: 'absolute',
              left: '-56px',
              top: 'calc(50% - 20px)',
            }}
          >
            <Button
              style={{
                borderRadius: '50%',
                backgroundColor: '#fff',
                height: '40px',
                width: '40px',
                minWidth: '40px',
                padding: '0px',
                boxShadow: '0px 0px 1px rgba(0,0,0,0.6)',
              }}
              onClick={() => {
                handleDownload(url, fileName)
              }}
            >
              <Icon name='getApp' color='primary' />
            </Button>
          </Box>
        )}
        {fileType === 'image' && (
          <img
            src={url}
            alt='preview'
            style={{
              borderRadius: '4px',
              objectFit: 'contain',
              maxWidth: '200px',
              maxHeight: '200px',
            }}
            onClick={() => {
              setExpandImage(true)
            }}
          />
        )}
        {fileType !== 'image' && (
          <Box
            style={{
              whiteSpace: 'nowrap',
              maxWidth: '200px',
            }}
          >
            <Typography
              variant='body1'
              family='poppins'
              style={{
                color: '#fff',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '100%',
                maxHeight: '24px',
              }}
            >
              {fileName}
            </Typography>
          </Box>
        )}
        {text && <Typography variant='body2'>{text}</Typography>}
        <Typography
          variant='caption'
          style={{
            position: 'absolute',
            top: '-24px',
            right: '-4px',
            color: '#000',
            width: 'max-content',
            whiteSpace: 'nowrap',
            textTransform: 'capitalize',
          }}
        >
          {author} - {date.toLocaleDateString('en-US', dateOptions)} - {sendTime}
        </Typography>
      </Box>
      <Box
        style={{
          position: 'absolute',
          bottom: '-1px',
          right: '-8px',
        }}
      >
        <svg height='8' width='16'>
          <polygon
            points='0,8 0,0 16,8'
            style={{
              fill: '#652d92',
            }}
          />
        </svg>
      </Box>
    </Box>
  )
}

MessageOutOrders.propTypes = {
  author: PropTypes.string.isRequired,
  content: PropTypes.shape({
    fileType: PropTypes.string,
    fileName: PropTypes.string,
    url: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  sendTime: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
}

export default MessageOut
