import React, { useEffect, useState } from 'react'
import VoucherDetailsControlBar from '@app/components/VoucherDetails/VoucherDetailsControlBar'
import AddNoteModal from '@app/containers/AddNoteModalContainer'
import AddOrderModal from '@app/containers/AddOrderModalContainer'
import { useVoucherDetails } from '@app/context/VoucherDetails'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Loader from '../stories/Loader'
import { getVoucher, getSubVoucher } from '@app/utils/voucherUtils'

const VoucherDetailsContainer = () => {
  const { voucherId, subvoucherId } = useParams()
  const { voucher, setVoucher } = useVoucherDetails()

  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchVoucher = async () => {
      if (voucher) {
        // avoids refetching if the voucher data is already loaded in context
        if (!subvoucherId || (subvoucherId && voucher.parentUid === voucherId)) {
          return
        }
      }

      setError(null)

      try {
        if (subvoucherId) {
          const subVoucherData = await getSubVoucher(voucherId, subvoucherId)
          if (!subVoucherData.empty)
            setVoucher({ ...subVoucherData.data(), id: subvoucherId, parentUid: voucherId })
          else setVoucher([])
        } else {
          const voucherData = await getVoucher(voucherId)
          if (!voucherData.empty) setVoucher({ ...voucherData.data(), id: voucherId })
          else setVoucher([])
        }
      } catch (err) {
        console.error('Error fetching voucher:', err)
        setError(err.message)
      }
    }

    fetchVoucher()
  }, [voucher, voucherId, subvoucherId, setVoucher])

  if (error) {
    return <Typography>Can't load voucher, contact Support</Typography>
  }

  if (!voucher) {
    return <Loader />
  }

  return (
    <>
      <VoucherDetailsControlBar />
      <AddNoteModal />
      <AddOrderModal />
    </>
  )
}

export default VoucherDetailsContainer
