import Form from '@app/containers/ResetPasswordForm'
import Container from '../stories/Container'
import Typography from '../stories/Typography'
import Section from '../stories/Section'

const ResetPasswordPage = () => (
  <Container maxWidth='xs'>
    <Section>
      <Form />
    </Section>
  </Container>
)

export default ResetPasswordPage
