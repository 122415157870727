import { useCallback, useState } from 'react'
import { getDocument, collection } from '../firebase/firestore'
import { currentUser } from '../firebase/auth'
import { call } from '../firebase/functions'
import { checkFields } from '../firebase/utils.js'
import { usePostHog } from 'posthog-js/react'

const ANONYMOUS_USER = { isAnonymous: true }

const fetchTokenResult = async () => {
  const token = await currentUser().getIdTokenResult(true)
  const { roles, meta, user_id } = token.claims
  console.log(' --user_id', user_id)
  console.log(' --roles', roles)
  console.log(' --meta', meta)
  const { organizationId, organizationName, acceptedTerms } = meta || {}

  let managedPlanData = {}

  if (roles?.includes('PLAN_ADMIN')) {
    console.log('  -- Plan Admin')
    const planAdminData = await collection('planAdmins')
      .where('adminIDs', 'array-contains', user_id)
      .get()

    if (!planAdminData.empty) {
      console.log('  -- Plan Admin Data not empty')
      const planAdmin = planAdminData.docs[0].data()

      console.log('  -- Plan Admin:', planAdmin)

      const managedPlanID = planAdmin?.managedPlanId

      console.log('  -- Managed Plan ID:', managedPlanID)

      if (managedPlanID) {
        console.log('  -- Managed Plan ID found')
        const planData = await getDocument('networks', managedPlanID)

        console.log('  -- Managed Plan Data:', planData)

        managedPlanData = {
          planData,
          managedPlanID,
          planAdminMessage: '',
        }
      } else {
        managedPlanData = {
          planData: {},
          managedPlanID: '',
          planAdminMessage: 'No managed plan found',
        }
      }
    } else {
      console.log('  -- Plan Admin Data empty')
      managedPlanData = {
        planData: {},
        managedPlanID: '',
        planAdminMessage: 'No plan admin found',
      }
    }
  }

  return {
    organizationId,
    organizationName,
    acceptedTerms,
    isProvider: roles?.includes('PROVIDER'),
    isPlanAdmin: roles?.includes('PLAN_ADMIN'),
    managedPlanData,
    isPatient: !roles?.includes('PROVIDER') && !roles?.includes('PLAN_ADMIN'), // patient should have no roles
  }
}

const getOrDummyProfile = async ({ uid, email }) => {
  try {
    return await getDocument('profiles', uid)
  } catch (error) {
    console.log(error) // eslint-disable-line
    return { email, cart: [] }
  }
}

const fetchProfileData = async user => {
  const [token, { cart, ...data }] = await Promise.all([
    fetchTokenResult(),
    getOrDummyProfile(user),
  ])
  console.log('  -- token', token)
  return { cart, ...token, ...data }
}

const useUser = () => {
  const [user, setUser] = useState(null)
  const [profile, setProfile] = useState({})
  const [clinic, setClinic] = useState({})

  const posthog = usePostHog()

  const refresh = useCallback(async () => {
    try {
      const data = await fetchTokenResult()
      setProfile(current => ({ ...current, ...data }))
    } catch (error) {
      // user is not authenticated
      console.log('error refreshing user') // eslint-disable-line
      console.log(error) // eslint-disable-line
    }
  }, [])

  const refreshProfile = useCallback(async () => {
    try {
      const token = await currentUser().getIdTokenResult(true)
      const { user_id: uid, email } = token.claims

      const { cart, ...data } = await getOrDummyProfile({ uid, email })

      setProfile(current => ({ ...current, ...data }))
      return true
    } catch (error) {
      // user is not authenticated
      console.log('error refreshing profile') // eslint-disable-line
      console.log(error) // eslint-disable-line
      return false
    }
  }, [])

  const login = useCallback(async value => {
    const userJSON = value?.toJSON() || {}
    setUser(userJSON)
    const { cart, intercomContactID, ...data } = await fetchProfileData(value)
    console.log('  -- intercomContactID', intercomContactID)
    if (userJSON.email && userJSON.uid) {
      const { email, uid } = userJSON
      // BEGIN - i
      if (window?.Intercom) {
        console.log('  -- Getting intercom hash')
        await call('intercom-getIntercomHash')
          .then(async intercomHash => {
            if (intercomHash.data) {
              const hash = intercomHash.data
              console.log('  -- Updating intercom', uid, email, hash, data)
              window.Intercom('update', checkFields(uid, email, hash, data))
              if (!intercomContactID) {
                console.log('  ** No intercom contact ID')
                const contactResult = await call('intercom-addContactID').catch(e => {
                  console.log('  ** error adding intercom contact')
                  console.log(e)
                })
                console.log('  -- contactResult', contactResult)
              }
            } else {
              console.log('  ** error no res data')
            }
          })
          .catch(e => {
            console.log('error getting intercom hash')
            console.log(e)
          })
      }
      // END - i

      try {
        console.log('  -- Identifying user')
        posthog.identify(uid, { email: email || '' })
        console.log('  -- Identified user')
      } catch (error) {
        console.log('error identifying user')
        console.log(error)
      }
    }
    setProfile({ ...data, ...userJSON })

    if (data?.organizationId) {
      const clinicData = await getDocument('organizations', data.organizationId)
      setClinic(clinicData)
    }
    return cart
  }, [])

  const logout = useCallback(() => {
    if (window.Intercom) {
      window.Intercom('shutdown')
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'kgysbru8',
      })
    }
    try {
      console.log('  -- Resetting posthog')
      posthog.reset()
    } catch (error) {
      console.log('error resetting posthog')
      console.log(error)
    }
    setUser(ANONYMOUS_USER)
    setProfile({})
    setClinic({})
  }, [])

  return [user, profile, login, logout, refresh, refreshProfile, clinic]
}

export default useUser
