import { FC, PropsWithChildren } from 'react'
import { PostHogProvider } from 'posthog-js/react'
import { BrowserRouter } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { CurrentUserProvider } from '@app/context/CurrentUser'
import { OnScreenProvider } from '@app/context/OnScreen'
import WhiteLabelingProvider from '@app/context/WhiteLabelingContext'
import { ThemeProvider } from './ThemeProvider'
import { ErrorBoundaryProvider } from './ErrorBoundaryProvider'

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
}

const Providers: FC<PropsWithChildren> = ({ children }) => (
  <ErrorBoundaryProvider>
    <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={options}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <CurrentUserProvider>
              <OnScreenProvider>
                <WhiteLabelingProvider>{children}</WhiteLabelingProvider>
              </OnScreenProvider>
            </CurrentUserProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </PostHogProvider>
  </ErrorBoundaryProvider>
)

export default Providers
