import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import Collapse from '@mui/material/Collapse'
import TableRow from '@mui/material/TableRow'
import ResponsiveTable from './ResponsiveTable'
import ResponsiveVoucherRow from './ResponsiveVoucherRow'
import OrderChatView from './OrderChatView'
import { getSubVouchers } from '@app/utils/voucherUtils'
import { useCurrentProfile } from '../context/CurrentUser'

const VouchersBundle = ({ headers, cols = 8, open = false, voucher = {} }) => {
  const [subVouchers, setSubVouchers] = useState([])
  const { isProvider, organizationId } = useCurrentProfile()

  useEffect(() => {
    const fetchSubVouchers = async () => {
      if (!open) {
        return
      }
      if (voucher?.bundle && voucher?.subVouchers?.length) {
        setSubVouchers(voucher?.subVouchers)
      } else if (voucher?.bundle) {
        const subVouchersData = await getSubVouchers(voucher, isProvider, organizationId)
        setSubVouchers(subVouchersData)
      }
    }
    fetchSubVouchers()
  }, [voucher, open])

  return (
    <TableRow style={{ border: 0, padding: 0 }}>
      <TableCell colSpan={cols} style={{ padding: 0 }}>
        <Collapse
          style={{ width: '100%' }}
          in={!!(open && subVouchers.length)}
          timeout='auto'
          unmountOnExit
        >
          <Box mb={2}>
            <ResponsiveTable headerColor='#f6eeff' headers={headers}>
              {subVouchers.map(subVoucher => (
                <ResponsiveVoucherRow
                  backgroundColor='#faf5ff'
                  key={subVoucher.id}
                  headers={headers}
                  voucher={{ ...subVoucher, parentUid: voucher.id }}
                />
              ))}
              <TableRow style={{ backgroundColor: '#faf5ff' }}>
                <TableCell style={{ padding: '16px' }} colSpan={headers.length}>
                  <Box display='flex' justifyContent='center' alignItems='center'>
                    <OrderChatView
                      order={voucher}
                      chatID={voucher?.id || voucher?.uid}
                      color='#652d92'
                      variant='bundle'
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </ResponsiveTable>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default VouchersBundle
