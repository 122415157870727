import { useLocation } from 'react-router-dom'
import Form from '@app/components/SignUpForm'
import { signUp } from '../firebase/auth'
import useErrors from '../hooks/useAuthErrors'

const formToProfile = elements => {
  const email = elements.email.value
  const password = elements.password.value
  const profile = {
    email,
    firstname: elements.firstname.value,
    lastname: elements.lastname.value,
    gender: elements.gender.value,
    dob: elements.dob.value,
    phone: elements.phone.value,
    terms: elements.terms.checked,
    hipaa: elements.hipaa.checked,
    smsOptIn: elements.smsOptIn.checked,
    cart: [],
  }
  return [email, password, profile]
}

const SignUpForm = ({ callback }) => {
  const [errors, handleSubmit] = useErrors()
  const { search } = useLocation()

  const ref = new URLSearchParams(search).get('ref')
  if (ref) {
    localStorage.setItem('refvalue', ref)
  }
  const onSubmit = handleSubmit(elements => {
    const [email, password, profile] = formToProfile(elements)
    if (localStorage.getItem('refvalue')) {
      profile.refvalue = localStorage.getItem('refvalue')
    }

    if (callback) {
      console.log(' -- callback in signup form')
      console.log(' -- callback', callback)
      return signUp(email, password, profile, callback)
    }

    return signUp(email, password, profile)
  })

  return <Form onSubmit={onSubmit} errors={errors} />
}

export default SignUpForm
