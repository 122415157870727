import Box from '@mui/material/Box'
import { useState, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentMethod from '../EFTPaymentMethod/PaymentMethod'
import { createCustomerAttachPayment } from '@app/firebase/functions'
import { useCurrentProfile } from '@app/context/CurrentUser'
import { collection } from '@app/firebase/firestore'
import { CircularProgress } from '@mui/material'

const VITE_STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY
const promise = loadStripe(VITE_STRIPE_KEY)

const EFTEnrollment = () => {
  const [clientSecret, setClientSecret] = useState(null)
  const { email, id: planAdminUID } = useCurrentProfile()
  const [pageDataLoading, setPageDataLoading] = useState(false)
  const [existingCustomerId, setExistingCustomerId] = useState(null)
  const [existingSetupIntentInfo, setExistingSetupIntentInfo] = useState(null)

  useEffect(() => {
    setPageDataLoading(true)
    if (planAdminUID) {
      getPlanAdminData()
    }
  }, [planAdminUID, email])

  const getPlanAdminData = async () => {
    collection('planAdmins')
      .where('adminIDs', 'array-contains', planAdminUID)
      .limit(1)
      .onSnapshot(doc => {
        doc.forEach(async doc => {
          if (!doc.exists) {
            setPageDataLoading(false)
            console.error('** No planAdmin document!')
            return null
          }
          const { stripe } = doc.data()
          console.log('stripe', stripe)
          const fetchExistingCustomerId = stripe?.customerId || null
          setExistingCustomerId(fetchExistingCustomerId)
          setExistingSetupIntentInfo(stripe?.setupIntentInfo || null)
          return await createCustomerAttachPayment({
            email,
            customerId: fetchExistingCustomerId,
          })
            .then(async data => {
              setClientSecret(data.clientSecret)
              setPageDataLoading(false)
            })
            .catch(err => {
              setPageDataLoading(false)
              console.log('err', err)
            })
        })
      })
  }

  const appearance = {
    theme: 'stripe',
    // theme: 'flat',
    // variables: { colorPrimaryText: '#652d92', colorSecondaryText: '#652d92' },
  }
  const loader = 'auto'

  console.log('existingSetupIntentInfo', existingSetupIntentInfo)

  return (
    <>
      {clientSecret && (
        <Elements
          options={{
            clientSecret,
            appearance,
            loader,
            paymentMethodOrder: ['us_bank_account', 'card'], // Add ACH if needed
          }}
          stripe={promise}
        >
          <PaymentMethod
            existingSetupIntentInfo={existingSetupIntentInfo}
            existingCustomerId={existingCustomerId}
            setExistingSetupIntentInfo={setExistingSetupIntentInfo}
          />
        </Elements>
      )}
      {pageDataLoading && (
        <Box>
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

export default EFTEnrollment
