import { useEffect } from 'react'
import Notification from '@app/components/AcceptTermsNotification'
import { useCurrentProfile, useCurrentUser } from '../context/CurrentUser'
import useCheck from '../hooks/useCheck'
import { call } from '../firebase/functions'

const AcceptTermsNotification = () => {
  const { refresh } = useCurrentUser()
  const { acceptedTerms } = useCurrentProfile()
  const [terms, setTerms] = useCheck()
  const [baa, setBaa] = useCheck()

  useEffect(() => {
    if (terms && baa) {
      call('members-acceptTermsAndBAA').then(res => {
        if (res.data) refresh()
      })
    }
  }, [terms, baa, refresh])

  if (acceptedTerms) return null
  return <Notification onTermsChange={setTerms} onBaaChange={setBaa} />
}

export default AcceptTermsNotification
