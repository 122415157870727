import { useEffect } from 'react'
import Form from '@app/containers/ConfirmPasswordForm'
import Container from '../stories/Container'
import Typography from '../stories/Typography'
import Section from '../stories/Section'

const ConfirmPasswordPage = () => {
  useEffect(() => {
    // only works if signed in
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Confirm Password Page',
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth='xs'>
      <Section>
        <Typography variant='h2' gutterBottom>
          Change Password
        </Typography>
        <Form />
      </Section>
    </Container>
  )
}

export default ConfirmPasswordPage
