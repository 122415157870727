import { memo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '../../stories/Typography'
import Divider from '../../stories/Divider'
import Button from '../../stories/Button'
import NotesAttributes from '../NotesAttributes'
import { MuiMarkdown } from 'mui-markdown'
import { formatMarkdown } from '@app/libs/formatters'

const VoucherNotes = ({ notes, voucher }) => {
  return (
    <>
      <Box mt={2}>
        <Typography variant='h4' mb={2}>
          Visit Notes
        </Typography>
      </Box>
      <Divider />
      <Box
        mt={2}
        style={{
          maxHeight: '80vh',
          overflowY: 'scroll',
        }}
      >
        {/* Begin Notes Generator */}
        <Grid container>
          {notes.map(note => (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              key={note.uid}
              style={{
                marginBottom: '16px',
              }}
            >
              {note.noteType === 'text' && (
                <Box
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '4px',
                    padding: '20px',
                    height: '100%',
                  }}
                >
                  <Box>
                    <MuiMarkdown>{formatMarkdown(note?.content || '')}</MuiMarkdown>
                  </Box>
                  <Divider {...{ style: { margin: '1rem 0' } }} />
                  <NotesAttributes
                    {...note}
                    originalOrderId={voucher.emrOrderID || voucher.orderId}
                  />
                </Box>
              )}
              {note.noteType === 'image' && (
                <Box
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '4px',
                    padding: '20px',
                    height: '100%',
                  }}
                >
                  <Box key={note.uid} maxWidth='100%' mb={1}>
                    <img
                      src={note?.content || ''}
                      alt='note'
                      width='100%'
                      style={{
                        borderRadius: '5px',
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography variant='body1' mb={2}>
                      {note.fileName || 'Image'}
                      <a
                        href={note?.content || ''}
                        download={note.fileName}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{
                          marginLeft: '10px',
                          textDecoration: 'none',
                        }}
                      >
                        <Button variant='outlined' size='small' color='primary'>
                          Download
                        </Button>
                      </a>
                    </Typography>
                  </Box>
                  <Divider {...{ style: { margin: '1rem 0' } }} />
                  <NotesAttributes
                    {...note}
                    originalOrderId={voucher.emrOrderID || voucher.orderId}
                  />
                </Box>
              )}
              {note.noteType === 'file' && (
                <Box
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '4px',
                    padding: '20px',
                    height: '100%',
                  }}
                >
                  <Box>
                    <Typography variant='body1' mb={2}>
                      {note.fileName || 'File'}
                      <a
                        href={note?.content || ''}
                        download={note.fileName}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{
                          marginLeft: '10px',
                          textDecoration: 'none',
                        }}
                      >
                        <Button variant='outlined' size='small' color='primary'>
                          Download
                        </Button>
                      </a>
                    </Typography>
                  </Box>
                  <Divider {...{ style: { margin: '1rem 0' } }} />
                  <NotesAttributes
                    {...note}
                    originalOrderId={voucher.emrOrderID || voucher.orderId}
                  />
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
        {/* End of notes generator */}
      </Box>
    </>
  )
}

VoucherNotes.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      noteType: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ),
  voucher: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    customer: PropTypes.shape({
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
    }).isRequired,
    service: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    emrOrderID: PropTypes.string,
    orderId: PropTypes.string,
  }).isRequired,
}

export default memo(VoucherNotes)
