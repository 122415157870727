import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { collection } from '../../firebase/firestore'
import { convertFirestoreToDate } from '../../libs/formatters'
import { CircularProgress } from '@mui/material'
import { useCurrentProfile } from '@app/context/CurrentUser'

const removePrefix = (str, prefix) => {
  if (str?.startsWith(prefix)) {
    return str?.slice(prefix.length)
  }
  return str
}

const getClaims = async planID => {
  const claimsRef = await collection('vouchers')
    .where('plan.id', '==', planID)
    .orderBy('createdAt', 'desc')
    .get()

  return claimsRef.docs.map(doc => {
    const { createdAt, service, status = '', paid = '', transferPayment = {} } = doc.data()

    const { price = '' } = service || {}

    const options = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }

    const priceIsNaN = isNaN(parseFloat(price))

    return {
      id: doc.id,
      amountPulled: priceIsNaN
        ? '---'
        : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            parseFloat(price),
          ),
      status: paid ? 'paid' : status || 'pending',
      date: convertFirestoreToDate(createdAt)?.toLocaleString('en-US', options) || '---',
      transferId: removePrefix(transferPayment?.traceId, 'supported - ') || '---',
    }
  })
}

const ClaimsRegister = () => {
  const { managedPlanData } = useCurrentProfile()

  const columns = [
    { field: 'id', headerName: 'ID', width: 250 },
    { field: 'amountPulled', headerName: 'Amount Pulled', width: 150 },
    { field: 'status', headerName: 'Status', width: 100 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'transferId', headerName: 'Transfer ID', width: 150 },
  ]

  const [claims, setClaims] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    setError(false)
    getClaims(managedPlanData?.managedPlanID)
      .then(claims => {
        setClaims(claims)
        setLoading(false)
      })
      .catch(error => {
        console.error('Error getting claims:', error)
        setError('We encountered an error while fetching claims.')
        setLoading(false)
      })
  }, [managedPlanData])

  return (
    <Box>
      {loading && (
        <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
          <CircularProgress load size={100} />
        </Box>
      )}
      {error && (
        <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
          {error}
        </Box>
      )}
      {!loading && !error && (
        <DataGrid
          rows={claims}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            minHeight: '200px',
          }}
        />
      )}
    </Box>
  )
}

export default ClaimsRegister
