import { useEffect, useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import {
  Button,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Box,
  Paper,
  Snackbar,
  Chip,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  createChargeInvoice,
  makeDefaultPaymentMethod,
  retrieveCustomerDetails,
  saveSetupIntentCustomer,
  verifyMicroDeposit,
} from '@app/firebase/functions'
import { SETUP_INTENT_STATUS } from '@app/libs/constant'
import { Stack } from '@mui/system'

const PaymentMethod = ({
  existingCustomerId,
  existingSetupIntentInfo,
  setExistingSetupIntentInfo,
}) => {
  const stripe = useStripe()

  const elements = useElements()
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState({ open: false, message: '' })
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [paymentMethodLoading, setPaymentMethodLoading] = useState(false)
  const [paymentMethodError, setPaymentMethodError] = useState(false)
  const [depositVerificationLoading, setDepositVerificationLoading] = useState(false)
  const [verificationRequired, setVerificationRequired] = useState(null)

  const [radioOptions, setRadioOptions] = useState('verify')

  useEffect(() => {
    if (existingCustomerId) {
      getDefaultPaymentMethod()
    }
  }, [existingCustomerId])

  const getDefaultPaymentMethod = () => {
    setPaymentMethodLoading(true)
    retrieveCustomerDetails({
      customerId: existingCustomerId,
    })
      .then(async data => {
        const { paymentMethod } = data
        if (data.error) {
          setPaymentMethodLoading(false)
          setPaymentMethodError(data.error)
          return
        }
        setPaymentMethod(paymentMethod)
        setPaymentMethodLoading(false)
      })
      .catch(err => {
        console.log('err', err)
        setPaymentMethodLoading(false)
      })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsProcessing(true)
    setError(null)

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.href}`, // Replace with your confirmation URL
      },
      redirect: 'if_required',
    })
    console.log('setupIntent----', setupIntent)

    if (error) {
      setError(`Error: ${error.message}`)
    } else {
      setExistingSetupIntentInfo(setupIntent)

      await saveSetupIntentCustomer({
        setupIntentInfo: setupIntent,
      })
        .then(async data => {
          console.log('data', data)
        })
        .catch(err => {
          console.log('err', err)
          setError(err.message)
        })

      if (setupIntent.status === 'succeeded') {
        await makeDefaultPaymentMethod({
          customerId: existingCustomerId,
          paymentMethodId: setupIntent.payment_method,
        })
          .then(async data => {
            getDefaultPaymentMethod()
            console.log('data', data)
            setIsProcessing(false)
          })
          .catch(err => {
            console.log('err', err)
            setError(err.message)
            setIsProcessing(false)
          })
      } else if (setupIntent.status === 'requires_action') {
        setIsProcessing(false)

        console.log('Payment requires additional action (e.g., authentication).')
        const { next_action } = setupIntent
        console.log('next_action', next_action)
        const { type, use_stripe_sdk } = next_action
        console.log('type', type, 'use_stripe_sdk', use_stripe_sdk)
        if (type === 'verify_with_microdeposits') {
          console.log('verified', next_action[type].hosted_verification_url)
          setVerificationRequired(true)
          // window.location.href = next_action[type].hosted_verification_url
        }
      }
    }
  }

  console.log('existingCustomerId', existingCustomerId)

  const handleCloseSnackbar = () => setSnackbarOpen({ open: false, message: '' })

  const onMircoDespositSubmit = async event => {
    event.preventDefault()
    const { elements } = event.target
    const { microDepositCode } = elements

    setDepositVerificationLoading(true)
    await verifyMicroDeposit({
      setupIntentId: existingSetupIntentInfo.id,
      microDepositCode: microDepositCode.value,
    })
      .then(async data => {
        setDepositVerificationLoading(false)
        getDefaultPaymentMethod()

        if (data.error) {
          console.log('data.error', data.error)
          setSnackbarOpen({ open: true, message: data.error })
          return
        }
        setVerificationRequired(null)
        setSnackbarOpen({ open: true, message: 'Micro Deposit Verified!' })
      })
      .catch(error => {
        setDepositVerificationLoading(false)
        console.log('error', error)
      })
  }
  const handleOptionsChange = event => {
    setRadioOptions(event.target.value)
  }
  const paymentUI = (
    <>
      {error && (
        <Typography color='error' variant='body2' sx={{ marginBottom: 2 }}>
          {error}
        </Typography>
      )}
      {<PaymentElement />}
      <Button
        type='submit'
        variant='contained'
        color='primary'
        fullWidth
        disabled={isProcessing}
        sx={{ marginTop: 2 }}
      >
        {isProcessing
          ? 'Processing...'
          : `${existingCustomerId ? 'Update' : 'Attach'} Payment Method`}
      </Button>
    </>
  )

  console.log('verificationRequired', verificationRequired)
  return (
    <>
      {existingSetupIntentInfo?.status === SETUP_INTENT_STATUS.SUCCEEDED && (
        <Paper sx={{ padding: 3, borderRadius: 1, width: '100%' }}>
          <Typography variant='h4' gutterBottom>
            Default Payment Method
          </Typography>
          {paymentMethod && paymentMethod.card && (
            <Stack spacing={2}>
              <Typography variant='subtitle' gutterBottom>
                Card Brand: {paymentMethod?.card?.brand}
              </Typography>
              <Typography variant='subtitle' gutterBottom>
                Last 4 Digits: {paymentMethod?.card?.last4}
              </Typography>
              <Typography variant='subtitle' gutterBottom>
                Expiry: {paymentMethod?.card?.exp_month}/{paymentMethod.card.exp_year}
              </Typography>
            </Stack>
          )}
          {paymentMethod && paymentMethod.us_bank_account && (
            <Stack spacing={2}>
              <Typography variant='subtitle' gutterBottom>
                Bank name: {paymentMethod?.us_bank_account?.bank_name}
              </Typography>
              <Typography variant='subtitle' gutterBottom>
                Account holder type: {paymentMethod?.us_bank_account?.account_holder_type}
              </Typography>
              <Typography variant='subtitle' gutterBottom>
                Account type: {paymentMethod?.us_bank_account?.account_type}{' '}
              </Typography>
              <Typography variant='subtitle' gutterBottom>
                Last 4 digits: {paymentMethod?.us_bank_account?.last4}
              </Typography>
              <Typography variant='subtitle' gutterBottom>
                Routing number: {paymentMethod?.us_bank_account?.routing_number}
              </Typography>
            </Stack>
          )}
          <br />
          {existingSetupIntentInfo?.status && (
            <Typography variant='h5'>
              Payment Method Status: {<Chip label={existingSetupIntentInfo?.status} />}
            </Typography>
          )}

          {paymentMethodLoading && <CircularProgress />}
        </Paper>
      )}

      {(verificationRequired ||
        existingSetupIntentInfo?.status === SETUP_INTENT_STATUS.REQUIRES_ACTION) && (
        <FormControl>
          <FormLabel id='demo-row-radio-buttons-group-label'>Payment Method</FormLabel>
          <RadioGroup
            value={radioOptions}
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='row-radio-buttons-group'
            onChange={handleOptionsChange}
          >
            <FormControlLabel value='verify' control={<Radio />} label='Verify Micro Deposit' />
            <FormControlLabel
              value='new-payment-method'
              control={<Radio />}
              label='Add New Payment Method'
            />
          </RadioGroup>
        </FormControl>
      )}

      {radioOptions === 'verify' &&
        (verificationRequired ||
          existingSetupIntentInfo?.status === SETUP_INTENT_STATUS.REQUIRES_ACTION) && (
          <Paper sx={{ flexGrow: 1, padding: 3, borderRadius: 1, width: '100%' }}>
            <Typography variant='h4' gutterBottom>
              Verification Required via Micro Deposit
            </Typography>
            <Typography variant='h6' gutterBottom>
              Stripe has sent a micro deposit to your bank account. Please enter the amount
              descriptor. It should be something like this SMXXXX
            </Typography>

            <form title='stripe-connect-form' onSubmit={onMircoDespositSubmit}>
              <TextField
                name='microDepositCode'
                size='small'
                type='text'
                placeholder='SMXXXX'
                label='Micro Deposit Descriptor Code'
                display='standard'
                style={{ width: '300px' }}
                inputProps={{
                  title: 'Descriptor Code',
                }}
              />

              <Box mt={2}>
                <LoadingButton
                  type='submit'
                  loading={depositVerificationLoading}
                  loadingPosition='start'
                  variant='contained'
                >
                  Micro Deposit for the Verification
                </LoadingButton>
              </Box>
            </form>
          </Paper>
        )}
      <br />
      <Box component='form' onSubmit={handleSubmit}>
        {((existingSetupIntentInfo?.status === SETUP_INTENT_STATUS.REQUIRES_ACTION &&
          radioOptions === 'new-payment-method') ||
          existingSetupIntentInfo?.status === SETUP_INTENT_STATUS.SUCCEEDED) && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              Add New Payment Method
            </AccordionSummary>
            <AccordionDetails>{paymentUI}</AccordionDetails>
          </Accordion>
        )}
        {existingSetupIntentInfo === null && (
          <Paper sx={{ padding: 3, borderRadius: 1, width: '100%' }}>
            <Typography variant='h4' gutterBottom>
              Payment Method
            </Typography>
            <Typography variant='h6' gutterBottom>
              Add a payment method to pay your future invoices.
            </Typography>
            {paymentUI}
          </Paper>
        )}
      </Box>

      <Snackbar
        autoHideDuration={1700}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen.open}
        onClose={handleCloseSnackbar}
        message={snackbarOpen.message}
      />
    </>
  )
}

export default PaymentMethod
