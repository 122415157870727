import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Highlight, useInstantSearch } from 'react-instantsearch'
import Box from '@mui/material/Box'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import Collapse from '@mui/material/Collapse'
import Icon from '../stories/Icons'
import Divider from '../stories/Divider'

import { useMapEffects } from '../context/MapEffects'

const ServiceSearchCardNetwork = ({
  data = { referralPrice: null, network: null },
  onClick = null,
}) => {
  const {
    price,
    network,
    clinicName,
    referralPrice,
    bundle,
    payerPrice,
    orderRequired,
    _rankingInfo,
  } = data
  const { indexUiState } = useInstantSearch()
  const exactMatch = data.cptCode && indexUiState.query?.toString() === data.cptCode?.toString()
  const [open, setOpen] = useState(false)
  const [exactMatchScale, setExactMatchScale] = useState(false)
  const timeoutRef = useRef(null)
  const intervalRef = useRef(null)
  const [exactMatchAnimationStep, setExactMatchAnimationStep] = useState(0)
  const exactMatchAnimation = ['scale(1.1) rotate(0.0015turn)', 'scale(1.1) rotate(-0.0015turn)']
  const { handleHover, handleClick } = useMapEffects()

  useEffect(() => {
    if (!exactMatch) return
    setExactMatchScale(!exactMatchScale)
    intervalRef.current = setInterval(() => {
      setExactMatchAnimationStep(prev => (prev === 0 ? 1 : 0))
    }, 300)
    timeoutRef.current = setTimeout(() => {
      setExactMatchScale(false)
      clearInterval(intervalRef.current)
    }, 1200)

    return () => clearTimeout(timeoutRef.current)
  }, [])

  return (
    <Box
      onMouseEnter={() => !data?.bundle && handleHover(true, data.clinicID, data)}
      onMouseLeave={() => handleHover(false)}
      width='100%'
      padding='16px'
      display='flex'
      justifyContent='space-between'
      sx={{
        flexDirection: {
          xs: 'column',
          md: open ? 'column' : 'row',
        },
        cursor: 'pointer',
        '&:hover': {
          bgcolor: !data?.bundle && '#f1e7f8',
        },
      }}
    >
      <Box width='100%'>
        {exactMatch && (
          <Box color='#652d92' width='min-content'>
            <Typography
              variant='body1'
              weight='semibold'
              style={{
                fontSize: '14px',
                marginLeft: '-4px',
                whiteSpace: 'nowrap',
                transition: 'all 0.3s ease-in-out',
                transform: exactMatchScale
                  ? exactMatchAnimation[exactMatchAnimationStep]
                  : 'scale(1)',
              }}
            >
              Procedure Code Match
            </Typography>
          </Box>
        )}
        {bundle && (
          <Box color='#652d92'>
            <Typography
              variant='body1'
              weight='semibold'
              style={{ fontSize: '14px', marginLeft: '-4px' }}
            >
              Bundle
            </Typography>
          </Box>
        )}
        <Typography variant='body1' weight='bold'>
          <Highlight attribute='name' hit={data} />
        </Typography>
        {!bundle && (
          <Box paddingLeft='4px'>
            <Typography variant='body1' style={{ color: '#652d92dd' }}>
              {clinicName || 'No Clinic Listed'} - {network?.name || 'No Network Listed'}
            </Typography>
            {data.rendering && (
              <Typography variant='body1' style={{ color: '#652d92dd' }}>
                {data.rendering}
              </Typography>
            )}
          </Box>
        )}
        {bundle && (
          <>
            <Button variant='text' onClick={() => setOpen(!open)} size='small'>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                color='#652d92dd'
              >
                <Typography variant='body1' style={{ color: '#652d92dd' }}>
                  Bundled Services
                </Typography>
                <Box
                  style={{
                    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s',
                  }}
                >
                  <Icon name='chevronRight' />
                </Box>
              </Box>
            </Button>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box paddingLeft='16px' color='#652d92aa' paddingBottom='8px'>
                {data.bundleComponentsUI
                  .sort((a, b) => b.totalPrice - a.totalPrice)
                  .map((service, index) => (
                    <Box>
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        key={index}
                        onMouseEnter={() => handleHover(true, service.clinicID, service)}
                        onMouseLeave={() => handleHover(false)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            bgcolor: '#f1e7f8',
                          },
                        }}
                      >
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box display='flex' color='#652d92'>
                            <Typography variant='body1' style={{ fontSize: '14px' }}>
                              {service.name && service.name}
                            </Typography>
                            <Typography variant='body1' style={{ fontSize: '14px' }}>
                              &nbsp;
                              {service.count > 1 ? `(x${service.count})` : ''}
                            </Typography>
                          </Box>
                          {service.cptCode && (
                            <Box width='100%' display='flex' justifyContent='space-between'>
                              <Typography
                                variant='body1'
                                weight='light'
                                style={{ fontSize: '14px' }}
                              >
                                Procedure Code:
                              </Typography>
                              <Typography
                                variant='body1'
                                weight='light'
                                style={{ fontSize: '14px' }}
                              >
                                {service.cptCode}
                              </Typography>
                            </Box>
                          )}
                          <Box width='100%' display='flex' justifyContent='space-between'>
                            {service.rendering && (
                              <Typography
                                variant='body1'
                                weight='light'
                                style={{
                                  fontSize: '14px',
                                }}
                              >
                                {service.rendering}:
                              </Typography>
                            )}
                            <Typography
                              variant='body1'
                              weight='light'
                              style={{
                                fontSize: '14px',
                              }}
                            >
                              {service.clinicName}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      {index < data.bundleComponentsUI.length - 1 && (
                        <Divider
                          style={{
                            margin: '2px 0',
                          }}
                        />
                      )}
                    </Box>
                  ))}
              </Box>
            </Collapse>
          </>
        )}
        <Box
          color='#652d92'
          sx={{
            paddingBottom: {
              xs: '8px',
              sm: '8px',
              md: '0',
              lg: '0',
            },
          }}
        >
          <Typography variant='body1' weight='bold' color='inherit'>
            $
            {(referralPrice && referralPrice.toFixed(2)) ||
              (price && price.toFixed(2)) ||
              (payerPrice && payerPrice.toFixed(2)) ||
              '---'}
          </Typography>
        </Box>
        {_rankingInfo?.geoDistance > 0 && (
          // distance converted from meters to miles, add commas for readability
          <Typography variant='body1' weight='light'>
            Distance: {parseInt(_rankingInfo.geoDistance / 1609.344, 10).toLocaleString()} miles
          </Typography>
        )}
      </Box>
      {onClick && (
        <Box
          display='flex'
          height='100%'
          alignItems='center'
          sx={{
            flexDirection: {
              xs: 'row',
              md: open ? 'row' : 'column',
            },
            justifyContent: {
              xs: 'flex-start',
              md: 'center',
            },
          }}
        >
          {data.misheCode && (
            <Button
              variant='outlined'
              style={{
                height: '40px',
                margin: '4px',
                width: '120px',
                border: 'solid 1px #652d92',
              }}
              to={`${onClick}${data.misheCode}`}
            >
              <Typography
                variant='h5'
                weight='light'
                style={{
                  margin: '0px',
                  whiteSpace: 'nowrap',
                  color: '#652d92',
                }}
              >
                Learn More
              </Typography>
            </Button>
          )}
          {!orderRequired ? (
            <Button
              style={{
                height: '40px',
                margin: '4px',
                width: '120px',
                backgroundColor: '#652d92',
              }}
              to={`/plans/${data.network.slug}/service/${data.id}/checkout`}
            >
              <Typography
                variant='h5'
                weight='light'
                style={{ margin: '0px', whiteSpace: 'nowrap' }}
              >
                Book Now
              </Typography>
            </Button>
          ) : (
            <Box>
              <Typography variant='body1'>Order Required</Typography>
              <Button variant='outlined' style={{ height: '40px', margin: '4px', width: '120px' }}>
                <Typography
                  variant='h5'
                  weight='light'
                  style={{ margin: '0px', whiteSpace: 'nowrap' }}
                >
                  Get Started
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      )}
      <Divider
        style={{
          margin: '8px 0',
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      />
    </Box>
  )
}

ServiceSearchCardNetwork.propTypes = {
  data: PropTypes.shape({
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    network: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    clinicName: PropTypes.string,
    referralPrice: PropTypes.number,
    rendering: PropTypes.string,
    cptCode: PropTypes.string,
    misheCode: PropTypes.string,
    bundle: PropTypes.bool,
    bundledServiceNames: PropTypes.array,
  }),
  onClick: PropTypes.string,
}

export default ServiceSearchCardNetwork
