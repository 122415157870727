import { Typography, Divider, List, ListItem, Tooltip, IconButton, Box } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { benefits } from '@app/libs/planConstants'

const SubTooltip = ({ subtext }) => {
  return (
    <Tooltip title={subtext} arrow>
      <IconButton size='small'>
        <InfoOutlinedIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}

const PatientPlanCopayInfo = ({ copayInfo }) => {
  return (
    <>
      <Typography variant='h5' gutterBottom align='center'>
        Health Plan Copay Information
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <List>
        {benefits(copayInfo).map(benefit => (
          <ListItem key={benefit.label} disableGutters>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }}>{benefit.label}</Typography>
                <SubTooltip subtext={benefit.description} />
              </Box>
              <Typography sx={{ textAlign: 'right' }}>{benefit.value || '--'}</Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default PatientPlanCopayInfo
