import { createContext, useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { collection } from '../firebase/firestore'

const Vouchers = createContext()

export const useVouchers = () => useContext(Vouchers)

const docsToList = async (snap, bundles = false) => {
  const ref = await snap
  if (ref?.docs?.length === 0 || !ref?.docs) return []
  return ref.docs
    .map(each => {
      if (!bundles) {
        if (each?.data()?.bundle) return null
      }
      const id = each.id
      const { createdAt, ...data } = each.data()
      const date = createdAt.toDate()
      const redeemedAt = data.redeemedAt?.toDate()
      const cancelledAt = data.cancelledAt?.toDate()
      const timestamp = date.getTime()
      return { ...data, id, date, timestamp, redeemedAt, cancelledAt }
    })
    .filter(Boolean)
    .sort((first, second) => second.date - first.date)
}

const VouchersProvider = ({ children }) => {
  const [vouchers, setVouchers] = useState([])
  const [vouchersLoading, setVouchersLoading] = useState(false)

  const changeVouchers = useCallback(async (snap, isPatient = false) => {
    console.log('isPatient', isPatient)
    console.log('snap', snap) // eslint-disable-line
    if (snap?.v2) {
      const voucherPromises = docsToList(snap.vouchers)
      const bundlePromises = docsToList(snap.bundles, true)

      const [formattedVouchers, formattedBundles] = await Promise.all([
        voucherPromises,
        bundlePromises,
      ])

      const combinedVouchers = [...formattedVouchers, ...formattedBundles].sort(
        (first, second) => second.date - first.date,
      )
      setVouchers(combinedVouchers)
    } else if (snap) {
      setVouchers(await docsToList(snap, isPatient))
    } else {
      setVouchers([])
    }
  }, [])

  const changeSubVoucherStatus = useCallback((id, subID, status) => {
    setVouchers(list =>
      list.map(voucher => {
        if (voucher.id === id) {
          const updatedSubVoucher = voucher.subVouchers.map(subVoucherData =>
            subVoucherData.id === subID ? { ...subVoucherData, status } : subVoucherData,
          )
          return {
            ...voucher,
            subVouchers: updatedSubVoucher,
          }
        } else {
          return voucher
        }
      }),
    )
  }, [])

  const changeStatus = useCallback((id, status) => {
    setVouchers(list => list.map(voucher => (voucher.id === id ? { ...voucher, status } : voucher)))
  }, [])

  const editVoucher = useCallback(
    async (id, status) => {
      try {
        await collection('vouchers').doc(id).update({ status })
        changeStatus(id, status)
        return true
      } catch (error) {
        changeStatus(id, 'error')
        return false
      }
    },
    [changeStatus],
  )

  const editSubVoucher = useCallback(
    async (id, subID, status) => {
      try {
        await collection('vouchers').doc(id).collection('subVouchers').doc(subID).update({ status })
        changeSubVoucherStatus(id, subID, status)
        return true
      } catch (error) {
        changeSubVoucherStatus(id, subID, 'error')
        return false
      }
    },
    [changeStatus],
  )

  const cancelVoucher = useCallback(
    id => {
      editVoucher(id, 'cancelled')
      if (window?.Intercom) {
        window.Intercom('trackEvent', 'Voucher Canceled', {
          voucherID: id,
        })
      }
    },
    [editVoucher],
  )

  const redeemVoucher = useCallback(
    (id, subID) => {
      console.log('redeemVoucher', id, subID) // eslint-disable-line
      let editVoucherResult = null

      if (subID) {
        console.log('Redeeming subVoucher')
        editVoucherResult = editSubVoucher(id, subID, 'redeemed')
      } else {
        editVoucherResult = editVoucher(id, 'redeemed')
      }
      if (editVoucherResult) {
        if (window?.Intercom) {
          window.Intercom('trackEvent', 'Voucher Redeemed', {
            voucherID: id,
          })
        }
      }
      return editVoucherResult
    },
    [editVoucher],
  )

  const unredeemVoucher = useCallback(
    async id => {
      editVoucher(id, 'purchased')
      if (window?.Intercom) {
        window.Intercom('trackEvent', 'Voucher UnRedeemed', {
          voucherID: id,
        })
      }
    },
    [editVoucher],
  )
  const value = {
    vouchers,
    changeVouchers,
    cancelVoucher,
    redeemVoucher,
    unredeemVoucher,
    setVouchers,
    vouchersLoading,
    setVouchersLoading,
  }
  return <Vouchers.Provider value={value}>{children}</Vouchers.Provider>
}

VouchersProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default VouchersProvider
