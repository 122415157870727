import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useCurrentProfile } from '../context/CurrentUser'
import { useVoucherDetails } from '../context/VoucherDetails'
import { useNavigate } from 'react-router-dom'

const VoucherDetailsEntry = ({ voucher, children }) => {
  const navigate = useNavigate()

  const { acceptedTerms, isProvider } = useCurrentProfile()
  const { setVoucher } = useVoucherDetails()

  const onClick = useCallback(() => {
    if (acceptedTerms || !isProvider) {
      if (voucher.parentUid) {
        setVoucher(voucher)

        navigate(`/dashboard/vouchers/${voucher.parentUid}/${voucher.id}`)
      } else if (voucher) {
        setVoucher(voucher)

        navigate(`/dashboard/vouchers/${voucher.id}`)
      }
    }
  }, [acceptedTerms, isProvider, voucher]) // eslint-disable-line react-hooks/exhaustive-deps

  const isDisabled = !acceptedTerms && isProvider
  return (
    <div
      onClick={!isDisabled ? onClick : undefined}
      style={{
        cursor: !isDisabled ? 'pointer' : 'not-allowed',
      }}
    >
      {children}
    </div>
  )
}

VoucherDetailsEntry.propTypes = {
  voucher: PropTypes.shape({
    uid: PropTypes.string,
    code: PropTypes.string,
    service: PropTypes.shape({
      name: PropTypes.string,
    }),
    customer: PropTypes.shape({
      lastname: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired, // Allows any valid React child
}

export default VoucherDetailsEntry
