import { useState } from 'react'
import PropTypes from 'prop-types'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'

import Button from '../../stories/Button'
import Link from '../../stories/Link'
import AppBar from '../../stories/AppBar'

import AutoBreakpoint from '../Breakpoints/StandardBreakpoints'
import Divider from '../../stories/Divider'
import Typography from '../../stories/Typography'
import { useWhiteLabelingContext } from '../../context/WhiteLabelingContext'

const PlanQuickLinks = ({ links = {} }) => {
  const [open, setOpen] = useState(false)
  const { planColorSecondary, planColorPrimary } = useWhiteLabelingContext()

  if (
    !links ||
    typeof links !== 'object' ||
    Array.isArray(links) ||
    Object.keys(links).length === 0
  ) {
    return <></>
  }

  // links is an object with keys as numbers and values as objects with text and link
  const sortedLinksArray = Object.keys(links).map(key => {
    return {
      id: key,
      ...links[key],
    }
  })

  const DesktopView = (
    <AppBar
      style={{
        height: '56px',
        marginTop: '64px',
        zIndex: 10,
        backgroundColor: planColorPrimary || '#fff',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography
        variant='body1'
        style={{ color: planColorSecondary || '#652d92', fontSize: '16px', marginRight: '10px' }}
      >
        Plan Links:
      </Typography>
      {sortedLinksArray.map((link, index) => (
        <Button
          key={link.id}
          variant='outlined'
          size='small'
          style={{
            borderRadius: '21px',
            backgroundColor: planColorSecondary || '#f1e7fb',
          }}
        >
          <Link
            type='external'
            variant='unstyled'
            to={link.url}
            target='_blank'
            rel='noreferrer'
            style={{
              color: planColorPrimary || '#652d92',
            }}
          >
            {link.text}
          </Link>
        </Button>
      ))}
    </AppBar>
  )

  const MobileView = (
    <>
      <AppBar
        style={{
          height: '45px',
          marginTop: '64px',
          backgroundColor: planColorPrimary || '#f1e7fb',
          zIndex: 10,
        }}
      >
        <Box width='100%'>
          <Button
            onClick={() => setOpen(!open)}
            variant='text'
            size='large'
            style={{ width: '100%' }}
          >
            <Typography
              variant='body1'
              style={{ color: planColorSecondary || '#652d92', fontSize: '18px' }}
            >
              Plan Links
            </Typography>
          </Button>
        </Box>
      </AppBar>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <AppBar
          style={{
            height: 'auto',
            borderTop: `2px solid ${planColorPrimary}` || '2px solid #652d92',
            borderBottom: `2px solid ${planColorPrimary}` || '2px solid #652d92',
            backgroundColor: 'white',
            marginTop: '109px',
          }}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {sortedLinksArray.map((link, index) => (
              <Box key={link.id} width='100%' display='flex' flexDirection='column'>
                <Button
                  variant='text'
                  size='large'
                  style={{
                    width: '100%',
                    padding: '4px, 0',
                  }}
                >
                  <Link
                    type='external'
                    variant='unstyled'
                    key={index}
                    to={link.url}
                    target='_blank'
                    rel='noreferrer'
                    style={{
                      color: planColorPrimary || '#652d92',
                    }}
                  >
                    {link.text}
                  </Link>
                </Button>
                {index < sortedLinksArray.length - 1 && <Divider />}
              </Box>
            ))}
          </Box>
        </AppBar>
      </Collapse>
    </>
  )

  return <AutoBreakpoint DesktopChildren={DesktopView} MobileChildren={MobileView} />
}

PlanQuickLinks.propTypes = {
  links: PropTypes.shape({}),
}

export default PlanQuickLinks
