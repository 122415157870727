import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import TextField from '../stories/TextField'
import DateField from '../stories/DateField'
import SelectField from '../stories/Select'

const ProviderVouchersForm = ({ onQueryChange, onDateChange, onStatusChange, currentStatus }) => (
  <>
    <Grid item xs={12} sm={8} md={4} lg={4}>
      <TextField
        size='small'
        label='search claim'
        placeholder='Insert order or claim ID or consumer last name'
        onChange={onQueryChange}
        autoFocus
      />
    </Grid>
    <Grid item xs={12} sm={4} md={3} lg={3}>
      <DateField
        size='small'
        label='consumer dob'
        placeholder='select a birthday'
        onChange={onDateChange}
      />
    </Grid>
    <Grid item xs={12} sm={4} md={3} lg={3}>
      <SelectField
        size='small'
        label='Status'
        placeholder='select a status'
        value={currentStatus || ''}
        onChange={onStatusChange}
        options={[
          { label: 'All', value: 'all' },
          { label: 'Redeemed', value: 'redeemed' },
          { label: 'Purchased', value: 'purchased' },
        ]}
      />
    </Grid>
  </>
)

ProviderVouchersForm.propTypes = {
  onQueryChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
}

export default ProviderVouchersForm
