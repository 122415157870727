import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { getVoucherLink } from '../utils/getVoucherLink'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Typography from '@app/stories/Typography'

const CopyVoucher = ({ voucher, label }) => {
  const handleCopyToClipboard = () => {
    const url = getVoucherLink(voucher)
    navigator.clipboard.writeText(url)
  }

  return (
    <Button
      variant='outlined'
      size='small'
      onClick={handleCopyToClipboard}
      style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
    >
      <ContentCopyIcon />
      <Typography variant='body2' style={{ marginLeft: '8px' }}>
        {label}
      </Typography>
    </Button>
  )
}

CopyVoucher.propTypes = {
  voucher: PropTypes.object.isRequired,
  label: PropTypes.string,
}

export default CopyVoucher
