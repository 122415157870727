import { useState } from 'react'
import { Typography, Box, CircularProgress } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import TextField from '@app/stories/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { createStripeAccount } from '@app/firebase/functions'
import { isText } from '@app/libs/validators'
import { STRIPE_CONNECT_ERROR } from '@app/libs/constant'

const StripeConnectAccount = () => {
  const [accoutSetupLoading, setAccountSetupLoading] = useState(false)
  const [errorList, setErrorList] = useState([])

  const validateFields = ({ email }) => {
    const errorList = []
    if (!isText(email.value)) {
      errorList.push(STRIPE_CONNECT_ERROR.INVALID_EMAIL)
    }

    return errorList
  }

  const onSubmit = async event => {
    event.preventDefault()
    const { elements } = event.target
    const { email } = elements

    const checkErrorsList = validateFields(elements)

    setErrorList(checkErrorsList)
    if (checkErrorsList.length === 0) {
      setAccountSetupLoading(true)

      try {
        await createStripeAccount({
          email: email.value,
          returnURL: window.location.origin,
        })
          .then(data => {
            setAccountSetupLoading(false)
            const { stripeRedirectLink } = data
            console.log('stripeRedirectLink', stripeRedirectLink)
            window.location.href = stripeRedirectLink
          })
          .catch(err => {
            console.log('err', err)
            setAccountSetupLoading(false)
          })
      } catch (error) {
        console.log('Hanlde submit error', error)
        setAccountSetupLoading(false)
      }
    }
  }
  return (
    <>
      <Typography variant='h4'>Begin the enrollment process.</Typography>
      <form title='stripe-connect-form' onSubmit={onSubmit}>
        <Typography variant='body1'>
          To enable same day payouts, please add an email address you'd like associated with your
          payout account.
        </Typography>
        <TextField
          name='email'
          type='email'
          label='Email address'
          display='standard'
          inputProps={{
            title: 'email',
          }}
          error={errorList.includes(STRIPE_CONNECT_ERROR.INVALID_EMAIL)}
          helperText={errorList.includes(STRIPE_CONNECT_ERROR.INVALID_EMAIL)}
        />

        <Box mt={2}>
          <LoadingButton
            type='submit'
            loading={accoutSetupLoading}
            loadingPosition='start'
            startIcon={<SaveIcon />}
            variant='contained'
          >
            Continue
          </LoadingButton>
        </Box>
      </form>
    </>
  )
}

export default StripeConnectAccount
