import { useState } from 'react'
import Box from '@mui/material/Box'
import InstantSearchContainerCustom from '../../containers/InstantSearchContainerCustom'
import PlanAdminProviderSearchCard from '.././PlanAdminProviderSearchCard'
import { useCurrentProfile } from '../../context/CurrentUser'

const BuildCard = ({ key, data, onClick, variant, exactMatch }) => {
  console.log(data)
  return (
    <PlanAdminProviderSearchCard
      key={key}
      data={data}
      onClick={onClick}
      variant={variant}
      exactMatch={exactMatch}
      hideButton={true}
    />
  )
}

const ProviderDirectory = () => {
  const { managedPlanData } = useCurrentProfile()

  console.log(managedPlanData)

  const { providerSearchTagFilters = [] } = managedPlanData?.planData || {}
  const configuration = {
    hitsPerPage: 5,
  }

  if (providerSearchTagFilters?.length > 0) {
    console.log('provider filters exist')
    configuration.filters = providerSearchTagFilters.map(tag => `tags:${tag}`).join(' OR ')
  }

  console.log(configuration)

  return (
    <Box>
      <InstantSearchContainerCustom
        HitComponent={BuildCard}
        // hitClick={`/plans/${networkSlug}/service/`}
        indexName='providers'
        showInitialHits
        configuration={configuration}
        searchBoxText='Search for a provider'
        noResultsMessage='No results for'
        customZIndex={5}
      />
    </Box>
  )
}

export default ProviderDirectory
