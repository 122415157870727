import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Icon from '../stories/Icons'
import Modal from '../stories/Modal'
import Heading from '../stories/Heading'
import Divider from '../stories/Divider'
import Button from '../stories/Button'
import Typography from '../stories/Typography'
import SelectField from '../stories/Select'
import Collapse from '@mui/material/Collapse'
import Zoom from '@mui/material/Zoom'

import { useVoucherDetails } from '../context/VoucherDetails'
import { useCurrentProfile, useCurrentClinic } from '../context/CurrentUser'

import useEmrOrders from '../hooks/useEmrOrders'

import StepperContainer from '@app/containers/StepperContainer'

import OrdersPatientInfoForm from './EmrOrderFormComponents/OrdersPatientInfoForm'
import DiagnosisCodeSearch from './EmrOrderFormComponents/DiagnosisSearch'
import ServiceSearch from './EmrOrderFormComponents/ServiceSearch'
import OrderNotes from './EmrOrderFormComponents/OrderNotes'
import ReviewOrder from './EmrOrderFormComponents/ReviewOrder'

import MedicationSearch from './EmrOrderFormComponents/MedicationSearch'
import ReviewMedicationOrder from './EmrOrderFormComponents/ReviewMedicationOrder'

import { formatDateForDatePicker, convertFirestoreToDate } from '../libs/formatters'

// This is the order modal
const AddOrderModal = ({ open, handleClose, orderType = 'text' }) => {
  const providerProfile = useCurrentProfile()

  const { plansAssociated, id: clinicID, name: clinicName, npi: clinicNPI } = useCurrentClinic()
  const { voucher } = useVoucherDetails()

  // Form Control
  const {
    firstname,
    setFirstname,
    lastname,
    setLastname,
    email,
    setEmail,
    phone,
    setFormattedPhone,
    dob,
    setDob,
    gender,
    setGender,
    street,
    setStreet,
    street2,
    setStreet2,
    state,
    setState,
    city,
    setCity,
    zip,
    setZip,
    serviceSelected,
    setServiceSelected,
    diagnosisCodeData,
    setDiagnosisCodeData,
    medicationSelected,
    setMedicationSelected,
    SIG,
    setSIG,
    quantity,
    setQuantity,
    drugAllergies,
    setDrugAllergies,
    otherMedications,
    setOtherMedications,
    medicalConditions,
    setMedicalConditions,
    pregnant,
    setPregnant,
    nursing,
    setNursing,
    safetyCap,
    setSafetyCap,
    providerFirstname,
    setProviderFirstname,
    providerNPI,
    setProviderNPI,
    currentFile,
    setCurrentFile,
    noteContent,
    setNoteContent,
    createNewOrder,
    setOrders, // eslint-disable-line no-unused-vars
    setReferenceVoucher,
    updateOrders, // eslint-disable-line no-unused-vars
    disabled, // eslint-disable-line no-unused-vars
    submitDisabled,
    clearAllOrderInfo,
  } = useEmrOrders({
    voucher,
    patient: {
      firstname: voucher?.customer?.firstname?.toLowerCase()?.trim(),
      lastname: voucher?.customer?.lastname?.toLowerCase()?.trim(),
      email: voucher?.customer?.email?.toLowerCase()?.trim(),
      phone: voucher?.customer?.phone,
      dob:
        formatDateForDatePicker(voucher?.customer?.dob) ||
        formatDateForDatePicker(voucher?.customer?.birthday) ||
        '',
      gender: voucher?.customer?.gender,
      address: voucher?.customer?.address,
    },
    provider: {
      id: providerProfile.uid,
      firstname: providerProfile?.firstname,
      lastname: providerProfile?.lastname,
      email: providerProfile?.email,
      phone: providerProfile?.phone,
      npi: providerProfile?.npi || clinicNPI,
      clinicID,
      clinicName,
    },
  })

  const cleanUp = () => {
    clearAllOrderInfo()
    handleClose()
  }

  useEffect(() => {
    if (!open) {
      return undefined
    }
    setReferenceVoucher(voucher)
  }, [open, voucher, setReferenceVoucher])

  // These needs to be passed to the stepper
  const [algoliaFilters, setAlgoliaFilters] = useState(null) // eslint-disable-line no-unused-vars

  const [planOptions, setPlanOptions] = useState([])

  const [selectedPlan, setSelectedPlan] = useState('default')
  const [selectedPlanLabel, setSelectedPlanLabel] = useState(null)

  useEffect(() => {
    if (!selectedPlan || selectedPlan === 'default') {
      setAlgoliaFilters(null)
      return undefined
    }
    setAlgoliaFilters(`networkId:${selectedPlan}`)
  }, [selectedPlan])

  useEffect(() => {
    // plans associated is an object with keys as network(plan) IDs and values as plan info
    if (plansAssociated && Object.keys(plansAssociated).length > 0) {
      const planOptions = Object.keys(plansAssociated).map(planID => {
        return {
          value: planID,
          label: plansAssociated[planID].name,
        }
      })

      // if url is not mishe.co add this id to the list of plans otherwise use other id
      if (window.location.hostname !== 'mishe.co') {
        planOptions.unshift({
          value: 'qwGk5c8R6wfOvVjh6SJ7',
          label: 'Mishe Health Plan',
        })
      } else {
        planOptions.unshift({
          value: 'UUrfH2FiTx2LjkdzUhnP',
          label: 'Mishe Health Plan',
        })
      }

      planOptions.unshift({
        value: 'default',
        label: 'Select a plan',
      })

      setPlanOptions(planOptions)
    }
  }, [plansAssociated])

  // For Stepper Container
  const orderBubbleInfo = [
    { stepName: 'Patient Info' },
    { stepName: 'Diagnosis Code Selection' },
    { stepName: 'Service Selection' },
    { stepName: 'Additional Notes' },
    { stepName: 'Review Order' },
  ]

  const medicationBubbleInfo = [
    { stepName: 'Patient Info' },
    { stepName: 'Diagnosis Code Selection' },
    { stepName: 'Medication Selection' },
    { stepName: 'Additional Notes' },
    { stepName: 'Review Order' },
  ]

  return (
    <Modal open={open} onClose={cleanUp} fullWidth maxWidth='md' height='large'>
      <Box style={{ position: 'relative' }}>
        <Button
          variant='outlined'
          onClick={cleanUp}
          style={{
            position: 'absolute',
            right: '0px',
            top: '0px',
            minWidth: '44px',
            height: '44px',
            width: '44px',
            borderRadius: '22px',
          }}
        >
          <Icon name='exitToApp' color='primary' />
        </Button>
        <Heading
          variant='h3'
          weight='softBold'
          align='center'
          style={{ margin: 'auto', width: '68%', fontFamily: 'Poppins' }}
        >
          Creating {orderType} order
        </Heading>
        <Divider spacing='vertical' />
        {orderType === 'medication' && (
          <StepperContainer
            bubbleInfo={medicationBubbleInfo}
            disableSubmit={submitDisabled}
            stepControlDetails={{
              show: true,
              handleFinished: () => {
                createNewOrder(cleanUp)
              },
            }}
          >
            <OrdersPatientInfoForm
              key='patient-info'
              values={{
                firstname: firstname || '',
                lastname: lastname || '',
                email: email || '',
                phone: phone || '',
                dob: dob || '',
                gender: gender || '',
                street: street || '',
                street2: street2 || '',
                city: city || '',
                state: state || '',
                zip: zip || '',
              }}
              setters={{
                setFirstname,
                setLastname,
                setEmail,
                setPhone: setFormattedPhone,
                setDob,
                setGender,
                setStreet,
                setStreet2,
                setCity,
                setState,
                setZip,
              }}
            />
            <DiagnosisCodeSearch
              key='diagnosis-code'
              values={{
                diagnosisCodeData,
              }}
              setters={{
                setDiagnosisCodeData,
              }}
            />
            <MedicationSearch
              key='medication-selection'
              values={{
                medicationSelected,
                SIG,
                quantity,
                drugAllergies,
                otherMedications,
                medicalConditions,
                pregnant,
                nursing,
                safetyCap,
              }}
              setters={{
                setMedicationSelected,
                setSIG,
                setQuantity,
                setDrugAllergies,
                setOtherMedications,
                setMedicalConditions,
                setPregnant,
                setNursing,
                setSafetyCap,
              }}
            />
            <OrderNotes
              key='additional-notes'
              values={{
                providerFirstname,
                providerNPI,
                noteContent,
                currentFile,
              }}
              setters={{
                setProviderFirstname,
                setProviderNPI,
                setNoteContent,
                setCurrentFile,
              }}
            />
            <ReviewMedicationOrder
              key='review-order'
              values={{
                firstname,
                lastname,
                email,
                phone,
                dob,
                gender,
                street,
                street2,
                city,
                state,
                zip,
                noteContent,
                currentFile,
                medicationSelected,
                SIG,
                quantity,
                drugAllergies,
                otherMedications,
                medicalConditions,
                pregnant,
                nursing,
                safetyCap,
                diagnosisCodeData,
                providerFirstname,
                providerNPI,
              }}
            />
          </StepperContainer>
        )}
        {orderType === 'referral' && (
          <StepperContainer
            bubbleInfo={orderBubbleInfo}
            disableSubmit={submitDisabled}
            stepControlDetails={{
              show: true,
              handleFinished: () => {
                createNewOrder(cleanUp)
              },
            }}
          >
            <OrdersPatientInfoForm
              key='patient-info'
              values={{
                firstname: firstname || '',
                lastname: lastname || '',
                email: email || '',
                phone: phone || '',
                dob: dob || '',
                gender: gender || '',
                street: street || '',
                street2: street2 || '',
                city: city || '',
                state: state || '',
                zip: zip || '',
              }}
              setters={{
                setFirstname,
                setLastname,
                setEmail,
                setPhone: setFormattedPhone,
                setDob,
                setGender,
                setStreet,
                setStreet2,
                setCity,
                setState,
                setZip,
              }}
            />
            <DiagnosisCodeSearch
              key='diagnosis-code'
              values={{
                diagnosisCodeData,
              }}
              setters={{
                setDiagnosisCodeData,
              }}
            />
            <Box>
              {!serviceSelected && (
                <Box>
                  <Box display='flex' gap='16px' alignItems='center' mb={2}>
                    <Typography variant='h4' margin='0px'>
                      {selectedPlan !== 'default'
                        ? selectedPlanLabel
                        : 'Select a plan to view services'}
                    </Typography>
                    <Zoom in={selectedPlan !== 'default'}>
                      <Box>
                        <Button
                          variant='outlined'
                          color='primary'
                          size='small'
                          onClick={() => {
                            setSelectedPlan('default')
                          }}
                        >
                          <Box display='flex' alignItems='center' gap='4px'>
                            <Icon name='edit' />
                            <Typography variant='body1' margin='0px'>
                              Change Plan
                            </Typography>
                          </Box>
                        </Button>
                      </Box>
                    </Zoom>
                  </Box>
                  <Collapse in={selectedPlan === 'default'} timeout={{ enter: 500, exit: 500 }}>
                    <SelectField
                      label='Plan'
                      value={selectedPlan}
                      margin='none'
                      onChange={e => {
                        setSelectedPlan(e.target.value)
                        setSelectedPlanLabel(
                          planOptions.find(option => option.value === e.target.value)?.label,
                        )
                      }}
                      options={planOptions}
                    />
                  </Collapse>
                </Box>
              )}
              <Collapse in={algoliaFilters} timeout={{ enter: 500, exit: 500 }}>
                <ServiceSearch
                  key='service-selection'
                  values={{
                    serviceSelected,
                    algoliaFilters,
                  }}
                  setters={{
                    setServiceSelected,
                  }}
                />
              </Collapse>
            </Box>
            <OrderNotes
              key='additional-notes'
              values={{
                providerFirstname,
                providerNPI,
                noteContent,
                currentFile,
              }}
              setters={{
                setProviderFirstname,
                setProviderNPI,
                setNoteContent,
                setCurrentFile,
              }}
            />
            <ReviewOrder
              key='review-order'
              values={{
                firstname,
                lastname,
                email,
                phone,
                dob,
                gender,
                street,
                street2,
                city,
                state,
                zip,
                noteContent,
                currentFile,
                serviceSelected,
                diagnosisCodeData,
                providerFirstname,
                providerNPI,
              }}
            />
          </StepperContainer>
        )}
      </Box>
    </Modal>
  )
}

AddOrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  orderType: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
}

export default AddOrderModal
