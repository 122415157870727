import { useCurrentProfile } from '@app/context/CurrentUser'
import usePayouts from '@app/hooks/usePayouts'
import { useEffect } from 'react'
import PaymentTable from './ClinicPayoutsTable'

const ClinicPayouts = () => {
  const { fetchPayouts, payoutData, payoutsLoading } = usePayouts()

  const { organizationId } = useCurrentProfile()
  useEffect(() => {
    organizationId && fetchPayouts({ organizationId })
  }, [organizationId])

  return <div>{!payoutsLoading && <PaymentTable payoutData={payoutData} />}</div>
}

export default ClinicPayouts
