import { collection } from '../firebase/firestore'

export class EmployerPlanUtil {
  /**
   * Constructor initializes the plan data and vouchers.
   * @param {Object} planData - Plan data fetched from the database.
   * @param {Array} vouchersPerPerson - Vouchers information per family member.
   */
  constructor(planData, vouchersPerPerson) {
    this.planData = planData
    this.familyVouchers = vouchersPerPerson
  }

  /**
   * Factory method to create an instance of EmployerPlanUtil.
   * @param {string} memberId - ID of the member.
   * @param {string} planId - ID of the plan.
   * @returns {EmployerPlanUtil} - Instance of EmployerPlanUtil.
   */
  static async create(memberId, planId) {
    // Fetch plan members associated with the given memberId
    const planMembersSnapshot = await collection('networks')
      .doc(planId)
      .collection('members')
      .where('memberId', '==', memberId)
      .get()

    if (planMembersSnapshot.empty) {
      throw new Error('No plan members found for the given memberId.')
    }

    const familyPlanId = planMembersSnapshot?.docs[0]?.data()?.familyPlanId || null

    const vouchersPerPerson = []

    if (familyPlanId) {
      // Fetch family members associated with the familyPlanId
      const familyMembersSnapshot = await collection('networks')
        .doc(planId)
        .collection('members')
        .where('familyPlanId', '==', familyPlanId)
        .get()

      if (!familyMembersSnapshot.empty) {
        familyMembersSnapshot.forEach(doc => {
          vouchersPerPerson.push({
            type: doc.data().type,
            name: doc.data().name,
            vouchers: doc.data().vouchersSpentOn || [],
          })
        })
      }
    }

    // Fetch the plan data
    const planRef = await collection('networks').doc(planId).get()
    const planData = planRef.data()

    if (!planData) {
      throw new Error('Plan data not found.')
    }

    return new EmployerPlanUtil(planData, vouchersPerPerson)
  }

  // Family-Level Metrics
  /**
   * Calculates the total amount spent by the family on vouchers.
   * @returns {number} - Total family spend.
   */
  get totalFamilySpend() {
    return this.familyVouchers.reduce((total, member) => {
      const vouchersSpend = member.vouchers.reduce((sum, service) => sum + service.amountPaid, 0)
      return total + vouchersSpend
    }, 0)
  }

  /**
   * Calculates the remaining deductible for the family.
   * @returns {Object} - Remaining and total deductible.
   */
  get deductible() {
    const totalPaidTowardDeductible = this.familyVouchers.reduce((total, member) => {
      return total + member.vouchers.reduce((sum, voucher) => sum + voucher.amountPaid, 0)
    }, 0)

    const totalDeductible = this.planDesign.deductible.family
    const remainingDeductible = Math.max(0, totalDeductible - totalPaidTowardDeductible)

    return { remaining: remainingDeductible, total: totalDeductible }
  }

  /**
   * Calculates the remaining out-of-pocket (OOP) maximum for the family.
   * @returns {Object} - Remaining and total OOP maximum.
   */
  get oop() {
    const totalPaidTowardOOP = this.familyVouchers.reduce((total, member) => {
      return total + member.vouchers.reduce((sum, voucher) => sum + voucher.amountPaid, 0)
    }, 0)

    const totalOOPMax = this.planDesign.outOfPocketMax.family
    const remainingOOP = Math.max(0, totalOOPMax - totalPaidTowardOOP)

    return { remaining: remainingOOP, total: totalOOPMax }
  }

  // Plan Design Details
  /**
   * Retrieves copay details from the plan design.
   * @returns {Object} - planDesign .
   */
  get planDesign() {
    return this.planData.planDesign
  }

  // Plan Metadata
  /**
   * Retrieves the prescription group from the plan data.
   * @returns {string} - Prescription group.
   */
  get prescriptionGroup() {
    return this.planData.prescriptionGroup
  }

  /**
   * Retrieves the effective date of the plan.
   * @returns {string} - Effective date.
   */
  get effectiveDate() {
    return this.planData.effectiveDate
  }

  /**
   * Retrieves the type of the plan.
   * @returns {string} - Plan type.
   */
  get planType() {
    return this.planData.planType
  }
}
