import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { collection } from '../../firebase/firestore'
import { convertFirestoreToDate } from '../../libs/formatters'
import { useCurrentProfile } from '@app/context/CurrentUser'

const getPlanCensus = async planID => {
  const membersRef = await collection('networks').doc(planID).collection('members').get()
  const members = membersRef.docs
    .filter(doc => {
      return !!doc.data().lastname //filter out none pateints. we should use "type" but that data is corrupted so its not realiable now
    })
    .map(doc => {
      const membershipData = doc.data()
      return {
        id: doc.id,
        familyPlanId: membershipData?.familyPlanId || null,
        type: membershipData?.type || null,
        memberId: membershipData?.memberId || null,
      }
    })

  const profiles = await Promise.all(
    members.map(async memberInfo => {
      const { id, familyPlanId } = memberInfo
      const profileRef = await collection('profiles').doc(id).get()

      return {
        ...profileRef.data(),
        ...memberInfo,
      }
    }),
  )

  return profiles.map(profile => {
    const {
      id,
      memberId,
      firstname,
      lastname,
      dob,
      gender,
      address,
      phone,
      email,
      type,
      familyPlanId,
    } = profile

    let dependentType = 'Subscriber'
    if (type > 1) {
      switch (familyPlanId) {
        case 2:
          dependentType = 'Spouse'
          break
        case 3:
          dependentType = 'Child'
          break
        default:
          dependentType = 'Subscriber'
      }
    }

    const formattedDob = convertFirestoreToDate(dob)

    const formattedAddress = [
      address?.street,
      address?.street2,
      address?.city,
      address?.state,
      address?.zip,
    ]
      .filter(Boolean)
      .join(', ')

    return {
      id: memberId || id,
      firstname: firstname || '---',
      lastname: lastname || '---',
      dob: formattedDob
        ? formattedDob?.toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit',
          })
        : '---',
      gender: gender || '---',
      address: formattedAddress,
      phone: phone || '---',
      email: email || '---',
      isDependent: type > 1,
      dependentType,
    }
  })
}

const MemberCensus = () => {
  const { managedPlanData } = useCurrentProfile()

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstname', headerName: 'First name', width: 150 },
    { field: 'lastname', headerName: 'Last name', width: 150 },
    { field: 'dob', headerName: 'Date of birth', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 110 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'phone', headerName: 'Phone number', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    // { field: 'membershipStatus', headerName: 'Membership status', width: 150 },
    // { field: 'lastCheckup', headerName: 'Last checkup', width: 150 },
    // { field: 'isDependent', headerName: 'Is dependent', width: 150 },
    { field: 'dependentType', headerName: 'Member type', width: 150 },
  ]

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    setError(false)
    getPlanCensus(managedPlanData?.managedPlanID)
      .then(profiles => {
        setRows(profiles)
        setLoading(false)
        setError(false)
      })
      .catch(error => {
        console.error('Error getting plan census:', error)
        setLoading(false)
        setError('We encountered an error while fetching the plan census.')
      })
  }, [managedPlanData])

  if (loading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
        <CircularProgress load size={100} />
      </Box>
    )
  }
  if (error) {
    return <Box>{error}</Box>
  }
  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: true,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{
          minHeight: '200px',
        }}
      />
    </Box>
  )
}

export default MemberCensus
